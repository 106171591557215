const zh: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "忘记密码并发送指示",
	goBack: "返回",
	invalidEmailAndPassword: "电子邮件地址或者密码错误",
	email: "电子邮件",
	password: "密码",
	emailAddress: "电子邮件地址",
	tfaCode: "双因素认证代码",
	login: "登录",
	dontHaveAnAccountYet: "还没有账户吗?",
	accountCreateOne: "创建一个!",
	forgotYourPassword: "忘记密码?",
	couldNotFindDefaultFolder: "找不到默认文件夹",
	unlockLink: "解锁",
	download: "下载",
	cannotDownloadEmptyFolder: "无法下载空文件夹",
	thisFolderIsEmpty: "此文件夹为空",
	linkFolderEmptyInfo: "一旦文件或文件夹被添加到该文件夹，它们将出现在这里。",
	confirmPassword: "确认密码",
	createAccount: "新建账户",
	alreadyHaveAnAccount: "已经有了一个账户?",
	loginEx: "登录!",
	resendConfirmationEmail: "重新发送确认邮件",
	reportAbuseModal_spam: "垃圾信息",
	reportAbuseModal_dmca: "侵犯版权",
	reportAbuseModal_cp: "虐待儿童",
	reportAbuseModal_stolen: "窃取的数据",
	reportAbuseModal_malware: "恶意软件",
	reportAbuseModal_other: "其他",
	abuseReport: "滥用报告",
	abuseReportModalInfo: "使用此表格报告不适当的、非法的或其他有害的共享文件.",
	yourEmailAddress: "您的电子邮件地址",
	abuseReportModalInfoPlaceholder: "请向我们提供所有的细节，以便我们采取适当的行动（可选）",
	send: "发送",
	abuseReportModalReason: "请选择一个原因",
	avatarUploadMaxFileSize: "文件大小不能超过3MB",
	storageUsed: "已用空间",
	storageUsedInfo: "已使用 __USED__ / __MAX__",
	storageUsedFilesAndFolders: "文件 __USED__",
	storageUsedVersionedFiles: "版本文件 __USED__",
	storageUsedFree: "可用空间 __FREE__",
	avatar: "头像",
	edit: "编辑",
	personalInformation: "个人资料",
	darkMode: "深色模式",
	language: "语言",
	change: "更改",
	requestAccountData: "申请账户数据",
	request: "申请",
	logout: "退出登录",
	versionedFiles: "版本文件",
	delete: "删除",
	allFilesAndFolders: "全部文件和文件夹",
	deleteAccount: "删除账户",
	changePassword: "更改密码",
	tfa: "双因素认证",
	subMoreInfo: "更多关于您的订阅信息",
	paymentMethod: "付款方式",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "加密货币",
	update: "更新",
	cancel: "取消",
	noSubs: "你目前没有有效的订阅.",
	noInvoices: "你还没有任何发票.",
	planFeatures_1: "无限流量",
	planFeatures_2: "客户端加密",
	planFeatures_3: "零知识技术",
	planTerms_monthly: "每月",
	planTerms_annually: "每年",
	planTerms_lifetime: "终身",
	planTerms_starter: "小额",
	buyNow: "立即购买",
	general: "通用",
	settings: "设置",
	security: "安全",
	plans: "计划",
	subs: "订阅",
	events: "事件",
	invoices: "发票",
	invalidEmail: "无效的电子邮件",
	invalidPassword: "无效的密码",
	emailsDoNotMatch: "电子邮件不匹配",
	changeEmailPleaseConfirm: "请点击发送的链接来确认你的新电子邮件地址",
	newEmail: "新的电子邮件",
	confirmNewEmail: "确认新的电子邮件",
	save: "保存",
	firstName: "名",
	lastName: "姓",
	companyName: "公司名称",
	vatId: "增值税编号",
	street: "街道",
	streetNumber: "街道编号",
	city: "城市",
	postalCode: "邮政编码",
	country: "国家",
	areYouSure: "你确定吗?",
	areYouSureDeleteAllVersioned: "你确定你要删除所有版本文件吗？这个行为不能被撤销!",
	areYouSureDeleteAll: "你确定要删除所有文件和文件夹吗？这个行为不能被撤销!",
	invalidNewPassword: "新密码无效",
	invalidCurrentPassword: "当前密码无效",
	newPasswordsDontMatch: "新密码不匹配",
	invalidMasterKeys: "无效的主密钥",
	passwordChanged: "密码已更改",
	newPassword: "新密码",
	confirmNewPassword: "确认新密码",
	currentPassword: "目前的密码",
	deleteAccountConfirm: "请点击发送至您邮箱的链接来删除你的账户",
	areYouSureDeleteAccount: "你确定要删除你的账户吗？我们将向您的电子邮件地址发送一个确认链接.",
	invalid2FACode: "无效的双因素认证代码",
	enable2FA: "启用双因素认证",
	copy: "复制",
	enter2FA: "输入双因素认证代码",
	enable: "启用",
	recoveryKeys: "恢复密钥",
	recoveryKeysInfo: "请安全地保存您的恢复密钥。这是在双因素认证设备丢失的情况下能够恢复您账户的唯一方法。",
	close: "关闭",
	disable2FA: "禁用双因素认证",
	disable: "禁用",
	sharedWithMe: "与我共享",
	sharedWithOthers: "与他人共享",
	links: "链接",
	favorites: "收藏",
	recents: "最近",
	trash: "回收站",
	cloudDrive: "云盘",
	emptyTrash: "清空回收站",
	monthlyRecurring: "月费",
	annuallyRecurring: "年费",
	oneTimePayment: "一次性付款",
	buyRecurringInfo: "通过购买计划,您授权Filen在每个账单期自动向您收费,直到您取消。您可以随时通过您的账户页面取消。没有部分退款.",
	buyLifetimeInfo: "通过购买计划,您授权Filen从您的付款方式中收取费用。没有部分退款.",
	creditDebit: "信用卡 / 借记卡",
	cancelSub: "取消订阅",
	cancelSubSure: "你确定你要取消这项订阅吗？",
	cancellingSub: "取消订阅",
	createFolder: "新建文件夹",
	createTextFile: "新建文本文件",
	uploadFiles: "上传文件",
	uploadFolders: "上传文件夹",
	preview: "预览",
	preparingDownload: "准备下载",
	normalDownload: "普通下载",
	zipDownload: "打包成ZIP格式下载",
	publicLink: "公开链接",
	share: "分享",
	versions: "历史版本",
	color: "颜色",
	color_default: "默认",
	color_blue: "蓝色",
	color_green: "绿色",
	color_purple: "紫色",
	color_red: "红色",
	color_gray: "灰色",
	unfavorite: "取消收藏",
	favorite: "收藏",
	rename: "重命名",
	move: "移动",
	selectDestination: "选择目标位置",
	restore: "恢复",
	deletePerm: "永久删除",
	remove: "删除",
	stopSharing: "停止分享",
	cookieConsent: "本网站使用cookies来衡量并改善您的体验.",
	accept: "同意",
	optOut: "选择退出",
	onlyNeeded: "仅需的",
	pleaseChooseDiffName: "请选择一个不同的名字",
	newFolderName: "名字",
	itemsMovedToTrash: "__COUNT__ 个项目移入回收站",
	couldNotMoveToTrash: "无法移动 __NAME__ 到回收站: __ERR__",
	deleteModalSure: "你确定你要删除 __COUNT__ 个项目?",
	deletePermModalSure: "你确定你要永久删除 __COUNT__ 个项目? 此行为不能被撤销!",
	itemsDeletedPerm: "__COUNT__ 个项目已永久删除",
	couldNotDeletePerm: " __NAME__ 无法永久删除: __ERR__",
	uploadHere: "在此处上传",
	event: "事件",
	date: "日期",
	ipAddress: "IP 地址",
	na: "不适用",
	listEmpty_1: "还没有与你分享的文件或文件夹",
	listEmpty_2: "一旦有人与你分享文件或文件夹，它们将出现在这里",
	listEmpty_3: "还没有与他人共享的文件或文件夹",
	listEmpty_4: "一旦你与他人共享文件或文件夹，它们将出现在这里。",
	listEmpty_5: "暂时没有公开链接",
	listEmpty_6: "一旦你为文件或文件夹创建了公共链接，它们将出现在这里",
	listEmpty_7: "暂时没有收藏的",
	listEmpty_8: "一旦你把文件或文件夹标记为收藏，它们就会出现在这里。",
	listEmpty_9: "没有最近的内容",
	listEmpty_10: "最近上传的文件将出现在这里",
	listEmpty_11: "回收站里什么都没有",
	listEmpty_12: "一旦你标记了要删除的文件或文件夹，它们将出现在这里",
	listEmpty_13: "还没有上传文件或文件夹",
	listEmpty_14: "在这里拖放文件或文件夹，或按下面的“上传”按钮",
	listFooterSelected: "已选择 __TOTAL__ 个项目中的 __COUNT__ 个",
	name: "文件名称",
	size: "大小",
	lastModified: "修改日期",
	moveModalBtn: "移动 __COUNT__ 个项目到 __DEST__",
	file: "文件",
	ctrlPlusS: "CTRL + S",
	exit: "退出",
	fileHasBeenChanged: "文件已被修改",
	textEditorExitSure: "你确定你要退出而不保存吗？",
	saveChanges: "保存更改",
	expire_never: "从不",
	expire_1h: "1小时",
	expire_6h: "6小时",
	expire_1d: "1天",
	expire_3d: "3天",
	expire_7d: "7天",
	expire_14d: "14天",
	expire_30d: "30天",
	addingItemsToPublicLink: "正在将项目添加到公共链接中",
	addingItemsToPublicLinkProgress: "正在将 __LEFT__ 个项目添加到公共链接中",
	enabled: "已启用",
	disabled: "已关闭",
	copied: "已复制",
	publicLinkDownloadBtn: "下载按钮已启用",
	publicLinkPassword: "密码（留空表示禁用）",
	removedSharedItems: "删除 __COUNT__ 个已共享文件",
	couldNotRemoveSharedItems: "无法删除 __NAME__: __ERR__",
	removeSharedItemsModalInfo: "你确定你要删除 __COUNT__ 个项目? 你将无法再访问它们。",
	renameNewName: "新名称",
	selectFromComputer: "从计算机中选择",
	upload: "上传",
	files: "文件",
	folders: "文件夹",
	newFolder: "新建文件夹",
	sharedWith: "分享给",
	sharingItems: "分享项目",
	sharingItemsProgress: "正在分享 __LEFT__ 个项目",
	itemsSharedWith: "__COUNT__ 个项目与 __EMAIL__ 分享",
	shareReceiver: "接收者的Filen账户电子邮件地址",
	upgrade: "升级",
	stoppedSharingItems: "停止共享 __COUNT__ 个项目",
	couldNotStopSharingItem: "无法停止分享 __NAME__: __ERR__",
	stopSharingModalSure: "你确定你要停止分享 __COUNT__ 个项目吗? 其他各方将不再能够访问它们。",
	aboutRemaining: "剩余时间 __TIME__ ",
	transferringItems: "正在转移 __COUNT__ 个项目",
	uploadingItems: "正在上传 __COUNT__ 个项目",
	downloadingItems: "正在下载 __COUNT__ 个项目",
	new: "新建",
	resume: "重新开始",
	pause: "暂停",
	stop: "停止",
	done: "完成",
	noUploadsQueued: "无上传队列",
	creatingFolders: "新建文件夹",
	creatingFoldersProgress: "正在创建 __LEFT__ 个文件夹",
	current: "目前的",
	create: "创建",
	changingColor: "为 __COUNT__ 个项目改变颜色",
	couldNotChangeColor: "无法为 __NAME__ 改变颜色: __ERR__",
	eventFileUploaded: "文件已上传",
	eventFileVersioned: "文件已计入版本",
	eventVersionedFileRestored: "文件已恢复版本",
	eventFileMoved: "文件已移动",
	eventFileTrash: "文件已移动到回收站",
	eventFileRm: "文件已删除",
	eventFileRestored: "文件已恢复",
	eventFileShared: "文件已共享",
	eventFileLinkEdited: "已编辑文件的公共链接",
	eventFolderTrash: "文件夹已移动到回收站",
	eventFolderShared: "文件夹已共享",
	eventFolderMoved: "文件夹已移动",
	eventFolderRenamed: "文件夹已被重命名",
	eventFolderCreated: "文件夹已新建",
	eventFolderRestored: "文件夹已恢复",
	eventFolderColorChanged: "文件夹颜色已更改",
	eventLogin: "登录",
	eventDeleteVersioned: "版本文件和文件夹已删除",
	eventDeleteAll: "已删除所有文件和文件夹",
	eventDeleteUnfinished: "未完成的上传已被删除",
	eventTrashEmptied: "回收站已被清空",
	eventRequestAccountDeletion: "已请求删除帐户",
	event2FAEnabled: "双因素认证已启用",
	event2FADisabled: "双因素认证已禁用",
	eventCodeRedeem: "代码已兑换",
	eventEmailChanged: "已更改电子邮件",
	eventPasswordChanged: "已更改密码",
	eventRemovedSharedInItems: "已移除共享项目",
	eventRemovedSharedOutItems: "已移除对外共享的项目",
	eventFileUploadedInfo: "__NAME__ 已上传",
	eventFileVersionedInfo: "__NAME__ 已计入版本",
	eventVersionedFileRestoredInfo: " __NAME__ 版本已恢复",
	eventFileRenamedInfo: "__NAME__ 已重命名为 __NEW__",
	eventFileMovedInfo: "__NAME__ 已移动",
	fileRenamedInfo: "__NAME__ 重命名为 __NEW__",
	eventFileTrashInfo: "__NAME__ 已移动到回收站",
	eventFileRmInfo: "__NAME__ 已删除",
	eventFileRestoredInfo: "__NAME__ 已从回收站恢复",
	eventFileSharedInfo: "__NAME__ 已与 __EMAIL__ 共享",
	eventFileLinkEditedInfo: "__NAME__ 的公开链接已编辑",
	eventFolderTrashInfo: "__NAME__ 已移到回收站",
	eventFolderSharedInfo: "__NAME__ 已与 __EMAIL__ 共享",
	eventFolderMovedInfo: "__NAME__ 已移动",
	eventFolderRenamedInfo: "__NAME__ 已重命名为 __NEW__",
	eventFolderCreatedInfo: "__NAME__ 已新建",
	eventFolderRestoredInfo: "__NAME__ 已从回收站恢复",
	eventFolderColorChangedInfo: "__NAME__ 的颜色已更改",
	eventLoginInfo: "已登录",
	eventDeleteVersionedInfo: "版本化文件和文件夹已被删除",
	eventDeleteAllInfo: "已删除所有文件和文件夹",
	eventDeleteUnfinishedInfo: "未完成的上传已被删除",
	eventTrashEmptiedInfo: "回收站已被清空",
	eventRequestAccountDeletionInfo: "已请求删除账户",
	event2FAEnabledInfo: "双因素认证已启用",
	event2FADisabledInfo: "双因素认证已禁用",
	eventCodeRedeemInfo: "代码 __CODE__ 已兑换",
	eventEmailChangedInfo: "电子邮件地址已更改为 __EMAIL__",
	eventPasswordChangedInfo: "密码已更改",
	eventRemovedSharedInItemsInfo: "__COUNT__ 个来自 __EMAIL__ 共享的项目已移除",
	eventRemovedSharedOutItemsInfo: "__COUNT__ 个向 __EMAIL__ 共享的项目已删除",
	favoritingItems: "收藏 __COUNT__ 个项目",
	unfavoritingItems: "取消收藏 __COUNT__ 个项目",
	couldNotChangeFavStatus: "无法为 __NAME__ 改变收藏状态: __ERR__",
	pleaseChooseDiffDest: "请选择一个不同的目标位置",
	movingItems: "移动 __COUNT__ 个项目",
	folderExistsAtDest: "在目标位置已经存在一个名字为 __NAME__ 的文件夹。",
	couldNotMoveItem: "无法移动 __NAME__: __ERR__",
	restoringItems: "正在恢复 __COUNT__ 个项目",
	couldNotRestoreItem: "无法恢复 __NAME__: __ERR__",
	changeEmail: "更改电子邮件",
	passwordsDoNotMatch: "密码不匹配",
	registerWeakPassword: "你的密码需要至少有10个字符的长度",
	invalidEmailOrPassword: "无效的电子邮件或密码",
	unknownErrorSupp: "发生了未知的错误。请再试一次或联系支持部门.",
	registerEmailAlreadyRegistered: "此电子邮件地址已被注册.",
	gotIt: "明白了",
	registration: "注册",
	registrationEmailInstructions: "为了完成您的注册，请确认您的电子邮件地址。一个带有说明的链接已经发送给您了.",
	forgotPasswordEmailSent: "一封关于如何重设密码的电子邮件已经发送给您。",
	maxStorageReached: "储存空间已满",
	maxStorageReachedInfo: "你已经达到了你的最大存储量。请升级您的账户以上传更多文件.",
	upgradeNow: "立即升级",
	abuseReportSubmitted: "滥用报告已发送!",
	invalidAbuseReason: "无效的原因",
	uploadErrored: "上传失败",
	newTextFileName: "新的文本文件名",
	passwordResetSuccess: "密码已更改。请从任意设备重新登录.",
	resetPasswordBtn: "重置密码",
	publicLinkDisabled: "已禁用公共链接",
	expireAfter: "过期于",
	exportMasterKeys: "导出主密钥",
	export: "导出",
	resetPasswordCheckbox:
		"我明白，由于零知识端到端加密的工作原理，在没有导出主密钥的情况下重设我的密码，将无法访问在我账户上存储的所有数据.",
	invalidAuthVersion: "无效的认证版本",
	recoveryMasterKeysInput: "主密钥已导出",
	exportMasterKeysInfo: "导出你的主密钥可以使你有可能在忘记密码的情况下恢复你的账户而不损失数据。请确保每次更改密码时都能导出新的主密钥.",
	import: "导入",
	invite: "邀请",
	yourReferralLink: "你的链接",
	requestPayout: "要求赔付",
	invalidPayoutAddress: "无效的支付地址",
	affiliatePayoutSuccess: "赔付请求已提交",
	requestPayoutInfo: "我们会在1-7个工作日内将您赚取的联盟佣金发送到指定的比特币地址.",
	comissionEarned: "已赚取的佣金",
	receivedBonusStorage: "收到的奖金存储总量",
	referInfo: "通过邀请他人获得最高 __STORAGE__ 的存储量",
	referInfo2:
		"你每邀请一位朋友加入Filen,你就会得到 __STORAGE__ - 你的朋友也会得到 __OTHER_STORAGE__. 您的朋友可以注册他们所选择的订阅，而您可以获得佣金。佣金支付的门槛是 __THRESHOLD__€. 你的比率是 __RATE__%",
	yourBtcAddress: "你的比特币地址",
	openUploads: "打开上传列表",
	payNow: "现在支付",
	emptyTrashModalSure: "你确定要清空你的回收站吗？这个行为不能被撤销!",
	emptyTrashSuccess: "回收站已清空",
	itemRenamed: "项目已重命名",
	subCancelled: "已取消",
	support: "支持",
	finishing: "整理中",
	searchNothingFound: "__TERM__ 未找到",
	queued: "排队中",
	searchInThisFolder: "在此文件夹中搜索...",
        fileVersioning: "文件版本管理",
        loginAlerts: "登录提醒",
	chats: "聊天",
	chatsMessageInput: "发消息给 __NAME__",
	notes: "笔记",
	contacts: "联系人",
	chatConversations: "对话",
	chatParticipants: "参与者",
	chatNew: "新聊天",
	chatNewInviteEmail: "邮箱地址",
	chatAddUserToConversation: "添加用户",
	add: "添加",
	chatInput: "发送一条消息吧",
	noteAddParticipant: "添加参与者",
	preparingFilesDots: "正在准备文件...",
	loadingDots: "加载中...",
	participants: "参与者",
	owner: "所有者",
	removeParticipantNote: "从此笔记中删除参与者",
	toggleParticipantWritePermissionsClick: "参与者有写入权限，点击更改",
	toggleParticipantReadPermissionsClick: "参与者有读取权限，点击更改",
	addNewParticipant: "添加新参与者",
	noteHistory: "历史",
	noteTypeText: "文本",
	noteTypeRich: "富文本",
	noteTypeMd: "Markdown",
	noteTypeChecklist: "清单",
	noteTypeCode: "代码",
	noteUnpin: "取消置顶",
	notePin: "置顶",
	noteUnfavorite: "取消收藏",
	noteFavorite: "收藏",
	noteDuplicate: "复制",
	noteExport: "导出",
	noteTrash: "回收站",
	noteArchive: "归档",
	noteRestore: "恢复",
	noteDelete: "删除",
	noteSynced: "笔记已同步",
	syncingNote: "正在同步笔记...",
	contactRequestSent: "联系人请求已发送",
	addContact: "添加一位联系人",
	addContactSmall: "添加联系人",
	addContactEmail: "联系人的 Filen 电子邮件地址",
	blockedUsers: "已拉黑的用户",
	contactsOnline: "在线",
	contactsAll: "所有",
	contactsOffline: "离线",
	contactsPending: "待批准",
	contactsRequests: "请求",
	contactsBlocked: "已拉黑的",
	blockUser: "拉黑",
	removeUser: "移除",
	contactsIcomingRequests: "传入的请求",
	contactsOutgoingRequests: "发出的请求",
	noteHistoryModal: "笔记历史",
	noteHistoryRestore: "恢复此版本",
	searchInput: "搜索...",
	notesTagsNameExists: "已存在使用此名称的标签",
	notesTagsCreate: "创建标签",
	newNote: "新建笔记",
	notesNoNotesFoundUnderTag: "未找到任何笔记",
	notesCreate: "创建一个",
	notesCreateInfo: "您还没有笔记。",
	notesDeleteWarning: "你确定要删除这条笔记吗？此操作无法撤消！",
	notesTagDeleteWarning: "您确定要删除此标签吗？此操作无法撤消！",
	notesTagsDelete: "删除标签",
	deleteNote: "删除笔记",
	toggleParticipantWritePermissions: "参与者有写入权限，点击更改",
	toggleParticipantReadPermissions: "参与者有读取权限，点击更改",
	notesTagsRename: "移除标签",
	notesTagsCreateRenamePlaceholder: "标签名称",
	noContactsFound: "未找到任何联系人。",
	noParticipantsFound: "未找到任何参与者",
	leave: "离开",
	leaveNote: "离开笔记",
	leaveNoteWarning: "您确定要离开笔记吗？此操作无法撤消！",
	noteRemoveParticipant: "移除参与者",
	noteRemoveParticipantWarning: "您确定要从此笔记中移除该参与者吗？此操作无法撤消！",
	block: "拉黑",
	blockUserWarning: "你确定你要拉黑 __NAME__?",
	removeUserWarning: "您确定要从联系人列表中删除 __NAME__ 吗",
	chatYou: "您",
	chatNoMessagesYet: "没有消息",
	chatMessageDelete: "删除消息",
	chatMessageDeleteWarning: "你确定要删除这条消息吗？此操作无法撤消！",
	chatConversationDelete: "删除该聊天",
	chatConversationDeleteWarning: "您确定要删除此聊天记录吗？此操作无法撤消！",
	chatConversationLeave: "离开聊天",
	chatConversationLeaveWarning: "您确定要离开此聊天吗？",
	chatConversationRemoveParticipant: "移除参与者",
	chatConversationRemoveParticipantWarning: "您确定要从聊天中移除该参与者吗？",
	chatInfoTitle: "端到端加密聊天",
	chatInfoSubtitle1: "Filen 默认使用零知识端到端加密来保护每次聊天。",
	chatInfoSubtitle2: "只有聊天的成员才能解密和阅读内容。",
	chatInfoSubtitle3:
		"系统确保接收到的数据确实上来自显示的用户，并且在此期间没有被更改。",
	chatEmojisMatching: "表情符号匹配中",
	selectFromCloud: "从云端选择",
	creatingPublicLinks: "正在创建公共链接",
	attachToChat: "附加到聊天",
	select: "选择",
	selectNumItems: "选择 __NUM__ 个项目",
	chatSettings: "聊天设置",
	chatSettingsDisplayName: "显示名称",
	chatSettingsAppearOffline: "显示为离线",
	chatMessageHiddenUserBlocked: "被拉黑的联系人的消息已隐藏。",
	chatConversationCreateSidebar: "还没有安全聊天。",
	chatConversationCreateSidebarCreate: "开始聊天",
	noConversationFound: "没有找到聊天记录。",
	copyText: "复制文本",
	chatEdited: "已修改",
	chatViewingOlderMessages: "您正在查看较旧的消息",
	chatJumpToPresent: "跳转至当前内容",
	twoFactorConfirmAlert: "我已妥善保存我的 2FA 恢复密钥",
	chatIsTyping: "正在输入",
	chatConversationEditName: "编辑名称",
	copyId: "复制 ID",
	replyToChatMessage: "回复",
	chatReplyingTo: "正在回复给",
	chatUnreadMessagesSince: "自 __DATE__ 以来的未读消息",
	chatMarkAsRead: "标为已读",
	profileMemberSince: "自 __DATE__ 起成为聊天成员",
	profileAddContact: "发送联系请求",
	profileBlockContact: "黑名单",
	profileUnblockContact: "取消拉黑",
	profile: "资料",
	chatMessageLimitReached: "消息限制为 __LIMIT__ 个字符",
	chatAttachmentTooManyFiles: "每条消息不能附加超过 __LIMIT__ 个文件",
	noteTooBig: "最大的笔记大小是 __MAXSIZE__",
	notesTags: "标签",
	notesType: "类型",
	notesAll: "所有",
	notesPinned: "已置顶"
}

export default zh
