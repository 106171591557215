const es: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "Enviar instrucciones",
	goBack: "Volver",
	invalidEmailAndPassword: "Correo electrónico y contraseña incorrectos",
	email: "Correo electrónico",
	password: "Contraseña",
	emailAddress: "Correo electrónico",
	tfaCode: "Código de verificación en dos pasos",
	login: "Iniciar sesión",
	dontHaveAnAccountYet: "¿No tienes cuenta aún?",
	accountCreateOne: "¡Crea una!",
	forgotYourPassword: "¿Olvidaste tu contraseña?",
	couldNotFindDefaultFolder: "No se pudo encontrar la carpeta predefinida",
	unlockLink: "Desbloquear",
	download: "Descargar",
	cannotDownloadEmptyFolder: "No se puede descargar una carpeta vacía",
	thisFolderIsEmpty: "Esta carpeta está vacía",
	linkFolderEmptyInfo: "Una vez los archivos y carpetas sean añadidas a esta carpeta aparecerán aquí",
	confirmPassword: "Confirmar contraseña",
	createAccount: "Crear cuenta",
	alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
	loginEx: "¡Iniciar sesión!",
	resendConfirmationEmail: "Reenviar correo de confirmación",
	reportAbuseModal_spam: "Spam",
	reportAbuseModal_dmca: "Infracción de derechos de autor",
	reportAbuseModal_cp: "Material de abuso sexual infantil",
	reportAbuseModal_stolen: "Información robada",
	reportAbuseModal_malware: "Malware",
	reportAbuseModal_other: "Otro",
	abuseReport: "Reportar abuso",
	abuseReportModalInfo: "Usa este formulario para reportar archivos compartidos inapropiados, ilegales o dañinos.",
	yourEmailAddress: "Tu correo electrónico",
	abuseReportModalInfoPlaceholder:
		"Por favor, danos todos los detalles posibles para que podamos tomar las medidas correspondientes (opcional)",
	send: "Enviar",
	abuseReportModalReason: "Por favor, elije un motivo",
	avatarUploadMaxFileSize: "El tamaño del archivo no puede exceder los 3MB",
	storageUsed: "Almacenamiento usado",
	storageUsedInfo: "__USED__ de __MAX__ en uso",
	storageUsedFilesAndFolders: "__USED__ archivos",
	storageUsedVersionedFiles: "__USED__ archivos versionados",
	storageUsedFree: "__FREE__ libres",
	avatar: "Foto de perfil",
	edit: "Editar",
	personalInformation: "Información personal",
	darkMode: "Modo oscuro",
	language: "Idioma",
	change: "Cambiar",
	requestAccountData: "Solicitar datos de la cuenta",
	request: "Solicitar",
	logout: "Cerrar sesión",
	versionedFiles: "Archivos versionados",
	delete: "Eliminar",
	allFilesAndFolders: "Todos los archivos y carpetas",
	deleteAccount: "Eliminar cuenta",
	changePassword: "Cambiar contraseña",
	tfa: "Verificación en dos pasos",
	subMoreInfo: "Más información sobre tu subscripción",
	paymentMethod: "Método de pago",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "Cripto",
	update: "Actualizar",
	cancel: "Cancelar",
	noSubs: "No hay subscripciones activas.",
	noInvoices: "No hay facturas aún.",
	planFeatures_1: "Banda ancha",
	planFeatures_2: "Encriptación del lado del cliente",
	planFeatures_3: "Tecnología de cero conocimiento",
	planTerms_monthly: "Mensual",
	planTerms_annually: "Anual",
	planTerms_lifetime: "Permanente",
	planTerms_starter: "Inicial",
	buyNow: "Comprar ahora",
	general: "General",
	settings: "Ajustes",
	security: "Seguridad",
	plans: "Planes",
	subs: "Subscripciones",
	events: "Eventos",
	invoices: "Facturas",
	invalidEmail: "Correo electrónico incorrecto",
	invalidPassword: "Contraseña incorrecta",
	emailsDoNotMatch: "Los correos electrónicos no son iguales",
	changeEmailPleaseConfirm: "Por favor, confirma tu nuevo correo electrónico haciendo clic en el enlace enviado",
	newEmail: "Nuevo correo electrónico",
	confirmNewEmail: "Confirmar nuevo correo electrónico",
	save: "Guardar",
	firstName: "Nombre",
	lastName: "Apellido",
	companyName: "Nombre de la compañía",
	vatId: "ID de IVA",
	street: "Calle",
	streetNumber: "Número de la calle",
	city: "Ciudad",
	postalCode: "Código postal",
	country: "País",
	areYouSure: "¿Estás seguro?",
	areYouSureDeleteAllVersioned: "¿Deseas eliminar todos los archivos versionados? ¡Esta acción no se puede deshacer!",
	areYouSureDeleteAll: "¿Deseas eliminar todos los archivos y carpetas? ¡Esta acción no se puede deshacer!",
	invalidNewPassword: "Contraseña nueva inválida",
	invalidCurrentPassword: "Contraseña actual inválida",
	newPasswordsDontMatch: "Las contraseñas nuevas no son iguales",
	invalidMasterKeys: "Las llaves maestras son inválidas",
	passwordChanged: "Contraseña cambiada",
	newPassword: "Nueva contraseña",
	confirmNewPassword: "Confirmar nueva contraseña",
	currentPassword: "Contraseña actual",
	deleteAccountConfirm:
		"Por favor, confirma la eliminación de la cuenta haciendo clic en el enlace que ha recibido en tu correo electrónico",
	areYouSureDeleteAccount: "¿Realmente deseas eliminar tu cuenta? Enviaremos un enlace de confirmación a tu correo electrónico.",
	invalid2FACode: "Código de verificación en dos pasos inválido",
	enable2FA: "Habilitar verificación en dos pasos",
	copy: "Copiar",
	enter2FA: "Introducir código de verificación en dos pasos",
	enable: "Activar",
	recoveryKeys: "Claves de recuperación",
	recoveryKeysInfo:
		"Por favor, guarda en un lugar seguro tus claves de recuperación. Es la única forma de recuperar tu cuenta en caso de perder el disposivo con verificación en dos pasos.",
	close: "Cerrar",
	disable2FA: "Deshabilitar la verificación de dos pasos",
	disable: "Desactivar",
	sharedWithMe: "Compartido conmigo",
	sharedWithOthers: "Compartido con otros",
	links: "Enlaces",
	favorites: "Favoritos",
	recents: "Recientes",
	trash: "Papelera",
	cloudDrive: "Unidad en la nube",
	emptyTrash: "Vaciar papelera",
	monthlyRecurring: "Mensual",
	annuallyRecurring: "Anual",
	oneTimePayment: "Pago de una sola vez",
	buyRecurringInfo:
		"Al comprar un plan autorizas a Filen a cobrarte automáticamente la factura de forma periódica hasta que se cancele. Puedes cancelar en cualquier momento vía tu página de Cuenta. No hay reembolsos parciales.",
	buyLifetimeInfo: "Al comprar un plan autorizas a Filen a cobrarte usando tu método de pago. No hay reembolsos parciales.",
	creditDebit: "Tarjetas de crédito / débito",
	cancelSub: "Cancelar subscripción",
	cancelSubSure: "¿Deseas cancelar esta subscripción?",
	cancellingSub: "Cancelando subscripción",
	createFolder: "Crear carpeta",
	createTextFile: "Crear archivo de texto",
	uploadFiles: "Subir archivos",
	uploadFolders: "Subir carpetas",
	preview: "Previsualizar",
	preparingDownload: "Preparando descarga",
	normalDownload: "Descarga normal",
	zipDownload: "Descarga en ZIP",
	publicLink: "Enlace público",
	share: "Compartir",
	versions: "Versiones",
	color: "Color",
	color_default: "Predefinido",
	color_blue: "Azul",
	color_green: "Verde",
	color_purple: "Morado",
	color_red: "Rojo",
	color_gray: "Gris",
	unfavorite: "No favorito",
	favorite: "Favorito",
	rename: "Renombrar",
	move: "Mover",
	selectDestination: "Seleccionar destino",
	restore: "Restaurar",
	deletePerm: "Eliminar permanentemente",
	remove: "Eliminar",
	stopSharing: "Dejar de compartir",
	cookieConsent: "Este sitio web usa cookies para medir y mejorar tu experiencia.",
	accept: "Aceptar",
	optOut: "Rechazar",
	onlyNeeded: "Solo las necesarias",
	pleaseChooseDiffName: "Por favor, elije un nombre distinto",
	newFolderName: "Nombre",
	itemsMovedToTrash: "__COUNT__ elementos movidos a la papelera",
	couldNotMoveToTrash: "No se pudo mover __NAME__ a la papelera: __ERR__",
	deleteModalSure: "¿Deseas eliminar __COUNT__ elementos?",
	deletePermModalSure: "¿Deseas eliminar permanentemente __COUNT__ elementos? ¡Esta acción no se puede deshacer!",
	itemsDeletedPerm: "__COUNT__ elementos eliminados permanentemente",
	couldNotDeletePerm: "No se pudo eliminar __NAME__ permanentemente: __ERR__",
	uploadHere: "Subir aquí",
	event: "Evento",
	date: "Fecha",
	ipAddress: "Dirección IP",
	na: "N/A",
	listEmpty_1: "No hay archivos o carpetas compartidas contigo aún",
	listEmpty_2: "Cuando alguien comparta archivos o carpetas contigo aparecerán aquí",
	listEmpty_3: "No hay archivos o carpetas compartidas con otros aún",
	listEmpty_4: "Cuando compartas archivos o carpetas con alguien aparecerán aquí",
	listEmpty_5: "No hay enlaces públicos aún",
	listEmpty_6: "Cuando crees enlaces públicos para archivos o carpetas aparecerán aquí",
	listEmpty_7: "No hay favoritos aún",
	listEmpty_8: "Una vez marques archivos o carpetas aparecerán aquí",
	listEmpty_9: "No hay recientes aún",
	listEmpty_10: "Los archivos subidos recientemente aparecerán aquí",
	listEmpty_11: "Papelera vacía",
	listEmpty_12: "Cuando marques archivos o carpetas para ser eliminadas aparecerán aquí",
	listEmpty_13: "No hay archivos o carpetas subidas aún",
	listEmpty_14: "Arrastra y suelta archivos o carpetas aquí, o pulsa el botón de abajo",
	listFooterSelected: "__COUNT__ seleccionado de __TOTAL__",
	name: "Nombre",
	size: "Tamaño",
	lastModified: "Modificado por última vez ",
	moveModalBtn: "Mover __COUNT__ elementos a __DEST__",
	file: "Archivo",
	ctrlPlusS: "CTRL + S",
	exit: "Salir",
	fileHasBeenChanged: "El archivo ha sido modificado",
	textEditorExitSure: "¿Deseas salir sin guardar?",
	saveChanges: "Guardar cambios",
	expire_never: "Nunca",
	expire_1h: "1 hora",
	expire_6h: "6 horas",
	expire_1d: "1 día",
	expire_3d: "3 días",
	expire_7d: "7 días",
	expire_14d: "14 días",
	expire_30d: "30 días",
	addingItemsToPublicLink: "Añadiendo elementos al enlace público",
	addingItemsToPublicLinkProgress: "Añadiendo __LEFT__ elementos al enlace público",
	enabled: "Activado",
	disabled: "Desactivado",
	copied: "Copiado",
	publicLinkDownloadBtn: "Botón de descarga habilitado",
	publicLinkPassword: "Contraseña (dejar en blanco para desactivar)",
	removedSharedItems: "Eliminados __COUNT__ elementos compartidos",
	couldNotRemoveSharedItems: "No se pudo eliminar __NAME__: __ERR__",
	removeSharedItemsModalInfo: "¿Deseas eliminar __COUNT__ elementos? Ya no podrás acceder a ellos.",
	renameNewName: "Nuevo nombre",
	selectFromComputer: "Seleccionar del ordenador",
	upload: "Subir",
	files: "Archivos",
	folders: "Carpetas",
	newFolder: "Nueva carpeta",
	sharedWith: "Compartido conmigo",
	sharingItems: "Compartiendo elementos",
	sharingItemsProgress: "Compartiendo __LEFT__ elementos",
	itemsSharedWith: "__COUNT__ elementos compartidos con __EMAIL__",
	shareReceiver: "Correo electrónico del destinatario",
	upgrade: "Mejorar",
	stoppedSharingItems: "Dejaste de compartir __COUNT__ elementos",
	couldNotStopSharingItem: "No se pudo dejar de compartir __NAME__: __ERR__",
	stopSharingModalSure: "¿Deseas dejar de compartir __COUNT__ elementos? Los demás usuarios ya no podrán acceder a ellos.",
	aboutRemaining: "__TIME__ restantes",
	transferringItems: "Transferiendo __COUNT__ elementos",
	uploadingItems: "Subiendo __COUNT__ elementos",
	downloadingItems: "Descargando __COUNT__ elementos",
	new: "Nuevo",
	resume: "Reanudar",
	pause: "Pausar",
	stop: "Detener",
	done: "Terminado",
	noUploadsQueued: "No hay subidas en cola",
	creatingFolders: "Creando carpetas",
	creatingFoldersProgress: "Creando __LEFT__ carpetas",
	current: "Actual",
	create: "Crear",
	changingColor: "Cambiando el color de __COUNT__ elementos",
	couldNotChangeColor: "No se pudo cambiar el color de __NAME__: __ERR__",
	eventFileUploaded: "Archivo subido",
	eventFileVersioned: "Archivo versionado",
	eventVersionedFileRestored: "Archivo versionado restaurado",
	eventFileMoved: "Archivo movido",
	eventFileTrash: "Archivo movido a la papelera",
	eventFileRm: "Archivo eliminado",
	eventFileRestored: "Archivo restaurado",
	eventFileShared: "Archivo compartido",
	eventFileLinkEdited: "Enlace público del archivo editado",
	eventFolderTrash: "Carpeta movida a la papelera",
	eventFolderShared: "Carpeta compartida",
	eventFolderMoved: "Carpeta movida",
	eventFolderRenamed: "Carpeta renombrada",
	eventFolderCreated: "Carpeta creada",
	eventFolderRestored: "Carpeta restaurada",
	eventFolderColorChanged: "Color de la carpeta cambiado",
	eventLogin: "Inicio de sesión",
	eventDeleteVersioned: "Archivos versionados y carpetas eliminadas",
	eventDeleteAll: "Todos los archivos y carpetas eliminadas",
	eventDeleteUnfinished: "Cargas no finalizadas eliminadas",
	eventTrashEmptied: "Papelera vaciada",
	eventRequestAccountDeletion: "Eliminación de cuenta solicitada",
	event2FAEnabled: "Verificación en dos pasos activada",
	event2FADisabled: "Verificación en dos pasos desactivada",
	eventCodeRedeem: "Código reclamado",
	eventEmailChanged: "Correo electrónico cambiado",
	eventPasswordChanged: "Contraseña cambiada",
	eventRemovedSharedInItems: "Archivos compartidos entrantes eliminados",
	eventRemovedSharedOutItems: "Archivos compartidos salientes eliminados",
	eventFileUploadedInfo: "__NAME__ subido",
	eventFileVersionedInfo: "__NAME__ versionado",
	eventVersionedFileRestoredInfo: "Versión del archivo de __NAME__ restaurada",
	eventFileRenamedInfo: "__NAME__ renombrado a __NEW__",
	eventFileMovedInfo: "__NAME__ movido",
	fileRenamedInfo: "__NAME__ renombrado a __NEW__",
	eventFileTrashInfo: "__NAME__ movido a la papelera",
	eventFileRmInfo: "__NAME__ eliminado",
	eventFileRestoredInfo: "__NAME__ restaurado de la papelera",
	eventFileSharedInfo: "__NAME__ compartido con __EMAIL__",
	eventFileLinkEditedInfo: "Enlace público de __NAME__ editado",
	eventFolderTrashInfo: "__NAME__ movido a la papelera",
	eventFolderSharedInfo: "__NAME__ compartido con __EMAIL__",
	eventFolderMovedInfo: "__NAME__ movido",
	eventFolderRenamedInfo: "__NAME__ renombrado a __NEW__",
	eventFolderCreatedInfo: "__NAME__ creado",
	eventFolderRestoredInfo: "__NAME__ restaurado de la papelera",
	eventFolderColorChangedInfo: "Color de __NAME__ cambiado",
	eventLoginInfo: "Inicio de sesión",
	eventDeleteVersionedInfo: "Archivos y carpetas versionadas eliminadas",
	eventDeleteAllInfo: "Todos los archivos y carpetas eliminadas",
	eventDeleteUnfinishedInfo: "Subidas no finalizadas eliminadas",
	eventTrashEmptiedInfo: "Papelera vaciada",
	eventRequestAccountDeletionInfo: "Eliminación de cuenta solicitada",
	event2FAEnabledInfo: "Verificación en dos pasos activada",
	event2FADisabledInfo: "Verificación en dos pasos desactivada",
	eventCodeRedeemInfo: "Código __CODE__ reclamado",
	eventEmailChangedInfo: "Correo electrónico cambiado a __EMAIL__",
	eventPasswordChangedInfo: "Contraseña cambiada",
	eventRemovedSharedInItemsInfo: "__COUNT__ elementos compartidos conmigo de __EMAIL__ retirados",
	eventRemovedSharedOutItemsInfo: "__COUNT__ elementos compartidos con __EMAIL__ retirados",
	favoritingItems: "Marcar como favoritos __COUNT__ elementos",
	unfavoritingItems: "Desmarcar como favoritos __COUNT__ elementos",
	couldNotChangeFavStatus: "No se pudo cambiar el estado de favorito de __NAME__: __ERR__",
	pleaseChooseDiffDest: "Por favor, elije un destino distinto",
	movingItems: "Moviendo __COUNT__ elementos",
	folderExistsAtDest: "La carpeta con el nombre __NAME__ ya existe en el destino",
	couldNotMoveItem: "No se pudo mover __NAME__: __ERR__",
	restoringItems: "Restaurando __COUNT__ elementos",
	couldNotRestoreItem: "No se pudo restaurar __NAME__: __ERR__",
	changeEmail: "Cambiar correo electrónico",
	passwordsDoNotMatch: "Las contraseñas no son iguales",
	registerWeakPassword: "Su contraseña debe ser contener al menos 10 carácteres",
	invalidEmailOrPassword: "Correo electrónico o contraseña inválidos",
	unknownErrorSupp: "Ocurrió un error desconocido. Por favor, inténtalo de nuevo o contacte al soporte.",
	registerEmailAlreadyRegistered: "Este correo electrónico ya está registrado.",
	gotIt: "Entendido",
	registration: "Registro",
	registrationEmailInstructions:
		"Por favor, confirma tu correo electrónico para completar la registración. Te hemos enviado un enlace con instrucciones.",
	forgotPasswordEmailSent: "Te hemos enviado un correo electrónico con instrucciones sobre cómo recuperar tu contraseña.",
	maxStorageReached: "Almacenamiento lleno",
	maxStorageReachedInfo: "Has alcanzado tu capacidad de almacenamiento máxima. Por favor, mejora tu cuenta para subir más archivos.",
	upgradeNow: "Mejorar ahora",
	abuseReportSubmitted: "¡Reporte de abuso enviado!",
	invalidAbuseReason: "Motivo inválido",
	uploadErrored: "Error de subida",
	newTextFileName: "Nuevo nombre para el archivo de texto",
	passwordResetSuccess: "Contraseña cambiada. Por favor, vuelve a iniciar sesión en todos sus dispositivos.",
	resetPasswordBtn: "Restablecer contraseña",
	publicLinkDisabled: "Enlace público desactivado",
	expireAfter: "Expirar tras...",
	exportMasterKeys: "Exportar llaves maestras",
	export: "Exportar",
	resetPasswordCheckbox:
		"Entiendo que, al restablecer mi contraseña sin mis llaves maestras exportadas, voy a volver los datos guardados en mi cuenta inaccesibles debido a cómo el encriptado de fin a fin de cero conocimiento funciona.",
	invalidAuthVersion: "Versión de autenticación inválida",
	recoveryMasterKeysInput: "Llaves maestras exportadas",
	exportMasterKeysInfo:
		"Exportar tus llaves maestras hace que sea posible recuperar tu cuenta, en caso de que olvides tu contraseña. Por favor, asegúrate de exportar tus nuevas llaves maestras siempre que cambies tu contraseña.",
	import: "Importar",
	invite: "Invitar",
	yourReferralLink: "Su enlace",
	requestPayout: "Solicitar pago",
	invalidPayoutAddress: "Dirección de pago inválida",
	affiliatePayoutSuccess: "Solicitud de pago enviada",
	requestPayoutInfo: "Enviaremos las ganancias de tu comisión afiliada a la dirección de Bitcon otorgada en 1-7 días laborales.",
	comissionEarned: "Comisión obtenida",
	receivedBonusStorage: "Almacenamiento total adicional recibido",
	referInfo: "Recibe hasta __STORAGE__ de almacenamiento adicional invitando a otros",
	referInfo2:
		"Por cada amigo que invites a Filen recibirá __STORAGE__ -, y tu amigo también recibirá __OTHER_STORAGE__. Además, si tu amigo compra una subscripción de tu elección, usted ganará una comisión. El límite de pago por comisión es de __THRESHOLD__€. Tu porcentaje de ganancia es __RATE__%.",
	yourBtcAddress: "Tu dirección de Bitcoin",
	openUploads: "Abrir subidas",
	payNow: "Pagar factura ahora",
	emptyTrashModalSure: "¿Deseas vaciar la papelera? ¡Esta acción no se puede deshacer!",
	emptyTrashSuccess: "Papelera vaciada",
	itemRenamed: "Elemento renombrado",
	subCancelled: "Cancelada",
	support: "Soporte",
	finishing: "Terminando",
	searchNothingFound: '"__TERM__" no ha sido encontrado',
	queued: "En cola",
	searchInThisFolder: "Busca en esta carpeta...",
	fileVersioning: "Versionado de archivos",
	loginAlerts: "Alertas de acceso"
}

export default es
