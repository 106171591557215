const it: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "Invia istruzioni",
	goBack: "Indietro",
	invalidEmailAndPassword: "Indirizzo e-mail e password non validi",
	email: "E-mail",
	password: "Password",
	emailAddress: "Indirizzo e-mail",
	tfaCode: "Codice di autenticazione a due fattori",
	login: "Accesso",
	dontHaveAnAccountYet: "Non hai ancora un account?",
	accountCreateOne: "Creane uno!",
	forgotYourPassword: "Hai dimenticato la password?",
	couldNotFindDefaultFolder: "Impossibile trovare la cartella predefinita",
	unlockLink: "Sblocca",
	download: "Scarica",
	cannotDownloadEmptyFolder: "Impossibile scaricare una cartella vuota",
	thisFolderIsEmpty: "La cartella è vuota",
	linkFolderEmptyInfo: "Una volta aggiunti file o cartelle a questa cartella, essi appariranno qui",
	confirmPassword: "Conferma password",
	createAccount: "Crea account",
	alreadyHaveAnAccount: "Hai già un account?",
	loginEx: "Accesso!",
	resendConfirmationEmail: "Reinvia e-mail di conferma",
	reportAbuseModal_spam: "Spam",
	reportAbuseModal_dmca: "Violazione dei diritti d'autore",
	reportAbuseModal_cp: "Materiale pedopornografico",
	reportAbuseModal_stolen: "Dati rubati",
	reportAbuseModal_malware: "Malware",
	reportAbuseModal_other: "Altro",
	abuseReport: "Segnalazione di abuso",
	abuseReportModalInfo: "Utilizza questo modulo per segnalare file condivisi inappropriati, illegali o altrimenti dannosi.",
	yourEmailAddress: "Il tuo indirizzo e-mail",
	abuseReportModalInfoPlaceholder:
		"Fornisci tutti i dettagli in modo da poter intraprendere un'azione appropriata (facoltativo)",
	send: "Invia",
	abuseReportModalReason: "Scegli un motivo",
	avatarUploadMaxFileSize: "La dimensione del file non può superare i 3 MB",
	storageUsed: "Spazio di archiviazione usato",
	storageUsedInfo: "__USED__ di __MAX__ usato",
	storageUsedFilesAndFolders: "__USED__ file",
	storageUsedVersionedFiles: "__USED__ file versionati",
	storageUsedFree: "__FREE__ libero",
	avatar: "Avatar",
	edit: "Modifica",
	personalInformation: "Informazioni personali",
	darkMode: "Modalità scura",
	language: "Lingua",
	change: "Cambia",
	requestAccountData: "Richiedi i dati dell'account",
	request: "Richiesta",
	logout: "Disconnetti",
	versionedFiles: "File versionati",
	delete: "Elimina",
	allFilesAndFolders: "Tutti i file e le cartelle",
	deleteAccount: "Elimina account",
	changePassword: "Cambia password",
	tfa: "Autenticazione a due fattori",
	subMoreInfo: "Ulteriori informazioni sull'abbonamento",
	paymentMethod: "Metodo di pagamento",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "Crypto",
	update: "Aggiorna",
	cancel: "Annulla",
	noSubs: "Al momento non si dispone di abbonamenti attivi.",
	noInvoices: "Non hai ancora fatture.",
	planFeatures_1: "Larghezza di banda illimitata",
	planFeatures_2: "Crittografia lato client",
	planFeatures_3: "Tecnologia a conoscenza zero",
	planTerms_monthly: "Mensile",
	planTerms_annually: "Annuale",
	planTerms_lifetime: "A vita",
	planTerms_starter: "Iniziale",
	buyNow: "Acquista ora",
	general: "Generale",
	settings: "Impostazioni",
	security: "Sicurezza",
	plans: "Piani",
	subs: "Abbonamenti",
	events: "Eventi",
	invoices: "Fatture",
	invalidEmail: "E-mail non valida",
	invalidPassword: "Password non valida",
	emailsDoNotMatch: "Le e-mail non corrispondono",
	changeEmailPleaseConfirm: "Conferma il nuovo indirizzo e-mail facendo clic sul link inviato",
	newEmail: "Nuova e-mail",
	confirmNewEmail: "Conferma la nuova e-mail",
	save: "Salva",
	firstName: "Nome",
	lastName: "Cognome",
	companyName: "Azienda",
	vatId: "Partita IVA",
	street: "Via",
	streetNumber: "Numero civico",
	city: "Città",
	postalCode: "Codice postale",
	country: "Paese",
	areYouSure: "Sei sicuro?",
	areYouSureDeleteAllVersioned: "Sei sicuro di voler eliminare tutti i file versionati? Questa azione non può essere annullata!",
	areYouSureDeleteAll: "Sei sicuro di voler eliminare tutti i file e le cartelle? Questa azione non può essere annullata!",
	invalidNewPassword: "Nuova password non valida",
	invalidCurrentPassword: "Password corrente non valida",
	newPasswordsDontMatch: "Le nuove password non corrispondono",
	invalidMasterKeys: "Chiavi master non valide",
	passwordChanged: "Password modificata",
	newPassword: "Nuova password",
	confirmNewPassword: "Conferma nuova password",
	currentPassword: "Password attuale",
	deleteAccountConfirm: "Conferma l'eliminazione dell'account facendo clic sul link inviato all'indirizzo e-mail",
	areYouSureDeleteAccount:
		"Sei sicuro di voler cancellare il tuo account? Invieremo un link di conferma al tuo indirizzo e-mail.",
	invalid2FACode: "Codice di autenticazione a due fattori non valido",
	enable2FA: "Abilita l'autenticazione a due fattori",
	copy: "Copia",
	enter2FA: "Inserisci il codice di autenticazione a due fattori",
	enable: "Abilita",
	recoveryKeys: "Chiavi di recupero",
	recoveryKeysInfo:
		"Conserva la chiave di recupero in modo sicuro. È l'unico modo per recuperare il tuo account in caso di perdita del dispositivo 2FA.",
	close: "Chiudi",
	disable2FA: "Disabilita l'autenticazione a due fattori",
	disable: "Disabilita",
	sharedWithMe: "Condiviso con me",
	sharedWithOthers: "Condiviso con altri",
	links: "Link",
	favorites: "Preferiti",
	recents: "Recenti",
	trash: "Cestino",
	cloudDrive: "Cloud Drive",
	emptyTrash: "Svuota il cestino",
	monthlyRecurring: "Ricorrenza mensile",
	annuallyRecurring: "Ricorrenza annuale",
	oneTimePayment: "Pagamento una tantum",
	buyRecurringInfo:
		"Acquistando un piano, autorizzi Filen ad addebitarti automaticamente ogni periodo di fatturazione fino all'annullamento. Puoi annullare in qualsiasi momento tramite la pagina del tuo account. Nessun rimborso parziale.",
	buyLifetimeInfo: "Acquistando un piano, autorizzi Filen ad addebitare nel tuo metodo di pagamento. Nessun rimborso parziale.",
	creditDebit: "Carte di credito / debito",
	cancelSub: "Annulla abbonamento",
	cancelSubSure: "Sei sicuro di voler annullare l'abbonamento?",
	cancellingSub: "Annullamento dell'abbonamento",
	createFolder: "Crea cartella",
	createTextFile: "Crea file di testo",
	uploadFiles: "Carica file",
	uploadFolders: "Carica cartelle",
	preview: "Anteprima",
	preparingDownload: "Preparazione al trasferimento",
	normalDownload: "Scarica normale",
	zipDownload: "Scarica ZIP",
	publicLink: "Link pubblico",
	share: "Condividi",
	versions: "Versioni",
	color: "Colore",
	color_default: "Default",
	color_blue: "Blu",
	color_green: "Verde",
	color_purple: "Viola",
	color_red: "Rosso",
	color_gray: "Grigio",
	unfavorite: "Non preferito",
	favorite: "Preferito",
	rename: "Rinomina",
	move: "Sposta",
	selectDestination: "Seleziona destinazione",
	restore: "Ripristina",
	deletePerm: "Elimina definitivamente",
	remove: "Rimuovi",
	stopSharing: "Ferma condivisione",
	cookieConsent: "Questo sito utilizza i cookie per misurare e migliorare la tua esperienza.",
	accept: "Accetta",
	optOut: "Rifiuta",
	onlyNeeded: "Solo necessari",
	pleaseChooseDiffName: "Scegli un nome diverso",
	newFolderName: "Nome",
	itemsMovedToTrash: "__COUNT__ elementi spostati nel cestino",
	couldNotMoveToTrash: "Non è stato possibile spostare __NAME__ nel cestino: __ERR__",
	deleteModalSure: "Sei sicuro di voler eliminare __COUNT__ elementi?",
	deletePermModalSure: "Sei sicuro di voler eliminare definitivamente __COUNT__ elementi? Questa azione non può essere annullata!",
	itemsDeletedPerm: "__COUNT__ elementi eliminati in modo permanente",
	couldNotDeletePerm: "Impossibile eliminare __NAME__ in modo permanente: __ERR__",
	uploadHere: "Carica qui",
	event: "Evento",
	date: "Data",
	ipAddress: "Indirizzo IP",
	na: "N/D",
	listEmpty_1: "Nessun file o cartella condivisa con te",
	listEmpty_2: "Una volta che qualcuno condivide file o cartelle con te, essi appariranno qui",
	listEmpty_3: "Nessun file o cartella condivisa con altri",
	listEmpty_4: "Una volta che condividi file o cartelle con qualcuno, essi appariranno qui",
	listEmpty_5: "Non ci sono link pubblici",
	listEmpty_6: "Una volta creati i link pubblici per file o cartelle, essi appariranno qui",
	listEmpty_7: "Non ci sono preferiti",
	listEmpty_8: "Una volta contrassegnati come preferiti file o cartelle, essi appariranno qui",
	listEmpty_9: "Non ci sono dati recenti",
	listEmpty_10: "I file caricati di recente appariranno qui",
	listEmpty_11: "Non c'è nulla nel cestino",
	listEmpty_12: "Una volta contrassegnati per l'eliminazione, i file o le cartelle verranno visualizzati qui",
	listEmpty_13: "Nessun file o cartella caricati",
	listEmpty_14: "Trascina qui i file o le cartelle, o premi il pulsante sottostante",
	listFooterSelected: "__COUNT__ selezionati di __TOTAL__",
	name: "Nome",
	size: "Dimensione",
	lastModified: "Ultima modifica",
	moveModalBtn: "Sposta __COUNT__ elementi in __DEST__",
	file: "File",
	ctrlPlusS: "CTRL + S",
	exit: "Esci",
	fileHasBeenChanged: "Il file è stato modificato",
	textEditorExitSure: "Sei sicuro di voler uscire senza salvare?",
	saveChanges: "Salva le modifiche",
	expire_never: "Mai",
	expire_1h: "1 ora",
	expire_6h: "6 ore",
	expire_1d: "1 giorno",
	expire_3d: "3 giorni",
	expire_7d: "7 giorni",
	expire_14d: "14 giorni",
	expire_30d: "30 giorni",
	addingItemsToPublicLink: "Aggiunta di elementi al link pubblico",
	addingItemsToPublicLinkProgress: "Aggiunta di __LEFT__ elementi al link pubblico",
	enabled: "Abilita",
	disabled: "Disabilita",
	copied: "Copiato",
	publicLinkDownloadBtn: "Bottone scarica abilitato",
	publicLinkPassword: "Password (lasciare vuoto per disabilitarla)",
	removedSharedItems: "Rimuovere __COUNT__ elementi condivisi",
	couldNotRemoveSharedItems: "Impossibile rimuovere __NAME__: __ERR__",
	removeSharedItemsModalInfo:
		"Sei sicuro di voler rimuovere __COUNT__ elementi? Non sarà più possibile accedervi.",
	renameNewName: "Nuovo nome",
	selectFromComputer: "Seleziona dal computer",
	upload: "Carica",
	files: "File",
	folders: "Cartella",
	newFolder: "Nuova cartella",
	sharedWith: "Condividi con",
	sharingItems: "Condividi elementi",
	sharingItemsProgress: "Condivi __LEFT__ elementi",
	itemsSharedWith: "__COUNT__ elementi condivisi con __EMAIL__",
	shareReceiver: "E-mail dell'account Filen del destinatario",
	upgrade: "Aggiorna",
	stoppedSharingItems: "Ferma la condivisione di __COUNT__ elementi",
	couldNotStopSharingItem: "Impossibile fermare la condivisione di __NAME__: __ERR__",
	stopSharingModalSure:
		"Sei sicuro di voler interrompere la condivisione di __COUNT__ elementi? Gli altri utenti non potranno più accedervi.",
	aboutRemaining: "__TIME__ rimanente",
	transferringItems: "Trasferimento di __COUNT__ elementi",
	uploadingItems: "Caricamento di __COUNT__ elementi",
	downloadingItems: "Trasferimento di __COUNT__ elementi",
	new: "Nuovo",
	resume: "Riprendi",
	pause: "Pausa",
	stop: "Ferma",
	done: "Fatto",
	noUploadsQueued: "Nessun trasferimento in coda",
	creatingFolders: "Creazione cartelle",
	creatingFoldersProgress: "Creazione di __LEFT__ cartelle",
	current: "Attuale",
	create: "Crea",
	changingColor: "Cambio di colore per __COUNT__ elementi",
	couldNotChangeColor: "Impossibile cambiare colore per __NAME__: __ERR__",
	eventFileUploaded: "Caricamento file",
	eventFileVersioned: "File versionato",
	eventVersionedFileRestored: "File versionato ripristinato",
	eventFileMoved: "File spostato",
	eventFileTrash: "File spostato nel cestino",
	eventFileRm: "File eliminato",
	eventFileRestored: "File ripristinato",
	eventFileShared: "File condiviso",
	eventFileLinkEdited: "Link pubblico al file modificato",
	eventFolderTrash: "Cartella spostata nel cestino",
	eventFolderShared: "Cartalla condivisa",
	eventFolderMoved: "Cartella spostata",
	eventFolderRenamed: "Cartella rinominata",
	eventFolderCreated: "Cartella creata",
	eventFolderRestored: "Cartella ripristinata",
	eventFolderColorChanged: "Colore della cartella cambiato",
	eventLogin: "Accesso",
	eventDeleteVersioned: "File e cartelle versionati eliminati",
	eventDeleteAll: "Tutti i file e le cartelle eliminati",
	eventDeleteUnfinished: "Caricamenti non completati eliminati",
	eventTrashEmptied: "Cestino svuotato",
	eventRequestAccountDeletion: "Richiesta di cancellazione dell'account",
	event2FAEnabled: "2FA abilitato",
	event2FADisabled: "2FA disabilitato",
	eventCodeRedeem: "Codice riscattato",
	eventEmailChanged: "E-mail modificata",
	eventPasswordChanged: "Password modificata",
	eventRemovedSharedInItems: "Azioni in entrata eliminate",
	eventRemovedSharedOutItems: "Azioni in uscita eliminate",
	eventFileUploadedInfo: "__NAME__ caricato",
	eventFileVersionedInfo: "__NAME__ versionato",
	eventVersionedFileRestoredInfo: "Versione del file __NAME__ ripristinata",
	eventFileRenamedInfo: "__NAME__ rinominato in __NEW__",
	eventFileMovedInfo: "__NAME__ spostato",
	fileRenamedInfo: "__NAME__ rinominato in __NEW__",
	eventFileTrashInfo: "__NAME__ spostato nel cestino",
	eventFileRmInfo: "__NAME__ eliminato",
	eventFileRestoredInfo: "__NAME__ ripristinato dal cestino",
	eventFileSharedInfo: "__NAME__ condiviso con __EMAIL__",
	eventFileLinkEditedInfo: "__NAME__ link pubblico modificato",
	eventFolderTrashInfo: "__NAME__ spostato nel cestino",
	eventFolderSharedInfo: "__NAME__ condiviso con __EMAIL__",
	eventFolderMovedInfo: "__NAME__ spostato",
	eventFolderRenamedInfo: "__NAME__ rinominato in __NEW__",
	eventFolderCreatedInfo: "__NAME__ creato",
	eventFolderRestoredInfo: "__NAME__ ripristinato dal cestino",
	eventFolderColorChangedInfo: "__NAME__ colore cambiato",
	eventLoginInfo: "Accesso effettuato",
	eventDeleteVersionedInfo: "File e cartelle versionati eliminati",
	eventDeleteAllInfo: "Tutti i file e le cartelle eliminati",
	eventDeleteUnfinishedInfo: "Caricamenti non completati eliminati",
	eventTrashEmptiedInfo: "Cestino svuotato",
	eventRequestAccountDeletionInfo: "Richiesta di cancellazione dell'account",
	event2FAEnabledInfo: "2FA abilitato",
	event2FADisabledInfo: "2FA disabilitato",
	eventCodeRedeemInfo: "Codice __CODE__ riscattato",
	eventEmailChangedInfo: "Email modificata in __EMAIL__",
	eventPasswordChangedInfo: "Password modificata",
	eventRemovedSharedInItemsInfo: "__COUNT__ azioni in entrata da __EMAIL__ rimosse",
	eventRemovedSharedOutItemsInfo: "__COUNT__ azioni in uscita da __EMAIL__ rimosse",
	favoritingItems: "Preferiti __COUNT__ elementi",
	unfavoritingItems: "Non preferiti __COUNT__ elementi",
	couldNotChangeFavStatus: "Impossibile modificare lo stato preferito per __NAME__: __ERR__",
	pleaseChooseDiffDest: "Scegli una destinazione diversa",
	movingItems: "Spostamento di __COUNT__ elementi",
	folderExistsAtDest: "Una cartella con il nome __NAME__ esiste già nella destinazione",
	couldNotMoveItem: "Impossibile spostare __NAME__: __ERR__",
	restoringItems: "Ripristino di __COUNT__ elementi",
	couldNotRestoreItem: "Impossibile ripristinare __NAME__: __ERR__",
	changeEmail: "Cambia e-mail",
	passwordsDoNotMatch: "Le password non corrispondono",
	registerWeakPassword: "La password deve essere lunga almeno 10 caratteri",
	invalidEmailOrPassword: "E-mail o password non valida",
	unknownErrorSupp: "Si è verificato un errore sconosciuto. Riprovare o contattare l'assistenza.",
	registerEmailAlreadyRegistered: "Questo indirizzo e-mail è già registrato.",
	gotIt: "Capito",
	registration: "Registrazione",
	registrationEmailInstructions:
		"Per completare la registrazione, conferma l'indirizzo e-mail. Ti è stato inviato un link con le istruzioni.",
	forgotPasswordEmailSent: "Ti è stata inviata un'e-mail con le istruzioni per reimpostare la password.",
	maxStorageReached: "Spazio di archiviazione pieno",
	maxStorageReachedInfo:
		"È stato raggiunto il limite massimo di spazio di archiviazione. Aggiorna il tuo account per caricare altri file.",
	upgradeNow: "Aggiorna ora",
	abuseReportSubmitted: "Segnalazione di abuso inviata!",
	invalidAbuseReason: "Motivo non valido",
	uploadErrored: "Fallito",
	newTextFileName: "Nuovo nome del file di testo",
	passwordResetSuccess: "La password è stata modificata. Effettuare nuovamente il login da tutti i dispositivi.",
	resetPasswordBtn: "Reimpostare la password",
	publicLinkDisabled: "Link pubblico disabilitato",
	expireAfter: "Scade dopo",
	exportMasterKeys: "Esporta chiavi master",
	export: "Esporta",
	resetPasswordCheckbox:
		"Sono consapevole che, reimpostando la mia password senza le mie chiavi master esportate, renderò inaccessibili tutti i dati memorizzati sul mio account a causa del funzionamento della crittografia end-to-end a conoscenza zero.",
	invalidAuthVersion: "Versione di autenticazione non valida",
	recoveryMasterKeysInput: "Chiavi master esportate",
	exportMasterKeysInfo:
		"L'esportazione delle chiavi master consente di recuperare l'account senza perdita di dati nel caso in cui dimentichi la password. Assicurati di esportare le nuove chiavi master ogni volta che cambi la password.",
	import: "Importa",
	invite: "Invita",
	yourReferralLink: "Il tuo link",
	requestPayout: "Richiesta di pagamento",
	invalidPayoutAddress: "Indirizzo di pagamento non valido",
	affiliatePayoutSuccess: "Richiesta di pagamento inoltrata",
	requestPayoutInfo:
		"Invieremo la tua commissione di affiliazione guadagnata all'indirizzo Bitcoin indicato entro 1-7 giorni lavorativi.",
	comissionEarned: "Commissione guadagnata",
	receivedBonusStorage: "Totale spazio di archiviazione bonus ricevuto",
	referInfo: "Ricevi fino a __STORAGE__ di spazio di archiviazione invitando altre persone",
	referInfo2:
		"Per ogni amico che inviti a Filen ricevi __STORAGE__ - e il tuo amico riceve anche __OTHER_STORAGE__. Il tuo amico può registrarsi per un abbonamento a sua scelta e tu guadagni una commissione. La soglia di pagamento delle commissioni è di __THRESHOLD__€. Il tuo tasso è __RATE__%.",
	yourBtcAddress: "Il tuo indirizzo Bitcoin",
	openUploads: "Caricamenti aperti",
	payNow: "Paga la fattura ora",
	emptyTrashModalSure: "Sei sicuro di voler svuotare il cestino? Questa azione non può essere annullata!",
	emptyTrashSuccess: "Cestino svuotato",
	itemRenamed: "Elemento rinominato",
	subCancelled: "Annullato",
	support: "Supporto",
	finishing: "Terminato",
	searchNothingFound: '"__TERM__" non trovato',
	queued: "In coda"
}

export default it
