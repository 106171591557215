const ja: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "手順を送信",
	goBack: "戻る",
	invalidEmailAndPassword: "メールアドレスとパスワードが違います",
	email: "メールアドレス",
	password: "パスワード",
	emailAddress: "メールアドレス",
	tfaCode: "二段階認証",
	login: "ログイン",
	dontHaveAnAccountYet: "アカウントをお持ちではない場合",
	accountCreateOne: "こちらからアカウントを作成できます",
	forgotYourPassword: "パスワードを忘れた",
	couldNotFindDefaultFolder: "既定のフォルダーが見つかりませんでした",
	unlockLink: "開く",
	download: "ダウンロード",
	cannotDownloadEmptyFolder: "空のフォルダーはダウンロードできません",
	thisFolderIsEmpty: "このフォルダーは空です",
	linkFolderEmptyInfo: "ファイルやフォルダーを作成すると、ここに表示されます。",
	confirmPassword: "パスワードをもう一度入力",
	createAccount: "アカウントを作成",
	alreadyHaveAnAccount: "アカウントを既にお持ちですか？",
	loginEx: "ログイン",
	resendConfirmationEmail: "認証メールを再送信",
	reportAbuseModal_spam: "詐欺",
	reportAbuseModal_dmca: "著作権侵害",
	reportAbuseModal_cp: "児童ポルノ関連",
	reportAbuseModal_stolen: "盗難データ",
	reportAbuseModal_malware: "マルウェア",
	reportAbuseModal_other: "その他",
	abuseReport: "通報",
	abuseReportModalInfo: "危険なファイルや違法なファイルなど、不適切なファイルがある場合はこちらからご報告ください。",
	yourEmailAddress: "お客様のメールアドレス",
	abuseReportModalInfoPlaceholder: "適切な措置を講じるため、詳細をご入力ください。（任意）",
	send: "送信",
	abuseReportModalReason: "種類を選択",
	avatarUploadMaxFileSize: "ファイルの上限サイズは3MBまでです",
	storageUsed: "容量",
	storageUsedInfo: "__MAX__中__USED__使用中",
	storageUsedFilesAndFolders: "ファイル　__USED__",
	storageUsedVersionedFiles: "過去ファイル　__USED__",
	storageUsedFree: "空き容量　__FREE__",
	avatar: "プロフィール画像",
	edit: "編集",
	personalInformation: "個人情報",
	darkMode: "ダークモード",
	language: "言語",
	change: "変更",
	requestAccountData: "アカウント情報のダウンロード",
	request: "ダウンロード",
	logout: "ログアウト",
	versionedFiles: "過去ファイル",
	delete: "消去",
	allFilesAndFolders: "すべてのファイルとフォルダー",
	deleteAccount: "アカウントを削除",
	changePassword: "パスワードを変更",
	tfa: "二段階認証",
	subMoreInfo: "ご契約の詳細",
	paymentMethod: "お支払い方法",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "暗号通貨",
	update: "更新",
	cancel: "キャンセル",
	noSubs: "契約中のプランはありません",
	noInvoices: "インボイスはありません",
	planFeatures_1: "無制限のダウンロード・アップロード",
	planFeatures_2: "端末上での暗号化",
	planFeatures_3: "ゼロ知識証明",
	planTerms_monthly: "月ごと",
	planTerms_annually: "年ごと",
	planTerms_lifetime: "買い切り",
	planTerms_starter: "少額",
	buyNow: "購入",
	general: "一般",
	settings: "設定",
	security: "セキュリティー",
	plans: "プラン",
	subs: "契約",
	events: "イベントログ",
	invoices: "インボイス",
	invalidEmail: "メールアドレスが違います",
	invalidPassword: "パスワードが違います",
	emailsDoNotMatch: "メールアドレスが一致していません",
	changeEmailPleaseConfirm: "認証メールを送信しました。メールの中のリンクを開き、メールアドレスを認証してください。",
	newEmail: "新しいメールアドレス",
	confirmNewEmail: "もう一度新しいメールアドレスを入力",
	save: "保存",
	firstName: "名前",
	lastName: "苗字",
	companyName: "会社名",
	vatId: "VAT番号",
	street: "市区町村",
	streetNumber: "番地",
	city: "都道府県",
	postalCode: "郵便番号",
	country: "国",
	areYouSure: "続行しますか？",
	areYouSureDeleteAllVersioned:
		"過去のファイルをすべて削除してよろしいですか？削除したファイルは復元できなくなります。",
	areYouSureDeleteAll: "すべてのファイルとフォルダーを削除してよろしいですか？削除したファイルは復元できません。",
	invalidNewPassword: "新しいパスワードが間違っています",
	invalidCurrentPassword: "現在のパスワードが違います",
	newPasswordsDontMatch: "新しいパスワードが一致していません",
	invalidMasterKeys: "マスターキーが違います",
	passwordChanged: "パスワードを変更しました",
	newPassword: "新しいパスワード",
	confirmNewPassword: "新しいパスワードをもう一度入力",
	currentPassword: "現在のパスワード",
	deleteAccountConfirm:
		"アカウントに確認メールを送信しました。メール内のリンクを開き、アカウントの削除を続行してください。",
	areYouSureDeleteAccount: "アカウントを削除しますか？続行される場合、確認用のメールが送信されます。",
	invalid2FACode: "二段階認証のコードが違います",
	enable2FA: "二段階認証を有効化",
	copy: "コピー",
	enter2FA: "二段階認証のコードを入力",
	enable: "有効化",
	recoveryKeys: "回復キー",
	recoveryKeysInfo:
		"回復キーは、別の安全なところに保管しておいてください。二段階認証ができない場合や使えない場合、これがないとログインができなくなります。",
	close: "閉じる",
	disable2FA: "二段階認証を無効化",
	disable: "無効化",
	sharedWithMe: "共有（自分宛）",
	sharedWithOthers: "共有（他人宛）",
	links: "共有リンク",
	favorites: "お気に入り",
	recents: "最近",
	trash: "ごみ箱",
	cloudDrive: "クラウド",
	emptyTrash: "ごみ箱を空にする",
	monthlyRecurring: "一ヶ月毎のお支払い",
	annuallyRecurring: "一年毎のお支払い",
	oneTimePayment: "一回きりのお支払い",
	buyRecurringInfo:
		"購入すると、お客様が契約を解除するまで自動的に料金の引き落としが行われます。契約の解除はアカウントのページから行っていただけます。部分的な返金には対応しておりません。",
	buyLifetimeInfo: "購入すると、決済が自動的に行われます。部分的な返金は行っておりません。",
	creditDebit: "クレジットカードまたはデビットカードが使用可",
	cancelSub: "契約を解除",
	cancelSubSure: "この契約を解除しますか？",
	cancellingSub: "契約を解除",
	createFolder: "フォルダーを作成",
	createTextFile: "テキストファイルを作成",
	uploadFiles: "ファイルをアップロード",
	uploadFolders: "フォルダーをアップロード",
	preview: "プレビュー",
	preparingDownload: "ダウンロードを準備中",
	normalDownload: "そのままでダウンロード",
	zipDownload: "ZIPにしてダウンロード",
	publicLink: "共有リンク",
	share: "共有",
	versions: "変更履歴",
	color: "色",
	color_default: "標準",
	color_blue: "青色",
	color_green: "緑色",
	color_purple: "紫色",
	color_red: "赤色",
	color_gray: "グレー",
	unfavorite: "お気に入りを解除",
	favorite: "お気に入り",
	rename: "改名",
	move: "移動",
	selectDestination: "移動先を選択",
	restore: "復元",
	deletePerm: "完全に削除",
	remove: "削除",
	stopSharing: "共有を停止",
	cookieConsent: "本サイトは、サイト内におけるお客様の使用状況を集計し、改善を行うためにクッキーを使用しております。",
	accept: "許可",
	optOut: "拒否",
	onlyNeeded: "必要なもののみ許可",
	pleaseChooseDiffName: "その名前は使用できません",
	newFolderName: "名前を入力",
	itemsMovedToTrash: "__COUNT__件のアイテムをごみ箱に移動しました",
	couldNotMoveToTrash: "__NAME__をごみ箱に移動できませんでした。（エラー内容：__ERR__）",
	deleteModalSure: "__COUNT__件のアイテムを削除してよろしいですか？",
	deletePermModalSure: "__COUNT__件のアイテムを完全に削除してよろしいですか？削除したものは復元できません。",
	itemsDeletedPerm: "__COUNT__件のアイテムを完全に削除しました",
	couldNotDeletePerm: "__NAME__を完全に削除できませんでした。（エラー内容：__ERR__）",
	uploadHere: "ここにアップロード",
	event: "イベントログ",
	date: "日付",
	ipAddress: "IPアドレス",
	na: "N/A",
	listEmpty_1: "自分宛の共有はありません",
	listEmpty_2: "ファイルやフォルダーを他のユーザーから共有されると、ここに表示されます。",
	listEmpty_3: "他人宛の共有はありません",
	listEmpty_4: "ファイルやフォルダーを他のユーザーと共有すると、ここに表示されます。",
	listEmpty_5: "共有リンクはありません",
	listEmpty_6: "共有リンクを作成すると、ここに表示されます。",
	listEmpty_7: "お気に入りがありません",
	listEmpty_8: "ファイルやフォルダーをお気に入りすると、ここに表示されます。",
	listEmpty_9: "表示するものはありません",
	listEmpty_10: "最近アップロードしたものはここに表示されます。",
	listEmpty_11: "ごみ箱は空です",
	listEmpty_12: "ファイルやフォルダーをごみ箱に移動すると、ここに表示されます。",
	listEmpty_13: "アップロード済みのファイルやフォルダーはありません",
	listEmpty_14: "ドラッグ&ドロップか、下のボタンでファイルやフォルダーをアップロードできます。",
	listFooterSelected: "__TOTAL__件中__COUNT__件を選択中",
	name: "名前",
	size: "サイズ",
	lastModified: "最終編集",
	moveModalBtn: "__COUNT__件のアイテムを__DEST__に移動",
	file: "ファイル",
	ctrlPlusS: "CTRL＋S",
	exit: "終了",
	fileHasBeenChanged: "ファイルが変更されました",
	textEditorExitSure: "保存せずに終了しますか？",
	saveChanges: "保存する",
	expire_never: "なし",
	expire_1h: "1時間",
	expire_6h: "6時間",
	expire_1d: "1日",
	expire_3d: "3日",
	expire_7d: "7日",
	expire_14d: "14日",
	expire_30d: "30日",
	addingItemsToPublicLink: "共有リンク内にアイテムを追加中",
	addingItemsToPublicLinkProgress: "あと__LEFT__件",
	enabled: "オン",
	disabled: "オフ",
	copied: "コピー",
	publicLinkDownloadBtn: "ダウンロードを許可",
	publicLinkPassword: "パスワード（任意）",
	removedSharedItems: "__COUNT__件の共有アイテムを削除しました",
	couldNotRemoveSharedItems: "__NAME__を削除できませんでした。（エラー内容：__ERR__）",
	removeSharedItemsModalInfo:
		"__COUNT__件のアイテムの共有を停止しますか？相手が再度共有するまでアクセスできなくなります。",
	renameNewName: "新しい名前を入力",
	selectFromComputer: "ファイルから選択",
	upload: "アップロード",
	files: "ファイル",
	folders: "フォルダー",
	newFolder: "新しいフォルダー",
	sharedWith: "共有相手：",
	sharingItems: "共有中",
	sharingItemsProgress: "あと__LEFT__件",
	itemsSharedWith: "__COUNT__件のアイテムを__EMAIL__と共有しました",
	shareReceiver: "共有相手のメールアドレス",
	upgrade: "アップグレード",
	stoppedSharingItems: "__COUNT__件のアイテムの共有を停止しました",
	couldNotStopSharingItem: "__NAME__の共有を停止できませんでした（エラー内容：__ERR__）",
	stopSharingModalSure:
		"__COUNT__件のアイテムの共有を停止してよろしいですか？共有相手はファイルを閲覧することができなくなります。",
	aboutRemaining: "残り約__TIME__",
	transferringItems: "__COUNT__件のファイルを転送中",
	uploadingItems: "__COUNT__件のファイルをアップロード中",
	downloadingItems: "__COUNT__件のファイルをダウンロード中",
	new: "新規",
	resume: "続行",
	pause: "一時停止",
	stop: "停止",
	done: "完了",
	noUploadsQueued: "待機中のアップロードはありません",
	creatingFolders: "フォルダーを作成中",
	creatingFoldersProgress: "残り__LEFT__件",
	current: "現在",
	create: "作成",
	changingColor: "__COUNT__件のフォルダーの色を変更中",
	couldNotChangeColor: "__NAME__の色を変更できませんでした。（エラー内容：__ERR__）",
	eventFileUploaded: "ファイルのアップロード",
	eventFileVersioned: "ファイルの更新",
	eventVersionedFileRestored: "更新履歴からファイルを復元",
	eventFileMoved: "ファイルの移動",
	eventFileTrash: "ファイルをごみ箱に移動",
	eventFileRm: "ファイルの削除",
	eventFileRestored: "ファイルの復元",
	eventFileShared: "ファイルの共有",
	eventFileLinkEdited: "共有リンクの設定変更",
	eventFolderTrash: "フォルダーをごみ箱に移動",
	eventFolderShared: "フォルダーの共有",
	eventFolderMoved: "フォルダーの移動",
	eventFolderRenamed: "フォルダーの改名",
	eventFolderCreated: "フォルダーの作成",
	eventFolderRestored: "フォルダーの復元",
	eventFolderColorChanged: "フォルダー色の変更",
	eventLogin: "ログイン",
	eventDeleteVersioned: "過去のファイルおよびフォルダーの削除",
	eventDeleteAll: "すべてのファイルおよびフォルダーの削除",
	eventDeleteUnfinished: "未完了のアップロードの破棄",
	eventTrashEmptied: "ごみ箱内の完全削除",
	eventRequestAccountDeletion: "アカウント削除の申請",
	event2FAEnabled: "二段階認証を有効化",
	event2FADisabled: "二段階認証を無効化",
	eventCodeRedeem: "コードの使用",
	eventEmailChanged: "メールアドレスの変更",
	eventPasswordChanged: "パスワードの変更",
	eventRemovedSharedInItems: "自分宛の共有の停止",
	eventRemovedSharedOutItems: "他人宛の共有の停止",
	eventFileUploadedInfo: "__NAME__のアップロード",
	eventFileVersionedInfo: "__NAME__の更新",
	eventVersionedFileRestoredInfo: "__NAME__の過去ファイルの復元",
	eventFileRenamedInfo: "__NAME__を__NEW__へ改名",
	eventFileMovedInfo: "__NAME__の移動",
	fileRenamedInfo: "__NAME__を__NEW__へ改名",
	eventFileTrashInfo: "__NAME__をごみ箱に移動",
	eventFileRmInfo: "__NAME__の削除",
	eventFileRestoredInfo: "__NAME__をごみ箱から復元",
	eventFileSharedInfo: "__NAME__を__EMAIL__と共有",
	eventFileLinkEditedInfo: "__NAME__の共有リンクの設定変更",
	eventFolderTrashInfo: "__NAME__をごみ箱に移動",
	eventFolderSharedInfo: "__NAME__を__EMAIL__と共有",
	eventFolderMovedInfo: "__NAME__の移動",
	eventFolderRenamedInfo: "__NAME__を__NEW__へ改名",
	eventFolderCreatedInfo: "__NAME__の作成",
	eventFolderRestoredInfo: "__NAME__をごみ箱から復元",
	eventFolderColorChangedInfo: "__NAME__の色変更",
	eventLoginInfo: "ログイン",
	eventDeleteVersionedInfo: "過去ファイルの削除",
	eventDeleteAllInfo: "すべてのファイルとフォルダーの削除",
	eventDeleteUnfinishedInfo: "未完了のアップロードの破棄",
	eventTrashEmptiedInfo: "ごみ箱内の全削除",
	eventRequestAccountDeletionInfo: "アカウント削除の申請",
	event2FAEnabledInfo: "二段階認証を有効化",
	event2FADisabledInfo: "二段階認証を無効化",
	eventCodeRedeemInfo: "コード__CODE__を使用",
	eventEmailChangedInfo: "メールアドレスを__EMAIL__へ変更",
	eventPasswordChangedInfo: "パスワードの変更",
	eventRemovedSharedInItemsInfo: "__COUNT__件の__EMAIL__からの共有の停止",
	eventRemovedSharedOutItemsInfo: "__COUNT__件の__EMAIL__への共有の停止",
	favoritingItems: "__COUNT__件のファイルをお気に入りしています",
	unfavoritingItems: "__COUNT__件のファイルのお気に入りを解除しています",
	couldNotChangeFavStatus: "__NAME__のお気に入り状態を変えることができませんでした（エラー内容：__ERR__）",
	pleaseChooseDiffDest: "他の移動先を選択してください",
	movingItems: "__COUNT__件のファイルを移動中",
	folderExistsAtDest: "__NAME__という名前のフォルダーが移動先にも存在しています",
	couldNotMoveItem: "__NAME__を移動できませんでした（エラー内容：__ERR__）",
	restoringItems: "__COUNT__件のファイルを復元中",
	couldNotRestoreItem: "__NAME__を復元できませんでした（エラー内容：__ERR__）",
	changeEmail: "メールアドレスを変更",
	passwordsDoNotMatch: "パスワードが一致しません",
	registerWeakPassword: "パスワードは10文字以上で設定してください",
	invalidEmailOrPassword: "メールアドレスたまはパスワードが違います",
	unknownErrorSupp: "エラーが発生しました。もう一度試すか、サポートにお問合せください。",
	registerEmailAlreadyRegistered: "このメールアドレスはすでに登録済みです",
	gotIt: "了解",
	registration: "登録",
	registrationEmailInstructions:
		"登録を完了させるには、メールアドレスの認証を行ってください。手順と認証リンクはメールで送信しております。",
	forgotPasswordEmailSent: "パスワード再設定用のメールを登録されているメールアドレスに送信しました。",
	maxStorageReached: "容量がいっぱいです",
	maxStorageReachedInfo:
		"容量が上限に達しました。他のファイルをアップロードするには、アップグレードをご検討ください。",
	upgradeNow: "アップグレードする",
	abuseReportSubmitted: "通報しました",
	invalidAbuseReason: "通報の種類を選択してください",
	uploadErrored: "アップロードできませんでした",
	newTextFileName: "テキストファイルの名前を入力",
	passwordResetSuccess: "パスワードを変更しました。ログイン済みの端末では再ログインが必要になります。",
	resetPasswordBtn: "パスワードを再設定",
	publicLinkDisabled: "共有を停止しました",
	expireAfter: "リンクの有効期限",
	exportMasterKeys: "マスターキーを保存",
	export: "保存",
	resetPasswordCheckbox:
		"マスターキーを保存せずにパスワードを再設定した場合、Filenの採用する暗号化方式の仕組みによりすべてのデータにアクセスすることが不可能になります。それに対し、我々は一切の責任を負いかねることに同意します。",
	invalidAuthVersion: "認証のバージョンが正しくありません",
	recoveryMasterKeysInput: "保存したマスターキー",
	exportMasterKeysInfo:
		"マスターキーを保存すると、パスワードをお忘れの場合にもデータにアクセスすることができるようになります。パスワードを変更するたびこのマスターキーも変わるので、都度保存してください。",
	import: "インポート",
	invite: "招待",
	yourReferralLink: "招待用リンク",
	requestPayout: "送金を申請",
	invalidPayoutAddress: "送金先アドレスが正しくありません",
	affiliatePayoutSuccess: "送金の申請を受け付けました。",
	requestPayoutInfo: "1〜7営業日のうちにクレジットをお客様のビットコインアドレスに送金いたします。",
	comissionEarned: "お客様のクレジット",
	receivedBonusStorage: "ボーナス容量の合計",
	referInfo: "招待をすると、最大__STORAGE__の容量が追加されます。",
	referInfo2:
		"一人あたりのご招待につき__STORAGE__の容量がお客様のアカウントに追加され、招待された側は__OTHER_STORAGE__の容量が最初から上乗せされます。招待リンクから登録されると、お客様のアカウントにはクレジットも追加されます。クレジットの上限は__THRESHOLD__€までです。",
	yourBtcAddress: "ビットコインアドレス",
	openUploads: "アップロードを開く",
	payNow: "お支払い",
	emptyTrashModalSure: "ごみ箱を空にしてよろしいですか？空にすると復元することはできません。",
	emptyTrashSuccess: "ごみ箱を空にしました",
	itemRenamed: "名前を変更しました",
	subCancelled: "キャンセル済み",
	support: "お問い合わせ",
	finishing: "終了処理中",
	searchNothingFound: '"__TERM__"に合致するアイテムはありませんでした。',
	queued: "待機中",
	searchInThisFolder: "このフォルダ内で検索..."
}

export default ja
