const ko: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "안내 보내기",
	goBack: "뒤로가기",
	invalidEmailAndPassword: "이메일 주소 및 비밀번호가 잘못되었습니다.",
	email: "이메일",
	password: "비밀번호",
	emailAddress: "이메일 주소",
	tfaCode: "2단계 인증 코드",
	login: "로그인",
	dontHaveAnAccountYet: "아직 계정이 없으신가요?",
	accountCreateOne: "계정 만들기",
	forgotYourPassword: "비밀번호를 잊으셨나요?",
	couldNotFindDefaultFolder: "기본 폴더를 찾을 수 없습니다.",
	unlockLink: "잠금 해제",
	download: "다운로드",
	cannotDownloadEmptyFolder: "빈 폴더를 다운로드 할 수 없습니다.",
	thisFolderIsEmpty: "이 폴더는 비어있습니다.",
	linkFolderEmptyInfo: "이 폴더에 파일이나 폴더가 추가되면 여기에 표시됩니다.",
	confirmPassword: "비밀번호 확인",
	createAccount: "계정 만들기",
	alreadyHaveAnAccount: "이미 계정을 갖고 계신가요?",
	loginEx: "로그인!",
	resendConfirmationEmail: "확인 이메일 다시 전송",
	reportAbuseModal_spam: "스팸",
	reportAbuseModal_dmca: "저작권 침해",
	reportAbuseModal_cp: "아동·청소년 성착취물",
	reportAbuseModal_stolen: "도난당한 데이터",
	reportAbuseModal_malware: "멀웨어",
	reportAbuseModal_other: "기타",
	abuseReport: "악용 신고",
	abuseReportModalInfo: "부적절하거나 불법적이거나 기타 유해한 공유 파일을 신고하려면 이 양식을 사용하세요.",
	yourEmailAddress: "사용자의 이메일 주소",
	abuseReportModalInfoPlaceholder: "적절한 조치를 취할 수 있도록 모든 세부 정보를 제공해 주세요. (선택 사항)",
	send: "보내기",
	abuseReportModalReason: "이유를 선택해 주세요.",
	avatarUploadMaxFileSize: "파일 크기는 3MB를 초과할 수 없습니다.",
	storageUsed: "사용된 공간",
	storageUsedInfo: "__MAX__ 중에 __USED__ 사용됨",
	storageUsedFilesAndFolders: "__USED__ 파일",
	storageUsedVersionedFiles: "__USED__ 버전 관리된 파일",
	storageUsedFree: "__FREE__ 남음",
	avatar: "아바타",
	edit: "편집",
	personalInformation: "개인 정보",
	darkMode: "다크 모드",
	language: "언어",
	change: "변경",
	requestAccountData: "계정 정보 요청",
	request: "요청",
	logout: "로그아웃",
	versionedFiles: "버전 관리된 파일",
	delete: "지우기",
	allFilesAndFolders: "모든 파일 및 폴더",
	deleteAccount: "계정 지우기",
	changePassword: "비밀번호 변경",
	tfa: "2단계 인증",
	subMoreInfo: "구독에 대한 자세한 정보",
	paymentMethod: "결제 방법",
	stripe: "스트라이프",
	paypal: "페이팔",
	crypto: "암호 화폐",
	update: "업데이트",
	cancel: "취소",
	noSubs: "현재 활성화된 구독이 없습니다.",
	noInvoices: "아직 명세서가 없습니다.",
	planFeatures_1: "무제한 대역폭",
	planFeatures_2: "클라이언트측 암호화",
	planFeatures_3: "영지식 기술",
	planTerms_monthly: "월간",
	planTerms_annually: "연간",
	planTerms_lifetime: "평생",
	planTerms_starter: "스타터",
	buyNow: "지금 구매하기",
	general: "일반",
	settings: "설정",
	security: "보안",
	plans: "이용권",
	subs: "구독",
	events: "사용 기록",
	invoices: "명세서",
	invalidEmail: "잘못된 이메일 주소",
	invalidPassword: "잘못된 비밀번호",
	emailsDoNotMatch: "이메일 주소가 일치하지 않습니다.",
	changeEmailPleaseConfirm: "전송된 링크를 클릭하여 새 이메일 주소를 확인하시기 바랍니다.",
	newEmail: "새 이메일 주소",
	confirmNewEmail: "새 이메일 주소 확인",
	save: "저장하기",
	firstName: "이름",
	lastName: "성",
	companyName: "회사명",
	vatId: "VAT ID",
	street: "주소 (Street)",
	streetNumber: "상세 주소 (Street number)",
	city: "도시명 (City)",
	postalCode: "우편 번호 (Postal code)",
	country: "국가",
	areYouSure: "확실한가요?",
	areYouSureDeleteAllVersioned: "버전 관리된 모든 파일을 삭제하시겠습니까? 이 작업은 취소할 수 없습니다!",
	areYouSureDeleteAll: "모든 파일 및 폴더를 삭제하시겠습니까? 이 작업은 취소할 수 없습니다!",
	invalidNewPassword: "유효하지 않은 새 비밀번호",
	invalidCurrentPassword: "유효하지 않은 기존 비밀번호",
	newPasswordsDontMatch: "새 비밀번호가 일치하지 않습니다.",
	invalidMasterKeys: "유효하지 않은 마스터 키",
	passwordChanged: "비밀번호 변경됨",
	newPassword: "새 비밀번호",
	confirmNewPassword: "새 비밀번호 확인",
	currentPassword: "현재 비밀번호",
	deleteAccountConfirm: "이메일로 전송된 링크를 클릭하여 계정 삭제를 확인하시기 바랍니다.",
	areYouSureDeleteAccount: "정말 계정을 삭제하시겠습니까? 확인 링크를 이메일 주소로 보내드리겠습니다.",
	invalid2FACode: "잘못된 2단계 인증 코드",
	enable2FA: "2단계 인증 켜기",
	copy: "복사",
	enter2FA: "2단계 인증 코드 입력",
	enable: "켜기",
	recoveryKeys: "복구 키",
	recoveryKeysInfo:
		"복구 키를 안전하게 보관하세요. 2단계 인증 기기를 분실했을 때 계정을 복구할 수 있는 유일한 방법입니다.",
	close: "닫기",
	disable2FA: "2단계 인증 끄기",
	disable: "끄기",
	sharedWithMe: "나와 공유",
	sharedWithOthers: "다른 사람과 공유",
	links: "링크",
	favorites: "즐겨찾기",
	recents: "최근",
	trash: "휴지통",
	cloudDrive: "클라우드 드라이브",
	emptyTrash: "휴지통 비우기",
	monthlyRecurring: "월간 지불",
	annuallyRecurring: "연간 지불",
	oneTimePayment: "일회성 지불",
	buyRecurringInfo:
		"이용권을 구매하면 취소할 때까지 Filen이 기간마다 자동으로 요금이 청구할 수 있도록 승인합니다. 계정 페이지에서 언제든지 취소할 수 있으며, 부분 환불은 되지 않습니다.",
	buyLifetimeInfo:
		"이용권을 구매하면 Filen이 결제 수단으로 요금을 청구할 수 있도록 승인합니다. 부분 환불은 되지 않습니다.",
	creditDebit: "신용 / 직불 카드",
	cancelSub: "구독 취소",
	cancelSubSure: "이 구독을 취소하시겠습니까?",
	cancellingSub: "구독 취소 중",
	createFolder: "폴더 만들기",
	createTextFile: "텍스트 파일 만들기",
	uploadFiles: "파일 업로드",
	uploadFolders: "폴더 업로드",
	preview: "미리 보기",
	preparingDownload: "다운로드 준비 중",
	normalDownload: "일반 다운로드",
	zipDownload: "ZIP으로 다운로드",
	publicLink: "공개 링크",
	share: "공유",
	versions: "버전",
	color: "색상",
	color_default: "기본",
	color_blue: "파랑색",
	color_green: "초록색",
	color_purple: "보라색",
	color_red: "빨강색",
	color_gray: "회색",
	unfavorite: "즐겨찾기 해제",
	favorite: "즐겨찾기",
	rename: "이름 바꾸기",
	move: "이동하기",
	selectDestination: "목적지 선택",
	restore: "복원하기",
	deletePerm: "영구 삭제",
	remove: "제거",
	stopSharing: "공유 중지",
	cookieConsent: "이 사이트는 쿠키를 사용하여 사용자 경험을 측정하고 개선합니다.",
	accept: "수락",
	optOut: "옵트아웃(거부하여 쿠키 사용 중단)",
	onlyNeeded: "필요한 쿠키만",
	pleaseChooseDiffName: "다른 이름을 선택해 주세요.",
	newFolderName: "이름",
	itemsMovedToTrash: "__COUNT__개 항목이 휴지통으로 이동되었습니다.",
	couldNotMoveToTrash: " __NAME__를 휴지통으로 이동하지 못했습니다.: __ERR__",
	deleteModalSure: "__COUNT__개 항목을 지우시겠습니까?",
	deletePermModalSure: "__COUNT__개 항목을 영구 삭제하시겠습니까? 이 작업은 취소할 수 없습니다!",
	itemsDeletedPerm: "__COUNT__개 항목이 영구 삭제되었습니다.",
	couldNotDeletePerm: "__NAME__를 영구 삭제하지 못했습니다.: __ERR__",
	uploadHere: "여기에 업로드",
	event: "사용 기록",
	date: "날짜",
	ipAddress: "IP 주소",
	na: "N/A",
	listEmpty_1: "아직 사용자님과 공유된 파일이나 폴더가 없습니다.",
	listEmpty_2: "누군가가 파일이나 폴더를 당신과 공유하면 여기에 표시됩니다.",
	listEmpty_3: "아직 다른 사람과 공유된 파일 또는 폴더가 없습니다.",
	listEmpty_4: "사용자님이 파일이나 폴더를 누군가와 공유하면 여기에 표시됩니다.",
	listEmpty_5: "아직 공개 링크가 없습니다.",
	listEmpty_6: "사용자님이 파일이나 폴더를 누군가와 공유하면 여기에 표시됩니다.",
	listEmpty_7: "아직 즐겨찾기가 없습니다.",
	listEmpty_8: "사용자님이 파일이나 폴더를 즐겨찾기로 표시하면 여기에 표시됩니다.",
	listEmpty_9: "아직 최근 파일이 없습니다.",
	listEmpty_10: "최근에 업로드한 파일이 여기에 표시됩니다.",
	listEmpty_11: "휴지통이 비어있습니다.",
	listEmpty_12: "사용자님이 파일이나 폴더를 삭제하면 이곳에 표시됩니다.",
	listEmpty_13: "아직 업로드된 파일이나 폴더가 없습니다.",
	listEmpty_14: "파일을 이곳에 끌어놓거나 아래 버튼을 누르세요.",
	listFooterSelected: "__TOTAL__개 항목 중 __COUNT__개 선택됨",
	name: "이름",
	size: "크기",
	lastModified: "마지막 수정",
	moveModalBtn: "__COUNT__개 항목을 __DEST__로 옮겼습니다.",
	file: "파일",
	ctrlPlusS: "CTRL + S",
	exit: "닫기",
	fileHasBeenChanged: "파일이 변경됨",
	textEditorExitSure: "정말로 저장하지 않고 나가시겠습니까?",
	saveChanges: "변경사항 저장",
	expire_never: "안 함",
	expire_1h: "1시간",
	expire_6h: "6시간",
	expire_1d: "1일",
	expire_3d: "3일",
	expire_7d: "7일",
	expire_14d: "14일",
	expire_30d: "30일",
	addingItemsToPublicLink: "항목을 공개 링크에 추가",
	addingItemsToPublicLinkProgress: "__LEFT__ 항목을 공개 링크에 추가 중",
	enabled: "켜기",
	disabled: "끄기",
	copied: "복사됨",
	publicLinkDownloadBtn: "다운로드 버튼 활성화됨",
	publicLinkPassword: "비밀번호 (비활성화하려면 비워두기)",
	removedSharedItems: "공유된 __COUNT__개 항목이 지워짐",
	couldNotRemoveSharedItems: "__NAME__를 지울 수 없습니다.: __ERR__",
	removeSharedItemsModalInfo:
		"정말로 __COUNT__개 항목을 지우시겠습니까? 더이상 이 파일 및 폴더에게 접근할 수 없습니다.",
	renameNewName: "새 이름",
	selectFromComputer: "컴퓨터에서 선택",
	upload: "업로드",
	files: "파일",
	folders: "폴더",
	newFolder: "새 폴더",
	sharedWith: "다음과 공유됨: ",
	sharingItems: "항목 공유",
	sharingItemsProgress: "__LEFT__개 항목 공유 중",
	itemsSharedWith: "__EMAIL__과 공유한 __COUNT__개 항목",
	shareReceiver: "받는 사람의 Filen 계정 이메일",
	upgrade: "업그레이드",
	stoppedSharingItems: "__COUNT__개 항목이 공유 중지됨",
	couldNotStopSharingItem: "__NAME__를 공유 중지하지 못했습니다.: __ERR__",
	stopSharingModalSure: "정말로 __COUNT__개 항목을 공유 중지 하시겠습니까? 상대방이 더 이상 액세스할 수 없게 됩니다.",
	aboutRemaining: "약 __TIME__ 남음",
	transferringItems: "__COUNT__개 항목 전송 중",
	uploadingItems: "__COUNT__개 항목 업로드 중",
	downloadingItems: " __COUNT__개 항목 다운로드 중",
	new: "새 항목",
	resume: "계속하기",
	pause: "일시 정지",
	stop: "중지",
	done: "완료",
	noUploadsQueued: "대기 중인 업로드가 없습니다.",
	creatingFolders: "폴더 만들기",
	creatingFoldersProgress: "__LEFT__개 폴더 만드는 중",
	current: "현재",
	create: "만들기",
	changingColor: "__COUNT__개 항목의 색상 변경",
	couldNotChangeColor: "__NAME__의 색상을 변경할 수 없습니다.: __ERR__",
	eventFileUploaded: "파일 업로드됨",
	eventFileVersioned: "파일 버전 관리됨",
	eventVersionedFileRestored: "버전 관리된 파일 복원됨",
	eventFileMoved: "파일 이동됨",
	eventFileTrash: "파일이 휴지통으로 이동됨",
	eventFileRm: "파일 삭제됨",
	eventFileRestored: "파일 복원됨",
	eventFileShared: "파일 공유됨",
	eventFileLinkEdited: "파일의 공개 링크가 변경됨",
	eventFolderTrash: "폴더가 휴지통으로 이동됨",
	eventFolderShared: "폴더 공유됨",
	eventFolderMoved: "폴더 이동됨",
	eventFolderRenamed: "폴더 이름 변경됨",
	eventFolderCreated: "폴더 생성됨",
	eventFolderRestored: "폴더 복원됨",
	eventFolderColorChanged: "폴더 색상 변경됨",
	eventLogin: "로그인",
	eventDeleteVersioned: "버전 관리된 파일 및 폴더 삭제됨",
	eventDeleteAll: "모든 파일 및 폴더 삭제됨",
	eventDeleteUnfinished: "완료되지 않은 업로드 제거됨",
	eventTrashEmptied: "휴지통 비워짐",
	eventRequestAccountDeletion: "계정 삭제 요청됨",
	event2FAEnabled: "2단계 인증이 켜짐",
	event2FADisabled: "2단계 인증이 꺼짐",
	eventCodeRedeem: "코드 사용됨",
	eventEmailChanged: "이메일 변경됨",
	eventPasswordChanged: "비밀번호 변경됨",
	eventRemovedSharedInItems: "받은 공유 제거됨",
	eventRemovedSharedOutItems: "보낸 공유 제거됨",
	eventFileUploadedInfo: "__NAME__가 업로드됨",
	eventFileVersionedInfo: "__NAME__가 버전 관리됨",
	eventVersionedFileRestoredInfo: "__NAME__의 파일 버전이 복원됨",
	eventFileRenamedInfo: "__NAME__가 __NEW__로 이름 변경됨",
	eventFileMovedInfo: "__NAME__가 이동됨",
	fileRenamedInfo: "__NAME__가 __NEW__로 이름 변경됨",
	eventFileTrashInfo: "__NAME__가 휴지통으로 이동됨",
	eventFileRmInfo: "__NAME__가 삭제됨",
	eventFileRestoredInfo: "__NAME__가 휴지통에서 복원됨",
	eventFileSharedInfo: "__NAME__가 __EMAIL__과 공유됨",
	eventFileLinkEditedInfo: "__NAME__의 공개 링크가 변경됨",
	eventFolderTrashInfo: "__NAME__가 휴지통으로 이동됨",
	eventFolderSharedInfo: "__NAME__가 __EMAIL__과 공유됨",
	eventFolderMovedInfo: "__NAME__가 이동됨",
	eventFolderRenamedInfo: "__NAME__가 __NEW__로 이름 변경됨",
	eventFolderCreatedInfo: "__NAME__가 만들어짐",
	eventFolderRestoredInfo: "__NAME__가 휴지통에서 복원됨",
	eventFolderColorChangedInfo: "__NAME__의 색상이 변경됨",
	eventLoginInfo: "로그인",
	eventDeleteVersionedInfo: "버전 관리된 파일 및 폴더 삭제됨",
	eventDeleteAllInfo: "모든 파일 및 폴더 삭제됨",
	eventDeleteUnfinishedInfo: "완료되지 않은 업로드 제거됨",
	eventTrashEmptiedInfo: "휴지통 비워짐",
	eventRequestAccountDeletionInfo: "계정 삭제 요청됨",
	event2FAEnabledInfo: "2단계 인증 켜짐",
	event2FADisabledInfo: "2단계 인증 꺼짐",
	eventCodeRedeemInfo: "코드 __CODE__ 사용됨",
	eventEmailChangedInfo: "이메일 주소가 __EMAIL__로 변경됨",
	eventPasswordChangedInfo: "비밀번호 변경됨",
	eventRemovedSharedInItemsInfo: "__EMAIL__로부터 받은 __COUNT__개 공유 제거됨",
	eventRemovedSharedOutItemsInfo: "__EMAIL__로 보낸 __COUNT__개 공유 제거됨",
	favoritingItems: "__COUNT__개 항목을 즐겨찾기함",
	unfavoritingItems: "__COUNT__개 항목을 즐겨찾기 해제함",
	couldNotChangeFavStatus: "__NAME__의 즐겨찾기 상태를 변경할 수 없습니다.: __ERR__",
	pleaseChooseDiffDest: "다른 경로를 선택해주세요.",
	movingItems: "__COUNT__개 항목을 옮기는 중",
	folderExistsAtDest: "목적지에 __NAME__ 이름을 가진 폴더가 이미 존재합니다.",
	couldNotMoveItem: "__NAME__를 옮길 수 없습니다.: __ERR__",
	restoringItems: "__COUNT__개 항목을 복원하는 중",
	couldNotRestoreItem: "__NAME__ 복원: __ERR__",
	changeEmail: "이메일 변경",
	passwordsDoNotMatch: "비밀번호가 일치하지 않습니다.",
	registerWeakPassword: "비밀번호는 10자 이상이어야 합니다.",
	invalidEmailOrPassword: "잘못된 이메일 혹은 비밀번호",
	unknownErrorSupp: "알 수 없는 오류가 발생했습니다. 다시 시도하거나 지원팀에 문의하세요.",
	registerEmailAlreadyRegistered: "이 이메일 주소는 이미 등록되어 있습니다.",
	gotIt: "알겠습니다",
	registration: "등록",
	registrationEmailInstructions:
		"등록을 완료하려면 이메일 주소를 확인하시기 바랍니다. 안내가 포함된 링크가 전송되었습니다.",
	forgotPasswordEmailSent: "비밀번호 재설정 방법에 대한 안내가 포함된 이메일이 발송되었습니다.",
	maxStorageReached: "저장 공간이 가득 찼습니다.",
	maxStorageReachedInfo: "최대 저장 용량에 도달했습니다. 더 많은 파일을 업로드하려면 계정을 업그레이드하세요.",
	upgradeNow: "지금 업그레이드",
	abuseReportSubmitted: "악용 신고가 접수되었습니다!",
	invalidAbuseReason: "유효하지 않은 이유",
	uploadErrored: "실패",
	newTextFileName: "새로운 텍스트 파일 이름",
	passwordResetSuccess: "비밀번호가 변경되었습니다. 모든 장치에서 다시 로그인해주세요.",
	resetPasswordBtn: "비밀번호 재설정",
	publicLinkDisabled: "공개 링크가 비활성화됨",
	expireAfter: "만료까지",
	exportMasterKeys: "마스터 키 내보내기",
	export: "내보내기",
	resetPasswordCheckbox:
		"저는 내보낸 마스터 키 없이 비밀번호를 재설정하면 영지식 종단 간 암호화 작동 방식으로 인해 계정에 저장된 모든 데이터에 액세스할 수 없게 된다는 것을 이해합니다.",
	invalidAuthVersion: "잘못된 인증 버전",
	recoveryMasterKeysInput: "내보낸 마스터 키",
	exportMasterKeysInfo:
		"마스터 키를 내보내면 비밀번호를 잊어버렸을 때 데이터 손실 없이 계정을 복구할 수 있습니다. 비밀번호를 변경할 때마다 새 마스터 키를 내보내야 합니다.",
	import: "가져오기",
	invite: "초대하기",
	yourReferralLink: "사용자님의 링크",
	requestPayout: "지급 요청",
	invalidPayoutAddress: "지급 주소가 잘못되었습니다.",
	affiliatePayoutSuccess: "지급 요청이 제출되었습니다.",
	requestPayoutInfo: "적립된 제휴 수수료는 영업일 기준 1~7일 이내에 지정된 비트코인 주소로 송금됩니다.",
	comissionEarned: "수수료 적립됨",
	receivedBonusStorage: "받은 총 추가 저장 공간",
	referInfo: "다른 사람을 초대하여 최대 __STORAGE__의 저장 공간을 받으세요.",
	referInfo2:
		"친구를 Filen에 초대할 때마다 사용자님께는 __STORAGE__가 제공되며, 친구도 __OTHER_STORAGE__를 받게 됩니다. 친구는 원하는 요금제에 등록할 수 있으며, 사용자님은 수수료를 받을 수 있습니다. 수수료 지급 한도는 __THRESHOLD__€입니다. 귀하의 수수료율은 __RATE__%입니다.",
	yourBtcAddress: "사용자님의 비트코인 주소",
	openUploads: "업로드 열기",
	payNow: "지금 명세서 결제하기",
	emptyTrashModalSure: "정말 휴지통을 비우시겠습니까? 이 작업은 취소할 수 없습니다!",
	emptyTrashSuccess: "휴지통 비워짐",
	itemRenamed: "항목 이름 변경됨",
	subCancelled: "취소됨",
	support: "지원",
	finishing: "완료"
}

export default ko
