const cs: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "Odeslat instrukce",
	goBack: "Jít zpět",
	invalidEmailAndPassword: "Neplatná emailová adresa a heslo",
	email: "Email",
	password: "Heslo",
	emailAddress: "Emailová adresa",
	tfaCode: "Kód dvoufaktorové autentizace",
	login: "Přihlásit se",
	dontHaveAnAccountYet: "Ještě nemáte účet?",
	accountCreateOne: "Vytvořte si ho!",
	forgotYourPassword: "Zapomněli jste heslo?",
	couldNotFindDefaultFolder: "Nelze najít výchozí složku",
	unlockLink: "Odemknout",
	download: "Stáhnout",
	cannotDownloadEmptyFolder: "Nelze stáhnout prázdnou složku",
	thisFolderIsEmpty: "Tato složka je prázdná",
	linkFolderEmptyInfo: "Jakmile budou do této složky přidány soubory nebo složky, zobrazí se zde",
	confirmPassword: "Potvrďte heslo",
	createAccount: "Vytvořit účet",
	alreadyHaveAnAccount: "Máte již účet?",
	loginEx: "Přihlaste se!",
	resendConfirmationEmail: "Znovu odeslat potvrzovací email",
	reportAbuseModal_spam: "Spam",
	reportAbuseModal_dmca: "Porušení autorských práv",
	reportAbuseModal_cp: "Materiál zneužívání dětí",
	reportAbuseModal_stolen: "Odcizená data",
	reportAbuseModal_malware: "Malware",
	reportAbuseModal_other: "Jiné",
	abuseReport: "Nahlásit zneužití",
	abuseReportModalInfo: "Použijte tento formulář k nahlášení nevhodných, nelegálních nebo jinak škodlivých sdílených souborů.",
	yourEmailAddress: "Vaše emailová adresa",
	abuseReportModalInfoPlaceholder: "Poskytněte nám všechny podrobnosti, abychom mohli přijmout vhodná opatření (volitelné)",
	send: "Odeslat",
	abuseReportModalReason: "Vyberte, prosím, důvod",
	avatarUploadMaxFileSize: "Velikost souboru nesmí překročit 3 MB",
	storageUsed: "Využití úložiště",
	storageUsedInfo: "__USED__ z __MAX__ použito",
	storageUsedFilesAndFolders: "Soubory __USED__",
	storageUsedVersionedFiles: "Verzované soubory __USED__",
	storageUsedFree: "Volné __FREE__",
	avatar: "Avatar",
	edit: "Upravit",
	personalInformation: "Osobní informace",
	darkMode: "Tmavý režim",
	language: "Jazyk",
	change: "Změnit",
	requestAccountData: "Vyžádání dat účtu",
	request: "Vyžádat",
	logout: "Odhlásit se",
	versionedFiles: "Verzované soubory",
	delete: "Smazat",
	allFilesAndFolders: "Všechny soubory a složky",
	deleteAccount: "Smazat účet",
	changePassword: "Změnit heslo",
	tfa: "Dvoufaktorová autentizace",
	subMoreInfo: "Více informací o vašem předplatném",
	paymentMethod: "Způsob platby",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "Crypto",
	update: "Aktualizovat",
	cancel: "Zrušit",
	noSubs: "Nemáte žádné aktivní předplatné.",
	noInvoices: "Zatím nemáte žádné faktury.",
	planFeatures_1: "Neomezená šířka pásma",
	planFeatures_2: "Šifrování na straně klienta",
	planFeatures_3: "Technologie nulových znalostí",
	planTerms_monthly: "Měsíční",
	planTerms_annually: "Roční",
	planTerms_lifetime: "Doživotní",
	planTerms_starter: "Starter",
	buyNow: "Koupit",
	general: "Obecné",
	settings: "Nastavení",
	security: "Zabezpečení",
	plans: "Plány",
	subs: "Předplatné",
	events: "Události",
	invoices: "Faktury",
	invalidEmail: "Neplatný email",
	invalidPassword: "Neplatné heslo",
	emailsDoNotMatch: "Emaily se neshodují",
	changeEmailPleaseConfirm: "Potvrďte, prosím, svou novou emailovou adresu kliknutím na odkaz zaslaný na ni",
	newEmail: "Nový email",
	confirmNewEmail: "Potvrďte nový email",
	save: "Uložit",
	firstName: "Jméno",
	lastName: "Příjmení",
	companyName: "Společnost",
	vatId: "DIČ",
	street: "Ulice",
	streetNumber: "Číslo popisné",
	city: "Město",
	postalCode: "PSČ",
	country: "Země",
	areYouSure: "Jste si jistí?",
	areYouSureDeleteAllVersioned: "Jste si jistí, že chcete smazat všechny verzované soubory? Tuto akci nelze vrátit zpět!",
	areYouSureDeleteAll: "Jste si jistí, že chcete smazat všechny soubory a složky? Tuto akci nelze vrátit zpět!",
	invalidNewPassword: "Neplatné nové heslo",
	invalidCurrentPassword: "Neplatné aktuální heslo",
	newPasswordsDontMatch: "Nová hesla se neshodují",
	invalidMasterKeys: "Neplatné hlavní klíče",
	passwordChanged: "Heslo změněno",
	newPassword: "Nové heslo",
	confirmNewPassword: "Nové heslo znovu",
	currentPassword: "Aktuální heslo",
	deleteAccountConfirm: "Potvrďte, prosím, smazání účtu kliknutím na odkaz zaslaný na Váš email",
	areYouSureDeleteAccount: "Jste si jistí, že chcete smazat svůj účet? Pošleme vám potvrzovací odkaz na vaši emailovou adresu.",
	invalid2FACode: "Neplatný kód dvoufaktorové autentizace",
	enable2FA: "Povolit dvoufaktorovou autentizaci",
	copy: "Kopírovat",
	enter2FA: "Zadejte kód dvoufaktorové autentizace",
	enable: "Povolit",
	recoveryKeys: "Klíč pro obnovu",
	recoveryKeysInfo: "Uložte si svůj klíč pro obnovu. Je to jediný způsob, jak obnovit váš účet v případě ztráty zařízení 2FA.",
	close: "Zavřít",
	disable2FA: "Zakázat dvoufaktorovou autentizaci",
	disable: "Zakázat",
	sharedWithMe: "Sdílené se mnou",
	sharedWithOthers: "Sdílené s ostatními",
	links: "Odkazy",
	favorites: "Oblíbené",
	recents: "Nedávné",
	trash: "Koš",
	cloudDrive: "Cloudová jednotka",
	emptyTrash: "Vyprázdnit koš",
	monthlyRecurring: "Měsíčně se opakující",
	annuallyRecurring: "Ročně se opakující",
	oneTimePayment: "Jednorázová platba",
	buyRecurringInfo:
		"Zakoupením plánu povolujete Filen automaticky vás každé fakturační období účtovat, dokud předplatné nezrušíte. Můžete kdykoli zrušit na své stránce Účet. Neposkytujeme částečné vrácení peněz.",
	buyLifetimeInfo: "Zakoupením plánu opravňujete Filen ke stržení částky z Vaší platební metody. Žádné částečné vrácení peněz.",
	creditDebit: "Kreditní / debetní karty",
	cancelSub: "Zrušit předplatné",
	cancelSubSure: "Jste si jistí, že chcete zrušit toto předplatné?",
	cancellingSub: "Rušení předplatného",
	createFolder: "Vytvořit složku",
	createTextFile: "Vytvořit soubor",
	uploadFiles: "Nahrát soubory",
	uploadFolders: "Nahrát složky",
	preview: "Náhled",
	preparingDownload: "Příprava ke stažení",
	normalDownload: "Stáhnout normálně",
	zipDownload: "Stáhnout jako ZIP",
	publicLink: "Veřejný odkaz",
	share: "Sdílet",
	versions: "Verze",
	color: "Barva",
	color_default: "Výchozí",
	color_blue: "Modrá",
	color_green: "Zelená",
	color_purple: "Fialová",
	color_red: "Červená",
	color_gray: "Šedá",
	unfavorite: "Odebrat z oblíbených",
	favorite: "Oblíbené",
	rename: "Přejmenovat",
	move: "Přesunout",
	selectDestination: "Vybrat destinaci",
	restore: "Obnovit",
	deletePerm: "Smazat trvale",
	remove: "Odebrat",
	stopSharing: "Zastavit sdílení",
	cookieConsent: "Tento web používá soubory cookie k měření a zlepšení Vašeho zážitku.",
	accept: "Přijmout",
	optOut: "Zakázat",
	onlyNeeded: "Pouze potřebné",
	pleaseChooseDiffName: "Zvolte, prosím, jiný název",
	newFolderName: "Název",
	itemsMovedToTrash: "__COUNT__ položek přesunuto do koše",
	couldNotMoveToTrash: "Nepodařilo se přesunout __NAME__ do koše",
	deleteModalSure: "Jste si jistí, že chcete smazat __COUNT__ položek?",
	deletePermModalSure: "Jste si jistí, že chcete trvale smazat __COUNT__ položek? Tuto akci nelze vrátit zpět!",
	itemsDeletedPerm: "__COUNT__ položek trvale smazáno",
	couldNotDeletePerm: "Nelze trvale smazat __NAME__: __ERR__",
	uploadHere: "Nahrát zde",
	event: "Událost",
	date: "Datum",
	ipAddress: "IP adresa",
	na: "Nedostupné",
	listEmpty_1: "Zatím nemáte žádné soubory nebo složky sdílené s Vámi",
	listEmpty_2: "Jakmile někdo s Vámi sdílí soubory nebo složky, objeví se zde",
	listEmpty_3: "Zatím nemáte žádné soubory nebo složky sdílené s ostatními",
	listEmpty_4: "Jakmile někomu sdílíte soubory nebo složky, objeví se zde",
	listEmpty_5: "Zatím nemáte žádné veřejné odkazy",
	listEmpty_6: "Jakmile vytvoříte veřejné odkazy pro soubory nebo složky, objeví se zde",
	listEmpty_7: "Zatím nemáte žádné oblíbené",
	listEmpty_8: "Jakmile označíte soubory nebo složky jako oblíbené, objeví se zde",
	listEmpty_9: "Zatím nemáte žádné nedávné",
	listEmpty_10: "Nedávno nahrávané soubory se objeví zde",
	listEmpty_11: "Zatím nemáte žádné smazané položky",
	listEmpty_12: "Jakmile označíte soubory nebo složky k smazání, objeví se zde",
	listEmpty_13: "Zatím nemáte žádné nahrané soubory nebo složky",
	listEmpty_14: "Přetáhněte soubory nebo složky sem nebo stiskněte tlačítko níže",
	listFooterSelected: "__COUNT__ vybráno z __TOTAL__",
	name: "Název",
	size: "Velikost",
	lastModified: "Poslední změna",
	moveModalBtn: "Přesunout __COUNT__ položek do __DEST__",
	file: "Soubor",
	ctrlPlusS: "CTRL + S",
	exit: "Ukončit",
	fileHasBeenChanged: "Soubor byl změněn",
	textEditorExitSure: "Opravdu chcete ukončit bez uložení?",
	saveChanges: "Uložit změny",
	expire_never: "Nikdy",
	expire_1h: "1 hodina",
	expire_6h: "6 hodin",
	expire_1d: "1 den",
	expire_3d: "3 dny",
	expire_7d: "7 dní",
	expire_14d: "14 dní",
	expire_30d: "30 dní",
	addingItemsToPublicLink: "Přidávání položek do veřejného odkazu",
	addingItemsToPublicLinkProgress: "Přidávání __LEFT__ položek do veřejného odkazu",
	enabled: "Povoleno",
	disabled: "Zakázáno",
	copied: "Zkopírováno",
	publicLinkDownloadBtn: "Tlačítko stáhnout povoleno",
	publicLinkPassword: "Heslo (nechte prázdné pro zakázání)",
	removedSharedItems: "Odebráno __COUNT__ sdílených položek",
	couldNotRemoveSharedItems: "Nelze odebrat __NAME__: __ERR__",
	removeSharedItemsModalInfo: "Jste si jistí, že chcete odebrat __COUNT__ položek? Už k nim nebudete mít přístup.",
	renameNewName: "Nový název",
	selectFromComputer: "Vybrat z počítače",
	upload: "Nahrát",
	files: "Soubory",
	folders: "Složky",
	newFolder: "Nová složka",
	sharedWith: "Sdíleno s",
	sharingItems: "Sdílení položek",
	sharingItemsProgress: "Sdílení __LEFT__ položek",
	itemsSharedWith: "__COUNT__ položek sdíleno s __EMAIL__",
	shareReceiver: "Emailová adresa příjemce",
	upgrade: "Navýšit limit",
	stoppedSharingItems: "Sdílení __COUNT__ položek zastaveno",
	couldNotStopSharingItem: "Nelze zastavit sdílení __NAME__: __ERR__",
	stopSharingModalSure: "Opravdu chcete zastavit sdílení __COUNT__ položek? Ostatní už k nim nebudou mít přístup.",
	aboutRemaining: "__TIME__ zbývá",
	transferringItems: "Přenos __COUNT__ položek",
	uploadingItems: "Nahrávání __COUNT__ položek",
	downloadingItems: "Stahování __COUNT__ položek",
	new: "Nový",
	resume: "Pokračovat",
	pause: "Pozastavit",
	stop: "Zastavit",
	done: "Hotovo",
	noUploadsQueued: "Žádné nahrávání ve frontě",
	creatingFolders: "Vytváření složek",
	creatingFoldersProgress: "Vytváření __LEFT__ složek",
	current: "Aktuální",
	create: "Vytvořit",
	changingColor: "Změna barvy pro __COUNT__ položek",
	couldNotChangeColor: "Nelze změnit barvu pro __NAME__: __ERR__",
	eventFileUploaded: "Soubor nahrán",
	eventFileVersioned: "Soubor verzován",
	eventVersionedFileRestored: "Verze souboru obnovena",
	eventFileMoved: "Soubor přesunut",
	eventFileTrash: "Soubor přesunut do koše",
	eventFileRm: "Soubor odebrán",
	eventFileRestored: "Soubor obnoven",
	eventFileShared: "Soubor sdílen",
	eventFileLinkEdited: "Veřejný odkaz na soubor upraven",
	eventFolderTrash: "Složka přesunuta do koše",
	eventFolderShared: "Složka sdílena",
	eventFolderMoved: "Složka přesunuta",
	eventFolderRenamed: "Složka přejmenována",
	eventFolderCreated: "Složka vytvořena",
	eventFolderRestored: "Složka obnovena",
	eventFolderColorChanged: "Barva složky změněna",
	eventLogin: "Přihlášení",
	eventDeleteVersioned: "Verzované soubory a složky smazány",
	eventDeleteAll: "Všechny soubory a složky smazány",
	eventDeleteUnfinished: "Nedokončené nahrávání smazáno",
	eventTrashEmptied: "Koš vyprázdněn",
	eventRequestAccountDeletion: "Žádost o smazání účtu",
	event2FAEnabled: "2FA povoleno",
	event2FADisabled: "2FA zakázáno",
	eventCodeRedeem: "Kód použit",
	eventEmailChanged: "Email změněn",
	eventPasswordChanged: "Heslo změněno",
	eventRemovedSharedInItems: "Příchozí sdílení odebráno",
	eventRemovedSharedOutItems: "Odchozí sdílení odebráno",
	eventFileUploadedInfo: "Soubor __NAME__ nahrán",
	eventFileVersionedInfo: "Soubor __NAME__ verzován",
	eventVersionedFileRestoredInfo: "Verze souboru __NAME__ obnovena",
	eventFileRenamedInfo: "Soubor __NAME__ přejmenován na __NEW__",
	eventFileMovedInfo: "Soubor __NAME__ přesunut",
	fileRenamedInfo: "Soubor __NAME__ přejmenován na __NEW__",
	eventFileTrashInfo: "Soubor __NAME__ přesunut do koše",
	eventFileRmInfo: "Soubor __NAME__ odebrán",
	eventFileRestoredInfo: "Soubor __NAME__ obnoven",
	eventFileSharedInfo: "Soubor __NAME__ sdílen s __EMAIL__",
	eventFileLinkEditedInfo: "Veřejný odkaz na __NAME__ upraven",
	eventFolderTrashInfo: "Složka __NAME__ přesunuta do koše",
	eventFolderSharedInfo: "Složka __NAME__ sdílena s __EMAIL__",
	eventFolderMovedInfo: "Složka __NAME__ přesunuta",
	eventFolderRenamedInfo: "Složka __NAME__ přejmenována na __NEW__",
	eventFolderCreatedInfo: "Složka __NAME__ vytvořena",
	eventFolderRestoredInfo: "Složka __NAME__ obnovena",
	eventFolderColorChangedInfo: "Barva složky __NAME__ změněna",
	eventLoginInfo: "Přihlášení",
	eventDeleteVersionedInfo: "Verzované soubory a složky smazány",
	eventDeleteAllInfo: "Všechny soubory a složky smazány",
	eventDeleteUnfinishedInfo: "Nedokončené nahrávání smazáno",
	eventTrashEmptiedInfo: "Koš vyprázdněn",
	eventRequestAccountDeletionInfo: "Zažádáno o smazání účtu",
	event2FAEnabledInfo: "2FA povoleno",
	event2FADisabledInfo: "2FA zakázáno",
	eventCodeRedeemInfo: "Kód __CODE__ použit",
	eventEmailChangedInfo: "Email změněn na __EMAIL__",
	eventPasswordChangedInfo: "Heslo změněno",
	eventRemovedSharedInItemsInfo: "__COUNT__ příchozích sdílení od __EMAIL__ odebráno",
	eventRemovedSharedOutItemsInfo: "__COUNT__ odchozích sdílení pro __EMAIL__ odebráno",
	favoritingItems: "Přidávání __COUNT__ položek do oblíbených",
	unfavoritingItems: "Odebírání __COUNT__ položek z oblíbených",
	couldNotChangeFavStatus: "Nelze změnit stav oblíbených pro __NAME__: __ERR__",
	pleaseChooseDiffDest: "Vyberte, prosím, jiné cílové umístění",
	movingItems: "Přesouvání __COUNT__ položek",
	folderExistsAtDest: "Složka __NAME__ již existuje v cílovém umístění",
	couldNotMoveItem: "Nelze přesunout __NAME__: __ERR__",
	restoringItems: "Obnovování __COUNT__ položek",
	couldNotRestoreItem: "Nelze obnovit __NAME__: __ERR__",
	changeEmail: "Změnit email",
	passwordsDoNotMatch: "Hesla se neshodují",
	registerWeakPassword: "Vaše heslo musí mít alespoň 10 znaků",
	invalidEmailOrPassword: "Neplatný email nebo heslo",
	unknownErrorSupp: "Nastala neznámá chyba. Prosím zkuste to znovu nebo kontaktujte podporu.",
	registerEmailAlreadyRegistered: "Tento email je již registrován",
	gotIt: "Rozumím",
	registration: "Registrace",
	registrationEmailInstructions:
		"Pro dokončení registrace potvrďte svou emailovou adresu. Odkaz s instrukcemi byl odeslán na Váš email.",
	forgotPasswordEmailSent: "Email s instrukcemi pro obnovení hesla byl odeslán na Váš email.",
	maxStorageReached: "Úložiště plné",
	maxStorageReachedInfo: "Dosáhli jste maximálního povoleného úložiště. Prosím upgradujte svůj účet pro nahrání více souborů.",
	upgradeNow: "Upgradovat nyní",
	abuseReportSubmitted: "Nahlášení odesláno!",
	invalidAbuseReason: "Neplatný důvod",
	uploadErrored: "Nahrávání selhalo",
	newTextFileName: "Nový název textového souboru",
	passwordResetSuccess: "Heslo změněno. Prosím přihlašte se znovu ze všech zařízení.",
	resetPasswordBtn: "Resetovat heslo",
	publicLinkDisabled: "Veřejný odkaz zakázán",
	expireAfter: "Vyprší po",
	exportMasterKeys: "Export hlavních klíčů",
	export: "Exportovat",
	resetPasswordCheckbox:
		"Rozumím, že pokud resetuji své heslo bez exportovaných hlavních klíčů, způsobím, že všechna data uložená na mém účtu budou nečitelná kvůli tomu, jak funguje šifrování end-to-end.",
	invalidAuthVersion: "Neplatná verze autentizace",
	recoveryMasterKeysInput: "Hlavní klíče exportovány",
	exportMasterKeysInfo:
		"Exportování vašich hlavních klíčů umožňuje obnovit váš účet bez ztráty dat v případě, že zapomenete heslo. Ujistěte se, že exportujete své nové hlavní klíče pokaždé, když změníte heslo.",
	import: "Importovat",
	invite: "Pozvat",
	yourReferralLink: "Váš odkaz",
	requestPayout: "Požádat o výplatu",
	invalidPayoutAddress: "Neplatná adresa výplaty",
	affiliatePayoutSuccess: "Požadavek na výplatu odeslán",
	requestPayoutInfo: "Vyplatíme vám vaši výdělanou provizi do 1-7 pracovních dnů.",
	comissionEarned: "Výdělek získán",
	receivedBonusStorage: "Získané bonusové úložiště",
	referInfo: "Získejte až __STORAGE__ úložiště za pozvání dalších uživatelů",
	referInfo2:
		"Za každého přítele, kterého pozvete do Filen, získáte __STORAGE__ a váš přítel také získá __OTHER_STORAGE__. Váš přítel se může zaregistrovat na předplatné dle svého výběru a vy získáte provizi. Minimální výplata provize je __THRESHOLD__€. Vaše sazba je __RATE__%.",
	yourBtcAddress: "Vaše Bitcoinová adresa",
	openUploads: "Otevřít nahrávání",
	payNow: "Zaplatit nyní",
	emptyTrashModalSure: "Opravdu chcete vyprázdnit koš? Tutu akci nelze vrátit zpět!",
	emptyTrashSuccess: "Koš vyprázdněn",
	itemRenamed: "Položka přejmenována",
	subCancelled: "Zrušeno",
	support: "Podpora",
	finishing: "Dokončování",
	searchNothingFound: '"__TERM__" nenalezeno',
	queued: "Čeká ve frontě",
	searchInThisFolder: "Hledat v této složce...",
	fileVersioning: "Verzování souborů",
	loginAlerts: "Upozornění na přihlášení",
	chats: "Konverzace",
	chatsMessageInput: "Poslat zprávu uživateli __NAME__",
	notes: "Poznámky",
	contacts: "Kontakty",
	chatConversations: "Konverzace",
	chatParticipants: "Účastníci",
	chatNew: "Nový chat",
	chatNewInviteEmail: "Emailová adresa",
	chatAddUserToConversation: "Přidat uživatele",
	add: "Přidat",
	chatInput: "Poslat zprávu",
	noteAddParticipant: "Přidat účastníka",
	preparingFilesDots: "Příprava souborů...",
	loadingDots: "Načítání...",
	participants: "Účastníci",
	owner: "Vlastník",
	removeParticipantNote: "Odebrat účastníka z této poznámky",
	toggleParticipantWritePermissionsClick: "Účastník má oprávnění k zápisu, klikněte pro změnu",
	toggleParticipantReadPermissionsClick: "Účastník má oprávnění ke čtení, klikněte pro změnu",
	addNewParticipant: "Přidat nového účastníka",
	noteHistory: "Historie",
	noteTypeText: "Text",
	noteTypeRich: "Formátovaný text",
	noteTypeMd: "Markdown",
	noteTypeChecklist: "Seznam",
	noteTypeCode: "Kód",
	noteUnpin: "Odepnout",
	notePin: "Připnout",
	noteUnfavorite: "Odebrat z oblíbených",
	noteFavorite: "Přidat do oblíbených",
	noteDuplicate: "Duplikovat",
	noteExport: "Exportovat",
	noteTrash: "Přesunout do koše",
	noteArchive: "Archivovat",
	noteRestore: "Obnovit",
	noteDelete: "Smazat",
	noteSynced: "Poznámka synchronizována",
	syncingNote: "Synchronizace poznámky...",
	contactRequestSent: "Žádost o kontakt odeslána",
	addContact: "Přidat kontakt",
	addContactSmall: "Přidat",
	addContactEmail: "Filen emailová adresa kontaktu",
	blockedUsers: "Blokovaní uživatelé",
	contactsOnline: "Online",
	contactsAll: "Vše",
	contactsOffline: "Offline",
	contactsPending: "Čekající",
	contactsRequests: "Požadavky",
	contactsBlocked: "Blokovaní",
	blockUser: "Blokovat",
	removeUser: "Odebrat",
	contactsIcomingRequests: "Příchozí požadavky",
	contactsOutgoingRequests: "Odchozí požadavky",
	noteHistoryModal: "Historie poznámky",
	noteHistoryRestore: "Obnovit tuto verzi",
	searchInput: "Hledat...",
	notesTagsNameExists: "Štítek s tímto názvem již existuje",
	notesTagsCreate: "Vytvořit štítek",
	newNote: "Nová poznámka",
	notesNoNotesFoundUnderTag: "Žádné poznámky nenalezeny.",
	notesCreate: "Vytvořit",
	notesCreateInfo: "Nemáte žádné poznámky.",
	notesDeleteWarning: "Opravdu chcete smazat tuto poznámku? Tuto akci nelze vrátit zpět!",
	notesTagDeleteWarning: "Opravdu chcete smazat tento štítek? Tuto akci nelze vrátit zpět!",
	notesTagsDelete: "Smazat štítek",
	deleteNote: "Smazat poznámku",
	toggleParticipantWritePermissions: "Účastník má oprávnění k zápisu, klikněte pro změnu",
	toggleParticipantReadPermissions: "Účastník má oprávnění ke čtení, klikněte pro změnu",
	notesTagsRename: "Přejmenovat štítek",
	notesTagsCreateRenamePlaceholder: "Název štítku",
	noContactsFound: "Žádné kontakty nenalezeny.",
	noParticipantsFound: "Žádní účastníci nenalezeni.",
	leave: "Opustit",
	leaveNote: "Opustit poznámku",
	leaveNoteWarning: "Opravdu chcete opustit tuto poznámku? Tuto akci nelze vrátit zpět!",
	noteRemoveParticipant: "Odebrat účastníka",
	noteRemoveParticipantWarning: "Opravdu chcete odebrat tohoto účastníka z této poznámky? Tuto akci nelze vrátit zpět!",
	block: "Blokovat",
	blockUserWarning: "Opravdu chcete blokovat __NAME__?",
	removeUserWarning: "Opravdu chcete odebrat __NAME__ ze svého seznamu kontaktů?",
	chatYou: "Vy",
	chatNoMessagesYet: "Zatím žádné zprávy",
	chatMessageDelete: "Smazat zprávu",
	chatMessageDeleteWarning: "Opravdu chcete smazat tuto zprávu? Tuto akci nelze vrátit zpět!",
	chatConversationDelete: "Smazat konverzaci",
	chatConversationDeleteWarning: "Opravdu chcete smazat tuto konverzaci? Tuto akci nelze vrátit zpět!",
	chatConversationLeave: "Opustit konverzaci",
	chatConversationLeaveWarning: "Opravdu chcete opustit tuto konverzaci?",
	chatConversationRemoveParticipant: "Odebrat účastníka",
	chatConversationRemoveParticipantWarning: "Opravdu chcete odebrat tohoto účastníka z konverzace?",
	chatInfoTitle: "Konverzace s koncovým šifrováním",
	chatInfoSubtitle1: "Filen zabezpečuje ve výchozím nastavení každý chat koncovým šifrováním.",
	chatInfoSubtitle2: "Pouze členové konverzace mohou dešifrovat a číst obsah.",
	chatInfoSubtitle3:
		"Systém zajišťuje, že data, která přijímáte, skutečně pocházejí od zobrazeného uživatele a nebyla v průběhu změněna.",
	chatEmojisMatching: "Odpovídající smajlíci",
	selectFromCloud: "Vybrat z cloudu",
	creatingPublicLinks: "Vytváření veřejných odkazů",
	attachToChat: "Připojit do konverzace",
	select: "Vybrat",
	selectNumItems: "Vybrat __NUM__ položek",
	chatSettings: "Nastavení konverzace",
	chatSettingsDisplayName: "Zobrazované jméno",
	chatSettingsAppearOffline: "Zobrazit jako offline",
	chatMessageHiddenUserBlocked: "Zpráva blokovaného kontaktu skryta.",
	chatConversationCreateSidebar: "Zatím žádné zabezpečené konverzace.",
	chatConversationCreateSidebarCreate: "Začít mluvit",
	noConversationFound: "Nenalezena žádná konverzace.",
	copyText: "Kopírovat text",
	chatEdited: "upraveno",
	chatViewingOlderMessages: "Prohlížíte starší zprávy",
	chatJumpToPresent: "Přeskočit na aktuální",
	twoFactorConfirmAlert: "Bezpečně jsem uložil svůj klíč pro obnovu 2FA",
	chatIsTyping: "píše",
	chatConversationEditName: "Přejmenovat konverzaci",
	copyId: "Kopírovat ID",
	replyToChatMessage: "Odpovědět",
	chatReplyingTo: "Odpovídáte",
	chatUnreadMessagesSince: "nepřečtené zprávy od __DATE__",
	chatMarkAsRead: "Označit jako přečtené",
	profileMemberSince: "Členem od __DATE__",
	profileAddContact: "Poslat žádost o kontakt",
	profileBlockContact: "Blokovat",
	profileUnblockContact: "Odblokovat",
	profile: "Profil",
	chatMessageLimitReached: "Limit zpráv je __LIMIT__ znaků",
	chatAttachmentTooManyFiles: "Nemůžete připojit více než __LIMIT__ souborů na zprávu",
	noteTooBig: "Maximální velikost poznámky je __MAXSIZE__",
	notesTags: "Štítky",
	notesType: "Typ",
	notesAll: "Vše",
	notesPinned: "Připnuté",
}

export default cs
