const uk: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "Надіслати інструкції",
	goBack: "Назад",
	invalidEmailAndPassword: "Неправильна адреса електронної пошти і пароль",
	email: "Електронна пошта",
	password: "Пароль",
	emailAddress: "Адреса електронної пошти",
	tfaCode: "Код двофакторної аутентифікації",
	login: "Увійти",
	dontHaveAnAccountYet: "Ще не маєте облікового запису?",
	accountCreateOne: "Створіть!",
	forgotYourPassword: "Забули свій пароль?",
	couldNotFindDefaultFolder: "Не вдалося знайти папку за замовчуванням",
	unlockLink: "Розблокувати",
	download: "Скачати",
	cannotDownloadEmptyFolder: "Не вдалося скачати порожню папку",
	thisFolderIsEmpty: "Ця папка порожня",
	linkFolderEmptyInfo: "Коли файли або папки будуть додані в цю папку, вони з'являться тут",
	confirmPassword: "Підтвердіть пароль",
	createAccount: "Створити обліковий запис",
	alreadyHaveAnAccount: "Вже маєте обліковий запис?",
	loginEx: "Увійдіть!",
	resendConfirmationEmail: "Повторно надіслати лист для підтвердження",
	reportAbuseModal_spam: "Спам",
	reportAbuseModal_dmca: "Порушення авторських прав",
	reportAbuseModal_cp: "Матеріали про сексуальне насильство над дітьми",
	reportAbuseModal_stolen: "Викрадені дані",
	reportAbuseModal_malware: "Зловмисне програмне забезпечення",
	reportAbuseModal_other: "Інше",
	abuseReport: "Повідомлення про порушення",
	abuseReportModalInfo:
		"Використовуйте цю форму, щоб повідомити про неприйнятні, незаконні або інші шкідливі файли, до яких надається спільний доступ.",
	yourEmailAddress: "Ваша адреса електронної пошти",
	abuseReportModalInfoPlaceholder:
		"Будь ласка, надайте нам всі деталі, щоб ми могли вжити відповідних заходів (необов'язково)",
	send: "Відправити",
	abuseReportModalReason: "Будь ласка, оберіть причину",
	avatarUploadMaxFileSize: "Розмір файлу не повинен перевищувати 3 МБ",
	storageUsed: "Використано пам'яті",
	storageUsedInfo: "__USED__ з __MAX__ використано",
	storageUsedFilesAndFolders: "Файли __USED__",
	storageUsedVersionedFiles: "Версіоновані файли __USED__",
	storageUsedFree: "Вільно __FREE__",
	avatar: "Аватар",
	edit: "Редагувати",
	personalInformation: "Персональні дані",
	darkMode: "Темна тема",
	language: "Мова",
	change: "Змінити",
	requestAccountData: "Запросити дані облікового запису",
	request: "Зробити запит",
	logout: "Вийти",
	versionedFiles: "Версіоновані файли",
	delete: "Видалити",
	allFilesAndFolders: "Всі файли та папки",
	deleteAccount: "Видалити обліковий запис",
	changePassword: "Змінити пароль",
	tfa: "Двофакторна аутентифікація",
	subMoreInfo: "Більше інформації про вашу підписку",
	paymentMethod: "Спосіб оплати",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "Криптовалюта",
	update: "Оновити",
	cancel: "Скасувати",
	noSubs: "Наразі у вас немає активних підписок.",
	noInvoices: "Ви ще не маєте рахунків.",
	planFeatures_1: "Необмежена пропускна здатність",
	planFeatures_2: "Шифрування на стороні клієнта",
	planFeatures_3: "Технологія нульового знання",
	planTerms_monthly: "Щомісячно",
	planTerms_annually: "Щорічно",
	planTerms_lifetime: "Довічно",
	planTerms_starter: "Стартові",
	buyNow: "Купити зараз",
	general: "Загальні",
	settings: "Налаштування",
	security: "Безпека",
	plans: "Плани",
	subs: "Підписки",
	events: "Події",
	invoices: "Рахунки",
	invalidEmail: "Неправильна адреса електронної пошти",
	invalidPassword: "Неправильний пароль",
	emailsDoNotMatch: "Адреси електронної пошти не збігаються",
	changeEmailPleaseConfirm:
		"Будь ласка, підтвердіть вашу нову адресу електронної пошти, перейшовши за посиланням, надісланим на неї",
	newEmail: "Нова адреса електронної пошти",
	confirmNewEmail: "Підтвердіть нову адресу електронної пошти",
	save: "Зберегти",
	firstName: "Ім'я",
	lastName: "Прізвище",
	companyName: "Назва компанії",
	vatId: "ІНПП",
	street: "Вулиця",
	streetNumber: "Номер будинку",
	city: "Місто",
	postalCode: "Поштовий індекс",
	country: "Країна",
	areYouSure: "Ви впевнені?",
	areYouSureDeleteAllVersioned:
		"Ви впевнені, що хочете видалити всі версоновані файли? Ця дія не може бути скасована!",
	areYouSureDeleteAll: "Ви впевнені, що хочете видалити всі файли і папки? Ця дія не може бути скасована!",
	invalidNewPassword: "Неправильний новий пароль",
	invalidCurrentPassword: "Неправильний поточний пароль",
	newPasswordsDontMatch: "Нові паролі не збігаються",
	invalidMasterKeys: "Недійсні майстер-ключі",
	passwordChanged: "Пароль змінено",
	newPassword: "Новий пароль",
	confirmNewPassword: "Підтвердіть новий пароль",
	currentPassword: "Поточний пароль",
	deleteAccountConfirm:
		"Будь ласка, підтвердіть видалення облікового запису, перейшовши за посиланням, надісланим на вашу електронну пошту",
	areYouSureDeleteAccount:
		"Ви впевнені, що хочете видалити свій обліковий запис? Ми надішлемо посилання для підтвердження на вашу електронну пошту.",
	invalid2FACode: "Неправильний код двофакторної аутентифікації",
	enable2FA: "Увімкнути двофакторну аутентифікацію",
	copy: "Копіювати",
	enter2FA: "Введіть код двофакторної аутентифікації",
	enable: "Увімкнути",
	recoveryKeys: "Ключі відновлення",
	recoveryKeysInfo:
		"Будь ласка, зберігайте ключ відновлення у надійному місці. Це єдиний спосіб відновити свій обліковий запис у разі втрати пристрою для двофакторної аутентифікації.",
	close: "Закрити",
	disable2FA: "Вимкнути двофакторну аутентифікацію",
	disable: "Вимкнути",
	sharedWithMe: "Доступно вам",
	sharedWithOthers: "Спільний доступ",
	links: "Посилання",
	favorites: "Обране",
	recents: "Недавні",
	trash: "Кошик",
	cloudDrive: "Хмарний диск",
	emptyTrash: "Очистити кошик",
	monthlyRecurring: "Щомісячне повторення",
	annuallyRecurring: "Щорічне повторення",
	oneTimePayment: "Одноразовий платіж",
	buyRecurringInfo:
		"Купуючи тарифний план, ви дозволяєте Filen автоматично стягувати з вас плату за кожен розрахунковий період, поки ви не скасуєте його. Ви можете скасувати в будь-який час через сторінку свого облікового запису. Часткове відшкодування не здійснюється.",
	buyLifetimeInfo:
		"Купуючи тарифний план, ви дозволяєте Filen стягувати плату згідно вашого способу оплати. Часткове відшкодування не здійснюється.",
	creditDebit: "Кредитні / дебетові картки",
	cancelSub: "Скасувати підписку",
	cancelSubSure: "Ви впевнені, що бажаєте скасувати цю підписку?",
	cancellingSub: "Скасування підписки",
	createFolder: "Створити папку",
	createTextFile: "Створити текстовий файл",
	uploadFiles: "Завантажити файли",
	uploadFolders: "Завантажити папки",
	preview: "Попередній перегляд",
	preparingDownload: "Підготовка до скачування",
	normalDownload: "Звичайне скачування",
	zipDownload: "ZIP-скачування",
	publicLink: "Публічне посилання",
	share: "Поділитися",
	versions: "Версії",
	color: "Колір",
	color_default: "За замовчуванням",
	color_blue: "Голубий",
	color_green: "Зелений",
	color_purple: "Фіолетовий",
	color_red: "Червоний",
	color_gray: "Сірий",
	unfavorite: "Видалити з обраного",
	favorite: "Додати в обране",
	rename: "Перейменувати",
	move: "Перемістити",
	selectDestination: "Виберіть місце призначення",
	restore: "Відновити",
	deletePerm: "Видалити назавжди",
	remove: "Видалити",
	stopSharing: "Вилучити зі спільного доступу",
	cookieConsent: "Цей сайт використовує файли cookie для оцінки та покращення вашого досвіду використання.",
	accept: "Прийняти",
	optOut: "Відмовитись",
	onlyNeeded: "Лише необхідні",
	pleaseChooseDiffName: "Будь ласка, оберіть іншу назву",
	newFolderName: "Назва",
	itemsMovedToTrash: "__COUNT__ елементів поміщено у кошик",
	couldNotMoveToTrash: "Не вдалось помістити __NAME__ у кошик: __ERR__",
	deleteModalSure: "Ви впевнені, що хочете видалити __COUNT__ елементів?",
	deletePermModalSure: "Ви впевнені, що хочете назавжди видалити __COUNT__ елементів? Ця дія не може бути скасована!",
	itemsDeletedPerm: "__COUNT__ елементів видалено назавжди",
	couldNotDeletePerm: "Не вдалося видалити __NAME__ назавжди: __ERR__",
	uploadHere: "Завантажити сюди",
	event: "Подія",
	date: "Дата",
	ipAddress: "IP адреса",
	na: "Невідомо",
	listEmpty_1: "Вам ще не давали доступ до файлів або папок",
	listEmpty_2: "Коли хтось надасть вам доступ до файлів або папок, вони з'являться тут",
	listEmpty_3: "Ви ще не надавали іншим доступ до файлів або папок",
	listEmpty_4: "Коли ви надасте доступ до файлів або папок, вони з'являться тут",
	listEmpty_5: "Публічних посилань поки що немає",
	listEmpty_6: "Після створення публічних посилань для файлів або папок вони з'являться тут",
	listEmpty_7: "Поки немає обраного",
	listEmpty_8: "Після того, як ви позначите файли або папки як обрані, вони з'являться тут",
	listEmpty_9: "Нещодавніх подій ще немає",
	listEmpty_10: "Нещодавно завантажені файли з'являться тут",
	listEmpty_11: "Кошик порожній",
	listEmpty_12: "Після того, як ви позначите файли або папки для видалення, вони з'являться тут",
	listEmpty_13: "Ще не завантажено жодного файлу або папки",
	listEmpty_14: "Перетягніть сюди файли або папки або натисніть кнопку нижче",
	listFooterSelected: "Вибрано __COUNT__ з __TOTAL__",
	name: "Назва",
	size: "Розмір",
	lastModified: "Востаннє модифіковано",
	moveModalBtn: "Перемістити __COUNT__ об'єктів до __DEST__",
	file: "Файл",
	ctrlPlusS: "CTRL + S",
	exit: "Вийти",
	fileHasBeenChanged: "Файл було змінено",
	textEditorExitSure: "Ви впевнені, що хочете вийти без збереження?",
	saveChanges: "Зберегти зміни",
	expire_never: "Ніколи",
	expire_1h: "1 годину",
	expire_6h: "6 годин",
	expire_1d: "1 день",
	expire_3d: "3 дні",
	expire_7d: "7 днів",
	expire_14d: "14 днів",
	expire_30d: "30 днів",
	addingItemsToPublicLink: "Додавання об'єктів у публічне посилання",
	addingItemsToPublicLinkProgress: "Додавання __LEFT__ об'єктів у публічне посилання",
	enabled: "Увімкнено",
	disabled: "Вимкнено",
	copied: "Скопійовано",
	publicLinkDownloadBtn: "Кнопка скачування увімкнена",
	publicLinkPassword: "Пароль (залиште пустим, щоб відключити)",
	removedSharedItems: "Видалено __COUNT__ об'єктів зі спільного доступу",
	couldNotRemoveSharedItems: "Не вдалося видалити __NAME__: __ERR__",
	removeSharedItemsModalInfo:
		"Ви впевнені, що хочете видалити __COUNT__ елементів? Ви більше не зможете отримати до них доступ.",
	renameNewName: "Нова назва",
	selectFromComputer: "Вибрати на комп'ютері",
	upload: "Завантажити",
	files: "Файли",
	folders: "Папки",
	newFolder: "Нова папка",
	sharedWith: "Спільний доступ надано",
	sharingItems: "Надання спільного доступу до об'єктів",
	sharingItemsProgress: "Надання спільного доступу до __LEFT__ об'єктів",
	itemsSharedWith: "Спільний доступ до __COUNT__ об'єктів надано __EMAIL__",
	shareReceiver: "Адреса електронної пошти облікового запису Filen одержувача",
	upgrade: "Покращити",
	stoppedSharingItems: "__COUNT__ об'єктів вилучено зі спільного доступу",
	couldNotStopSharingItem: "Не вдалося вилучити зі спільного доступу __NAME__: __ERR__",
	stopSharingModalSure:
		"Ви впевнені, що хочете вилучити зі спільного доступу __COUNT__ об'єктів? Ви більше не зможете отримати до них доступ.",
	aboutRemaining: "Залишилось приблизно __TIME__",
	transferringItems: "Передача __COUNT__ об'єктів",
	uploadingItems: "Завантаження __COUNT__ об'єктів",
	downloadingItems: "Скачування __COUNT__ об'єктів",
	new: "Додати",
	resume: "Продовжити",
	pause: "Пауза",
	stop: "Стоп",
	done: "Готово",
	noUploadsQueued: "Немає завантажень у черзі",
	creatingFolders: "Створення папок",
	creatingFoldersProgress: "Створення __LEFT__ папок",
	current: "Поточні",
	create: "Створити",
	changingColor: "Зміна кольору для __COUNT__ об'єктів",
	couldNotChangeColor: "Не вдалося змінити колір для __NAME__: __ERR__",
	eventFileUploaded: "Файл завантажено",
	eventFileVersioned: "Файл версіоновано",
	eventVersionedFileRestored: "Версіонований файл відновлено",
	eventFileMoved: "Файл переміщено",
	eventFileTrash: "Файл поміщено у кошик",
	eventFileRm: "Файл видалено",
	eventFileRestored: "Файл відновлено",
	eventFileShared: "Надано спільний доступ до файлу",
	eventFileLinkEdited: "Публічне посилання файлу відредаговано",
	eventFolderTrash: "Папку поміщено до кошика",
	eventFolderShared: "Надано спільний доступ до папки",
	eventFolderMoved: "Папку переміщено",
	eventFolderRenamed: "Папку перейменовано",
	eventFolderCreated: "Папку створено",
	eventFolderRestored: "Папку відновлено",
	eventFolderColorChanged: "Колір папки змінено",
	eventLogin: "Увійти",
	eventDeleteVersioned: "Версіоновані файли та папки видалено",
	eventDeleteAll: "Всі файли та папки видалено",
	eventDeleteUnfinished: "Незавершені завантаження видалено",
	eventTrashEmptied: "Кошик очищено",
	eventRequestAccountDeletion: "Надіслано запит на видалення облікового запису",
	event2FAEnabled: "Двофакторна аутентифікація увімкнена",
	event2FADisabled: "Двофакторна аутентифікація вимкнена",
	eventCodeRedeem: "Код використано",
	eventEmailChanged: "Адресу електронної пошти змінено",
	eventPasswordChanged: "Пароль змінено",
	eventRemovedSharedInItems: "Доступні вам файли видалено",
	eventRemovedSharedOutItems: "Файли зі спільного доступу видалено",
	eventFileUploadedInfo: "Файл __NAME__ завантажено",
	eventFileVersionedInfo: "Файл __NAME__ версіоновано",
	eventVersionedFileRestoredInfo: "Версія файла __NAME__ відновлена",
	eventFileRenamedInfo: "Файл __NAME__ перейменовано на __NEW__",
	eventFileMovedInfo: "Файл __NAME__ переміщено",
	fileRenamedInfo: "Файл __NAME__ перейменовано на __NEW__",
	eventFileTrashInfo: "Файл __NAME__ поміщено у кошик",
	eventFileRmInfo: "Файл __NAME__ видалено",
	eventFileRestoredInfo: "Файл __NAME__ відновлено з кошика",
	eventFileSharedInfo: "Спільний доступ по файлу __NAME__ надано __EMAIL__",
	eventFileLinkEditedInfo: "Публічне посилання файла __NAME__ відредаговано",
	eventFolderTrashInfo: "Папку __NAME__ поміщено у кошик",
	eventFolderSharedInfo: "Спільний доступ по папки __NAME__ надано __EMAIL__",
	eventFolderMovedInfo: "Папку __NAME__ видалено",
	eventFolderRenamedInfo: "Папку __NAME__ перейменовано на __NEW__",
	eventFolderCreatedInfo: "Папку __NAME__ створено",
	eventFolderRestoredInfo: "Папку __NAME__ відновлено з кошика",
	eventFolderColorChangedInfo: "Колір папки __NAME__ змінено",
	eventLoginInfo: "Вхід здійснено",
	eventDeleteVersionedInfo: "Версіоновані файли та папки видалено",
	eventDeleteAllInfo: "Всі файли та папки видалено",
	eventDeleteUnfinishedInfo: "Незавершені завантаження видалено",
	eventTrashEmptiedInfo: "Кошик очищено",
	eventRequestAccountDeletionInfo: "Запит на видалення облікового запису надіслано",
	event2FAEnabledInfo: "Двофакторна аутентифікація увімкнена",
	event2FADisabledInfo: "Двофакторна аутентифікація вимкнена",
	eventCodeRedeemInfo: "Код __CODE__ використано",
	eventEmailChangedInfo: "Адресу електронної пошти змінено на __EMAIL__",
	eventPasswordChangedInfo: "Пароль змінено",
	eventRemovedSharedInItemsInfo: "__COUNT__ доступних вам файлів від __EMAIL__ видалено",
	eventRemovedSharedOutItemsInfo: "__COUNT__ файлів зі спільного доступу з __EMAIL__ видалено",
	favoritingItems: "Додавання __COUNT__ об'єктів до обраного",
	unfavoritingItems: "Видалення __COUNT__ об'єктів з обраного",
	couldNotChangeFavStatus: "Не вдалося змінити статус обраності для __NAME__: __ERR__",
	pleaseChooseDiffDest: "Будь ласка, оберіть інше місце призначення",
	movingItems: "Переміщення __COUNT__ об'єктів",
	folderExistsAtDest: "Папка з назвою __NAME__ вже існує в цьому місці",
	couldNotMoveItem: "Не вдалося перемістити __NAME__: __ERR__",
	restoringItems: "Відновлення __COUNT__ об'єктів",
	couldNotRestoreItem: "Не вдалося відновити __NAME__: __ERR__",
	changeEmail: "Змінити адресу електронної пошти",
	passwordsDoNotMatch: "Паролі не збігаються",
	registerWeakPassword: "Ваш пароль повинен бути довжиною не менше 10 символів",
	invalidEmailOrPassword: "Неправильна адреса електронної пошти або пароль",
	unknownErrorSupp: "Виникла невідома помилка. Будь ласка, спробуйте ще раз або зверніться до служби підтримки.",
	registerEmailAlreadyRegistered: "Ця адреса електронної пошти вже зареєстрована.",
	gotIt: "Ясно",
	registration: "Реєстрація",
	registrationEmailInstructions:
		"Для завершення реєстрації, будь ласка, підтвердіть свою адресу електронної пошти. Посилання з інструкціями надіслано вам на пошту.",
	forgotPasswordEmailSent: "На вашу електронну пошту було надіслано лист з інструкціями щодо відновлення паролю.",
	maxStorageReached: "Сховище заповнено",
	maxStorageReachedInfo:
		"Ви вичерпали свій максимальний обсяг сховища. Будь ласка, покращіть свій обліковий запис, щоб завантажити більше файлів.",
	upgradeNow: "Покращити зараз",
	abuseReportSubmitted: "Повідомлення про порушення надіслано!",
	invalidAbuseReason: "Недійсна причина",
	uploadErrored: "Не вдалося",
	newTextFileName: "Назва нового текстового файлу",
	passwordResetSuccess: "Пароль змінено. Будь ласка, повторно увійдіть в систему з усіх пристроїв.",
	resetPasswordBtn: "Скинути пароль",
	publicLinkDisabled: "Публічне посилання відключено",
	expireAfter: "Спливає через",
	exportMasterKeys: "Експортувати майстер-ключі",
	export: "Експортувати",
	resetPasswordCheckbox:
		"Я розумію, що скинувши пароль не експортувавши майстер-ключі, я зроблю недоступними всі дані, що зберігаються в моєму обліковому записі, через те, як працює наскрізне шифрування з нульовим знанням.",
	invalidAuthVersion: "Неправильна версія аутентифікації",
	recoveryMasterKeysInput: "Експортовані майстер-ключі",
	exportMasterKeysInfo:
		"Експорт майстер-ключів дає можливість відновити обліковий запис без втрати даних у випадку, якщо ви забули пароль. Будь ласка, обов'язково експортуйте нові майстер-ключі щоразу після зміни пароля.",
	import: "Імпортувати",
	invite: "Запросити",
	yourReferralLink: "Ваше посилання",
	requestPayout: "Запросити виплату",
	invalidPayoutAddress: "Неправильна адреса виплати",
	affiliatePayoutSuccess: "Запит на виплату подано",
	requestPayoutInfo:
		"Ми відправимо вашу зароблену партнерську комісію на вказану Bitcoin-адресу протягом 1-7 робочих днів.",
	comissionEarned: "Зароблена комісія",
	receivedBonusStorage: "Загальне отримане бонусне сховище",
	referInfo: "Отримуйте до __STORAGE__ сховища, запрошуючи інших",
	referInfo2:
		"За кожного друга, якого ви запросите до Filen, ви отримаєте __STORAGE__ - а ваш друг також отримає __OTHER_STORAGE__. Ваш друг може оформити підписку на обраний ним тарифний план, а ви зароблятимете комісійні. Поріг для виплати комісії становить __THRESHOLD__€. Ваша частка складає __RATE__%.",
	yourBtcAddress: "Ваша Bitcoin-адреса",
	openUploads: "Відкрити список завантажень",
	payNow: "Оплатити рахунок зараз",
	emptyTrashModalSure: "Ви впевнені, що хочете очистити кошик? Цю дію не можна скасувати!",
	emptyTrashSuccess: "Кошик очищено",
	itemRenamed: "Об'єкт перейменовано",
	subCancelled: "Підписку скасовано",
	support: "Підтримка",
	finishing: "Завершення",
	searchNothingFound: '"__TERM__" не знайдено',
	queued: "В черзі",
	searchInThisFolder: "Шукати в цій папці...",
	fileVersioning: "Версіонування файлів",
	loginAlerts: "Сповіщення про вхід до системи",
	chats: "Чати",
	chatsMessageInput: "Надіслати повідомлення __NAME__",
	notes: "Нотатки",
	contacts: "Контакти",
	chatConversations: "Чати",
	chatParticipants: "Учасники",
	chatNew: "Новий чат",
	chatNewInviteEmail: "Адреса електронної пошти",
	chatAddUserToConversation: "Додати користувача",
	add: "Додати",
	chatInput: "Надіслати повідомлення",
	noteAddParticipant: "Додати учасника",
	preparingFilesDots: "Підготовка файлів...",
	loadingDots: "Завантаження...",
	participants: "Учасники",
	owner: "Власник",
	removeParticipantNote: "Видалити учасника з цієї нотатки",
	toggleParticipantWritePermissionsClick: "Учасник має права на запис, натисніть, щоб змінити",
	toggleParticipantReadPermissionsClick: "Учасник має права на читання, натисніть, щоб змінити",
	addNewParticipant: "Додати нового учасника",
	noteHistory: "Історія",
	noteTypeText: "Текст",
	noteTypeRich: "Форматований текст",
	noteTypeMd: "Markdown",
	noteTypeChecklist: "Контрольний список",
	noteTypeCode: "Код",
	noteUnpin: "Відкріпити",
	notePin: "Закріпити",
	noteUnfavorite: "Видалити з обраного",
	noteFavorite: "Додати в обране",
	noteDuplicate: "Дублювати",
	noteExport: "Експортувати",
	noteTrash: "Кошик",
	noteArchive: "Архів",
	noteRestore: "Відновити",
	noteDelete: "Видалити",
	noteSynced: "Нотатку синхронізовано",
	syncingNote: "Синхронізація нотатки...",
	contactRequestSent: "Запит на контакт надіслано",
	addContact: "Додати контакт",
	addContactSmall: "Додати контакт",
	addContactEmail: "Адреса електронної пошти контакта Filen",
	blockedUsers: "Заблоковані користувачі",
	contactsOnline: "В мережі",
	contactsAll: "Всі",
	contactsOffline: "Не в мережі",
	contactsPending: "В очікуванні",
	contactsRequests: "Запити",
	contactsBlocked: "Заблоковані",
	blockUser: "Заблокувати",
	removeUser: "Видалити",
	contactsIcomingRequests: "Вхідні запити",
	contactsOutgoingRequests: "Вихідні запити",
	noteHistoryModal: "Історія нотатки",
	noteHistoryRestore: "Відновити цю версію",
	searchInput: "Шукати...",
	notesTagsNameExists: "Тег з такою назвою вже існує",
	notesTagsCreate: "Створити тег",
	newNote: "Нова нотатка",
	notesNoNotesFoundUnderTag: "Нотаток не знайдено.",
	notesCreate: "Створити",
	notesCreateInfo: "У вас ще немає нотаток.",
	notesDeleteWarning: "Ви впевнені, що хочете видалити цю нотатку? Ця дія не може бути скасована!",
	notesTagDeleteWarning: "Ви впевнені, що хочете видалити цей тег? Ця дія не може бути скасована!",
	notesTagsDelete: "Видалити тег",
	deleteNote: "Видалити нотатку",
	toggleParticipantWritePermissions: "Учасник має права на запис, натисніть, щоб змінити",
	toggleParticipantReadPermissions: "Учасник має права на читання, натисніть, щоб змінити",
	notesTagsRename: "Перейменувати тег",
	notesTagsCreateRenamePlaceholder: "Назва тегу",
	noContactsFound: "Контактів не знайдено.",
	noParticipantsFound: "Учасників не знайдено.",
	leave: "Покинути",
	leaveNote: "Покинути нотатку",
	leaveNoteWarning: "Ви впевнені, що хочете покинути цю нотатку? Ця дія не може бути скасована!",
	noteRemoveParticipant: "Видалити учасника",
	noteRemoveParticipantWarning: "Ви впевнені, що хочете видалити цього учасника з цієї нотатки? Ця дія не може бути скасована!",
	block: "Заблокувати",
	blockUserWarning: "Ви впевнені, що хочете заблокувати __NAME__?",
	removeUserWarning: "Ви впевнені, що хочете видалити __NAME__ зі списку контактів?",
	chatYou: "Ви",
	chatNoMessagesYet: "Ще немає повідомлень",
	chatMessageDelete: "Видалити повідомлення",
	chatMessageDeleteWarning: "Ви впевнені, що хочете видалити це повідомлення? Ця дія не може бути скасована!",
	chatConversationDelete: "Видалити чат",
	chatConversationDeleteWarning: "Ви впевнені, що хочете видалити цей чат? Ця дія не може бути скасована!",
	chatConversationLeave: "Покинути чат",
	chatConversationLeaveWarning: "Ви впевнені, що хочете покинути цей чат?",
	chatConversationRemoveParticipant: "Видалити учасника",
	chatConversationRemoveParticipantWarning: "Ви впевнені, що хочете видалити цього учасника з чату?",
	chatInfoTitle: "Чат з наскрізним шифруванням",
	chatInfoSubtitle1: "Filen за замовчуванням захищає кожен чат наскрізним шифруванням з нульовою довірою.",
	chatInfoSubtitle2: "Тільки учасники чату можуть розшифрувати та прочитати вміст.",
	chatInfoSubtitle3:
		"Система гарантує, що отримані дані дійсно надходять від відображуваного користувача і не були змінені за минулий час.",
	chatEmojisMatching: "Підбір емодзі",
	selectFromCloud: "Вибрати з хмари",
	creatingPublicLinks: "Створення публічних посилань",
	attachToChat: "Прикріпити до чату",
	select: "Вибрати",
	selectNumItems: "Вибрати __NUM__ об'єктів",
	chatSettings: "Налаштування чату",
	chatSettingsDisplayName: "Відображуване ім'я",
	chatSettingsAppearOffline: "Статус \"Не в мережі\"",
	chatMessageHiddenUserBlocked: "Повідомлення від заблокованого контакту приховано.",
	chatConversationCreateSidebar: "Захищених чатів ще немає.",
	chatConversationCreateSidebarCreate: "Почати розмову",
	noConversationFound: "Чатів не знайдено.",
	copyText: "Копіювати текст",
	chatEdited: "редаговано",
	chatViewingOlderMessages: "Ви переглядаєте старі повідомлення",
	chatJumpToPresent: "Повернутися до останніх",
	twoFactorConfirmAlert: "Я безпечно зберіг(-ла) свій ключ відновлення двофакторної аутентифікації",
	chatIsTyping: "друкує",
	chatConversationEditName: "Редагувати назву",
	copyId: "Скопіювати ID",
	replyToChatMessage: "Відповісти",
	chatReplyingTo: "Відповідь на",
	chatUnreadMessagesSince: "непрочитаних повідомлень з __DATE__",
	chatMarkAsRead: "Позначити як прочитане",
	profileMemberSince: "Учасник з __DATE__",
	profileAddContact: "Надіслати запит на контакт",
	profileBlockContact: "Заблокувати",
	profileUnblockContact: "Розблокувати",
	profile: "Профіль",
	chatMessageLimitReached: "Ліміт повідомлення складає __LIMIT__ символів",
	chatAttachmentTooManyFiles: "До одного повідомлення можна прикріпити не більше __LIMIT__ файлів"
}

export default uk
