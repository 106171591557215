const en: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "Send instructions",
	goBack: "Go back",
	invalidEmailAndPassword: "Invalid email address and password",
	email: "Email",
	password: "Password",
	emailAddress: "Email address",
	tfaCode: "Two Factor Authentication Code",
	login: "Login",
	dontHaveAnAccountYet: "Don't have an account yet?",
	accountCreateOne: "Create one!",
	forgotYourPassword: "Forgot your password?",
	couldNotFindDefaultFolder: "Could not find default folder",
	unlockLink: "Unlock",
	download: "Download",
	cannotDownloadEmptyFolder: "Cannot download an empty folder",
	thisFolderIsEmpty: "This folder is empty",
	linkFolderEmptyInfo: "Once files or folders are added to this folder they will appear here",
	confirmPassword: "Confirm password",
	createAccount: "Create account",
	alreadyHaveAnAccount: "Already have an account?",
	loginEx: "Login!",
	resendConfirmationEmail: "Resend confirmation email",
	reportAbuseModal_spam: "Spam",
	reportAbuseModal_dmca: "Copyright infrigement",
	reportAbuseModal_cp: "Child sexual abuse material",
	reportAbuseModal_stolen: "Stolen data",
	reportAbuseModal_malware: "Malware",
	reportAbuseModal_other: "Other",
	abuseReport: "Abuse report",
	abuseReportModalInfo: "Use this form to report inappropriate, illegal, or otherwise harmful shared files.",
	yourEmailAddress: "Your email address",
	abuseReportModalInfoPlaceholder: "Please provide us with all the details so that we can take appropriate action (optional)",
	send: "Send",
	abuseReportModalReason: "Please choose a reason",
	avatarUploadMaxFileSize: "File size cannot exceed 3 MB",
	storageUsed: "Storage used",
	storageUsedInfo: "__USED__ of __MAX__ used",
	storageUsedFilesAndFolders: "Files __USED__",
	storageUsedVersionedFiles: "Versioned files __USED__",
	storageUsedFree: "Free __FREE__",
	avatar: "Avatar",
	edit: "Edit",
	personalInformation: "Personal information",
	darkMode: "Dark mode",
	language: "Language",
	change: "Change",
	requestAccountData: "Request account data",
	request: "Request",
	logout: "Logout",
	versionedFiles: "Versioned files",
	delete: "Delete",
	allFilesAndFolders: "All files and folders",
	deleteAccount: "Delete account",
	changePassword: "Change password",
	tfa: "Two Factor Authentication",
	subMoreInfo: "More information about your subscription",
	paymentMethod: "Payment method",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "Crypto",
	update: "Update",
	cancel: "Cancel",
	noSubs: "You currently do not have active subscriptions.",
	noInvoices: "You do not have any invoices yet.",
	planFeatures_1: "Unlimited bandwidth",
	planFeatures_2: "Client side encryption",
	planFeatures_3: "Zero knowledge technology",
	planTerms_monthly: "Monthly",
	planTerms_annually: "Annually",
	planTerms_lifetime: "Lifetime",
	planTerms_starter: "Starter",
	buyNow: "Buy now",
	general: "General",
	settings: "Settings",
	security: "Security",
	plans: "Plans",
	subs: "Subscriptions",
	events: "Events",
	invoices: "Invoices",
	invalidEmail: "Invalid email",
	invalidPassword: "Invalid password",
	emailsDoNotMatch: "Emails do not match",
	changeEmailPleaseConfirm: "Please confirm your new email address by clicking the link sent to it",
	newEmail: "New email",
	confirmNewEmail: "Confirm new email",
	save: "Save",
	firstName: "First name",
	lastName: "Last name",
	companyName: "Company name",
	vatId: "VAT ID",
	street: "Street",
	streetNumber: "Street number",
	city: "City",
	postalCode: "Postal code",
	country: "Country",
	areYouSure: "Are you sure?",
	areYouSureDeleteAllVersioned: "Are you sure you want to delete all versioned files? This action cannot be undone!",
	areYouSureDeleteAll: "Are you sure you want to delete all files and folders? This action cannot be undone!",
	invalidNewPassword: "Invalid new password",
	invalidCurrentPassword: "Invalid current password",
	newPasswordsDontMatch: "New passwords do not match",
	invalidMasterKeys: "Invalid master keys",
	passwordChanged: "Password changed",
	newPassword: "New password",
	confirmNewPassword: "Confirm new password",
	currentPassword: "Current password",
	deleteAccountConfirm: "Please confirm the account deletion by clicking the link sent to your email",
	areYouSureDeleteAccount: "Are you sure you want to delete your account? We will send a confirmation link to your email address.",
	invalid2FACode: "Invalid Two Factor Authentication code",
	enable2FA: "Enable Two Factor Authentication",
	copy: "Copy",
	enter2FA: "Enter Two Factor Authentication code",
	enable: "Enable",
	recoveryKeys: "Recovery keys",
	recoveryKeysInfo: "Please store your recovery key safely. It is the only way to recover your account in case of 2FA device loss.",
	close: "Close",
	disable2FA: "Disable Two Factor Authentication",
	disable: "Disable",
	sharedWithMe: "Shared with me",
	sharedWithOthers: "Shared with others",
	links: "Links",
	favorites: "Favorites",
	recents: "Recents",
	trash: "Trash",
	cloudDrive: "Cloud Drive",
	emptyTrash: "Empty trash",
	monthlyRecurring: "Monthly recurring",
	annuallyRecurring: "Anually recurring",
	oneTimePayment: "One time payment",
	buyRecurringInfo:
		"By purchasing a plan you authorize Filen to automatically charge you each billing period until you cancel. You can cancel anytime via your Account page. No partial refunds.",
	buyLifetimeInfo: "By purchasing a plan you authorize Filen to charge your payment method. No partial refunds.",
	creditDebit: "Credit / Debit cards",
	cancelSub: "Cancel subscription",
	cancelSubSure: "Are you sure you want to cancel this subscription?",
	cancellingSub: "Canceling subscription",
	createFolder: "Create folder",
	createTextFile: "Create text file",
	uploadFiles: "Upload files",
	uploadFolders: "Upload folders",
	preview: "Preview",
	preparingDownload: "Preparing download",
	normalDownload: "Normal download",
	zipDownload: "ZIP download",
	publicLink: "Public link",
	share: "Share",
	versions: "Versions",
	color: "Color",
	color_default: "Default",
	color_blue: "Blue",
	color_green: "Green",
	color_purple: "Purple",
	color_red: "Red",
	color_gray: "Gray",
	unfavorite: "Unfavorite",
	favorite: "Favorite",
	rename: "Rename",
	move: "Move",
	selectDestination: "Select destination",
	restore: "Restore",
	deletePerm: "Delete permanently",
	remove: "Remove",
	stopSharing: "Stop sharing",
	cookieConsent: "This site uses cookies to measure and improve your experience.",
	accept: "Accept",
	optOut: "Opt out",
	onlyNeeded: "Only needed",
	pleaseChooseDiffName: "Please choose a different name",
	newFolderName: "Name",
	itemsMovedToTrash: "__COUNT__ items moved to the trash",
	couldNotMoveToTrash: "Could not move __NAME__ to trash: __ERR__",
	deleteModalSure: "Are you sure you want to delete __COUNT__ items?",
	deletePermModalSure: "Are you sure you want to permanently delete __COUNT__ items? This action cannot be undone!",
	itemsDeletedPerm: "__COUNT__ items deleted permanently",
	couldNotDeletePerm: "Could not delete __NAME__ permanently: __ERR__",
	uploadHere: "Upload here",
	event: "Event",
	date: "Date",
	ipAddress: "IP address",
	na: "N/A",
	listEmpty_1: "No files or folder shared with you yet",
	listEmpty_2: "Once somebody shares files or folders with you they will appear here",
	listEmpty_3: "No files or folder shared with others yet",
	listEmpty_4: "Once you share files or folders with somebody they will appear here",
	listEmpty_5: "No public links yet",
	listEmpty_6: "Once you create public links for files or folders they will appear here",
	listEmpty_7: "No favorites yet",
	listEmpty_8: "Once you mark files or folders as favorites they will appear here",
	listEmpty_9: "No recents yet",
	listEmpty_10: "Recently uploaded files will appear here",
	listEmpty_11: "Nothing in the trash yet",
	listEmpty_12: "Once you mark files or folders for deletion they will appear here",
	listEmpty_13: "No files or folders uploaded yet",
	listEmpty_14: "Drag and drop files or folders here or press the button below",
	listFooterSelected: "__COUNT__ selected of __TOTAL__",
	name: "Name",
	size: "Size",
	lastModified: "Last modified",
	moveModalBtn: "Move __COUNT__ items to __DEST__",
	file: "File",
	ctrlPlusS: "CTRL + S",
	exit: "Exit",
	fileHasBeenChanged: "File has been changed",
	textEditorExitSure: "Are you sure you want to exit without saving?",
	saveChanges: "Save changes",
	expire_never: "Never",
	expire_1h: "1 hour",
	expire_6h: "6 hours",
	expire_1d: "1 day",
	expire_3d: "3 days",
	expire_7d: "7 days",
	expire_14d: "14 days",
	expire_30d: "30 days",
	addingItemsToPublicLink: "Adding items to public link",
	addingItemsToPublicLinkProgress: "Adding __LEFT__ items to public link",
	enabled: "Enabled",
	disabled: "Disabled",
	copied: "Copied",
	publicLinkDownloadBtn: "Download button enabled",
	publicLinkPassword: "Password (leave empty to disable)",
	removedSharedItems: "Removed __COUNT__ shared items",
	couldNotRemoveSharedItems: "Could not remove __NAME__: __ERR__",
	removeSharedItemsModalInfo: "Are you sure you want to remove __COUNT__ items? You will no longer be able the access them.",
	renameNewName: "New name",
	selectFromComputer: "Select from computer",
	upload: "Upload",
	files: "Files",
	folders: "Folders",
	newFolder: "New folder",
	sharedWith: "Shared with",
	sharingItems: "Sharing items",
	sharingItemsProgress: "Sharing __LEFT__ items",
	itemsSharedWith: "__COUNT__ items shared with __EMAIL__",
	shareReceiver: "Filen account email of the receiver",
	upgrade: "Upgrade",
	stoppedSharingItems: "Stopped sharing __COUNT__ items",
	couldNotStopSharingItem: "Could not stop sharing __NAME__: __ERR__",
	stopSharingModalSure: "Are you sure you want to stop sharing __COUNT__ items? The other parties will no longer be able to access them.",
	aboutRemaining: "About __TIME__ remaining",
	transferringItems: "Transferring __COUNT__ items",
	uploadingItems: "Uploading __COUNT__ items",
	downloadingItems: "Downloading __COUNT__ items",
	new: "New",
	resume: "Resume",
	pause: "Pause",
	stop: "Stop",
	done: "Done",
	noUploadsQueued: "No uploads queued",
	creatingFolders: "Creating folders",
	creatingFoldersProgress: "Creating __LEFT__ folders",
	current: "Current",
	create: "Create",
	changingColor: "Changing color for __COUNT__ items",
	couldNotChangeColor: "Could not change color for __NAME__: __ERR__",
	eventFileUploaded: "File uploaded",
	eventFileVersioned: "File versioned",
	eventVersionedFileRestored: "Versioned file restored",
	eventFileMoved: "File moved",
	eventFileTrash: "File moved to trash",
	eventFileRm: "File deleted",
	eventFileRestored: "File restored",
	eventFileShared: "File shared",
	eventFileLinkEdited: "File public link edited",
	eventFolderTrash: "Folder moved to trash",
	eventFolderShared: "Folder shared",
	eventFolderMoved: "Folder moved",
	eventFolderRenamed: "Folder renamed",
	eventFolderCreated: "Folder created",
	eventFolderRestored: "Folder restored",
	eventFolderColorChanged: "Folder color changed",
	eventLogin: "Login",
	eventDeleteVersioned: "Versioned files and folders deleted",
	eventDeleteAll: "All files and folders deleted",
	eventDeleteUnfinished: "Unfinished uploads deleted",
	eventTrashEmptied: "Trash emptied",
	eventRequestAccountDeletion: "Account deletion requested",
	event2FAEnabled: "2FA enabled",
	event2FADisabled: "2FA disabled",
	eventCodeRedeem: "Code redeemed",
	eventEmailChanged: "Email changed",
	eventPasswordChanged: "Password changed",
	eventRemovedSharedInItems: "Incoming shares removed",
	eventRemovedSharedOutItems: "Outgoing shares removed",
	eventFileUploadedInfo: "__NAME__ uploaded",
	eventFileVersionedInfo: "__NAME__ versioned",
	eventVersionedFileRestoredInfo: "File version of __NAME__ restored",
	eventFileRenamedInfo: "__NAME__ renamed to __NEW__",
	eventFileMovedInfo: "__NAME__ moved",
	fileRenamedInfo: "__NAME__ renamed to __NEW__",
	eventFileTrashInfo: "__NAME__ moved to trash",
	eventFileRmInfo: "__NAME__ deleted",
	eventFileRestoredInfo: "__NAME__ restored from trash",
	eventFileSharedInfo: "__NAME__ shared with __EMAIL__",
	eventFileLinkEditedInfo: "__NAME__ public link edited",
	eventFolderTrashInfo: "__NAME__ moved to trash",
	eventFolderSharedInfo: "__NAME__ shared with __EMAIL__",
	eventFolderMovedInfo: "__NAME__ moved",
	eventFolderRenamedInfo: "__NAME__ renamed to __NEW__",
	eventFolderCreatedInfo: "__NAME__ created",
	eventFolderRestoredInfo: "__NAME__ restored from trash",
	eventFolderColorChangedInfo: "__NAME__ color changed",
	eventLoginInfo: "Logged in",
	eventDeleteVersionedInfo: "Versioned files and folders deleted",
	eventDeleteAllInfo: "All files and folders deleted",
	eventDeleteUnfinishedInfo: "Unfinished uploads deleted",
	eventTrashEmptiedInfo: "Trash emptied",
	eventRequestAccountDeletionInfo: "Account deletion requested",
	event2FAEnabledInfo: "2FA enabled",
	event2FADisabledInfo: "2FA disabled",
	eventCodeRedeemInfo: "Code __CODE__ redeemed",
	eventEmailChangedInfo: "Email changed to __EMAIL__",
	eventPasswordChangedInfo: "Password changed",
	eventRemovedSharedInItemsInfo: "__COUNT__ incoming shares from __EMAIL__ removed",
	eventRemovedSharedOutItemsInfo: "__COUNT__ outgoing shares to __EMAIL__ removed",
	favoritingItems: "Favoriting __COUNT__ items",
	unfavoritingItems: "Unfavoriting __COUNT__ items",
	couldNotChangeFavStatus: "Could not change favorite status for __NAME__: __ERR__",
	pleaseChooseDiffDest: "Please choose a different destination",
	movingItems: "Moving __COUNT__ items",
	folderExistsAtDest: "A folder with the name __NAME__ already exists at the destination",
	couldNotMoveItem: "Could not move __NAME__: __ERR__",
	restoringItems: "Restoring __COUNT__ items",
	couldNotRestoreItem: "Could not restore __NAME__: __ERR__",
	changeEmail: "Change email",
	passwordsDoNotMatch: "Passwords do not match",
	registerWeakPassword: "Your password needs to be at least 10 characters long",
	invalidEmailOrPassword: "Invalid email or password",
	unknownErrorSupp: "Unknown error occured. Please try again or contact the support.",
	registerEmailAlreadyRegistered: "This email address is already registered.",
	gotIt: "Got it",
	registration: "Registration",
	registrationEmailInstructions:
		"To complete your registration please confirm your email address. A link with instructions has been sent to you.",
	forgotPasswordEmailSent: "An email with instructions on how to reset your password has been sent to you.",
	maxStorageReached: "Storage full",
	maxStorageReachedInfo: "You have reached your maximum storage allowance. Please upgrade your account to upload more files.",
	upgradeNow: "Upgrade now",
	abuseReportSubmitted: "Abuse report sent!",
	invalidAbuseReason: "Invalid reason",
	uploadErrored: "Failed",
	newTextFileName: "New text file name",
	passwordResetSuccess: "Password changed. Please re-login from all devices.",
	resetPasswordBtn: "Reset password",
	publicLinkDisabled: "Public link disabled",
	expireAfter: "Expire after",
	exportMasterKeys: "Export master keys",
	export: "Export",
	resetPasswordCheckbox:
		"I understand that by resetting my password without my exported master keys I will render all data stored on my account inaccessible due to how zero-knowledge end-to-end encryption works.",
	invalidAuthVersion: "Invalid authentication version",
	recoveryMasterKeysInput: "Exported master keys",
	exportMasterKeysInfo:
		"Exporting your master keys makes it possible to recover your account without data loss in case you forget your password. Please make sure to export your new master keys every time you change your password.",
	import: "Import",
	invite: "Invite",
	yourReferralLink: "Your link",
	requestPayout: "Request payout",
	invalidPayoutAddress: "Invalid payout address",
	affiliatePayoutSuccess: "Payout request submitted",
	requestPayoutInfo: "We will send your earned affiliate comission to the given Bitcoin address within 1-7 working days.",
	comissionEarned: "Comission earned",
	receivedBonusStorage: "Total received bonus storage",
	referInfo: "Receive up to __STORAGE__ of storage by inviting others",
	referInfo2:
		"For every friend you invite to Filen you receive __STORAGE__ - and your friend also receives __OTHER_STORAGE__. Your friend can register for a subscription of their choice and you earn comission. The comission payout threshold is __THRESHOLD__€. Your rate is __RATE__%.",
	yourBtcAddress: "Your Bitcoin address",
	openUploads: "Open uploads",
	payNow: "Pay invoice now",
	emptyTrashModalSure: "Are you sure you want to empty you trash? This action cannot be undone!",
	emptyTrashSuccess: "Trash emptied",
	itemRenamed: "Item renamed",
	subCancelled: "Cancelled",
	support: "Support",
	finishing: "Finishing",
	searchNothingFound: '"__TERM__" not found',
	queued: "Queued",
	searchInThisFolder: "Search in this folder...",
	fileVersioning: "File versioning",
	loginAlerts: "Login alerts",
	chats: "Chats",
	chatsMessageInput: "Send a message to __NAME__",
	notes: "Notes",
	contacts: "Contacts",
	chatConversations: "Chats",
	chatParticipants: "Participants",
	chatNew: "New chat",
	chatNewInviteEmail: "Email address",
	chatAddUserToConversation: "Add user",
	add: "Add",
	chatInput: "Send a message",
	noteAddParticipant: "Add participant",
	preparingFilesDots: "Preparing files...",
	loadingDots: "Loading...",
	participants: "Participants",
	owner: "Owner",
	removeParticipantNote: "Remove participant from this note",
	toggleParticipantWritePermissionsClick: "Participant has write permissions, click to change",
	toggleParticipantReadPermissionsClick: "Participant has read permissions, click to change",
	addNewParticipant: "Add a new participant",
	noteHistory: "History",
	noteTypeText: "Text",
	noteTypeRich: "Rich text",
	noteTypeMd: "Markdown",
	noteTypeChecklist: "Checklist",
	noteTypeCode: "Code",
	noteUnpin: "Unpin",
	notePin: "Pin",
	noteUnfavorite: "Unfavorite",
	noteFavorite: "Favorite",
	noteDuplicate: "Duplicate",
	noteExport: "Export",
	noteTrash: "Trash",
	noteArchive: "Archive",
	noteRestore: "Restore",
	noteDelete: "Delete",
	noteSynced: "Note synced",
	syncingNote: "Syncing note...",
	contactRequestSent: "Contact request sent",
	addContact: "Add a contact",
	addContactSmall: "Add contact",
	addContactEmail: "Filen email address of the contact",
	blockedUsers: "Blocked users",
	contactsOnline: "Online",
	contactsAll: "All",
	contactsOffline: "Offline",
	contactsPending: "Pending",
	contactsRequests: "Requests",
	contactsBlocked: "Blocked",
	blockUser: "Block",
	removeUser: "Remove",
	contactsIcomingRequests: "Incoming requests",
	contactsOutgoingRequests: "Outgoing requests",
	noteHistoryModal: "Note history",
	noteHistoryRestore: "Restore this version",
	searchInput: "Search...",
	notesTagsNameExists: "A tag with this name already exists",
	notesTagsCreate: "Create tag",
	newNote: "New note",
	notesNoNotesFoundUnderTag: "No notes found.",
	notesCreate: "Create one",
	notesCreateInfo: "You have no notes yet.",
	notesDeleteWarning: "Are you sure you want to delete this note? This action cannot be undone!",
	notesTagDeleteWarning: "Are you sure you want to delete this tag? This action cannot be undone!",
	notesTagsDelete: "Delete tag",
	deleteNote: "Delete note",
	toggleParticipantWritePermissions: "Participant has write permissions, click to change",
	toggleParticipantReadPermissions: "Participant has read permissions, click to change",
	notesTagsRename: "Rename tag",
	notesTagsCreateRenamePlaceholder: "Tag name",
	noContactsFound: "No contacts found.",
	noParticipantsFound: "No participants found.",
	leave: "Leave",
	leaveNote: "Leave note",
	leaveNoteWarning: "Are you sure you want to leave this note? This action cannot be undone!",
	noteRemoveParticipant: "Remove participant",
	noteRemoveParticipantWarning: "Are you sure you want to remove this participant from this note? This action cannot be undone!",
	block: "Block",
	blockUserWarning: "Are you sure you want to block __NAME__?",
	removeUserWarning: "Are you sure you want to remove __NAME__ from your contact list?",
	chatYou: "You",
	chatNoMessagesYet: "No messages yet",
	chatMessageDelete: "Delete message",
	chatMessageDeleteWarning: "Are you sure you want to delete this message? This action cannot be undone!",
	chatConversationDelete: "Delete chat",
	chatConversationDeleteWarning: "Are you sure you want to delete this chat? This action cannot be undone!",
	chatConversationLeave: "Leave chat",
	chatConversationLeaveWarning: "Are you sure you want to leave this chat?",
	chatConversationRemoveParticipant: "Remove participant",
	chatConversationRemoveParticipantWarning: "Are you sure you want to remove this participant from the chat?",
	chatInfoTitle: "End-to-end encrypted chat",
	chatInfoSubtitle1: "Filen secures every chat with zero-knowledge end-to-end encryption by default.",
	chatInfoSubtitle2: "Only the members of the chat can decrypt and read the content.",
	chatInfoSubtitle3:
		"The system ensures that the data received actually comes from the displayed user and has not been changed in the meantime.",
	chatEmojisMatching: "Emojis matching",
	selectFromCloud: "Select from cloud",
	creatingPublicLinks: "Creating public links",
	attachToChat: "Attach to chat",
	select: "Select",
	selectNumItems: "Select __NUM__ items",
	chatSettings: "Chat settings",
	chatSettingsDisplayName: "Display name",
	chatSettingsAppearOffline: "Appear offline",
	chatMessageHiddenUserBlocked: "Message of blocked contact hidden.",
	chatConversationCreateSidebar: "No secure chats yet.",
	chatConversationCreateSidebarCreate: "Start talking",
	noConversationFound: "No chat found.",
	copyText: "Copy text",
	chatEdited: "edited",
	chatViewingOlderMessages: "You are viewing older messages",
	chatJumpToPresent: "Jump to present",
	twoFactorConfirmAlert: "I have safely stored my 2FA recovery key",
	chatIsTyping: "is typing",
	chatConversationEditName: "Edit name",
	copyId: "Copy ID",
	replyToChatMessage: "Reply",
	chatReplyingTo: "Replying to",
	chatUnreadMessagesSince: "unread messages since __DATE__",
	chatMarkAsRead: "Mark as read",
	profileMemberSince: "Member since __DATE__",
	profileAddContact: "Send contact request",
	profileBlockContact: "Block",
	profileUnblockContact: "Unblock",
	profile: "Profile",
	chatMessageLimitReached: "Message limit is __LIMIT__ characters",
	chatAttachmentTooManyFiles: "You cannot attach more than __LIMIT__ files per message",
	noteTooBig: "Maximum note size is __MAXSIZE__",
	notesTags: "Tags",
	notesType: "Type",
	notesAll: "All",
	notesPinned: "Pinned",
	warning: "Warning",
	iUnderstand: "I understand",
	forgotPasswordAcknowledgeWarning:
		"By resetting your password without your backed up master keys all your previous uploaded files and folders will be gone!"
}

export default en
