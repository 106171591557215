const fr: {
	[key: string]: string
} = {
	forgotPasswordSendInstructions: "Envoi d'instructions",
	goBack: "Retour",
	invalidEmailAndPassword: "Adresse e-mail ou mot de passe incorrect",
	email: "E-mail",
	password: "Mot de passe",
	emailAddress: "Adresse e-mail",
	tfaCode: "Code de double authentification",
	login: "Identifiant",
	dontHaveAnAccountYet: "Vous n'avez pas de compte ?",
	accountCreateOne: "Créez-en un !",
	forgotYourPassword: "Mot de passe oublié ?",
	couldNotFindDefaultFolder: "Impossible de trouver le dossier par défaut",
	unlockLink: "Débloquer",
	download: "Télécharger",
	cannotDownloadEmptyFolder: "Impossible de télécharger un dossier vide",
	thisFolderIsEmpty: "Ce dossier est vide",
	linkFolderEmptyInfo: "Une fois les fichiers ou dossiers ajoutés, ils apparaîtront ici",
	confirmPassword: "Confirmer le mot de passe",
	createAccount: "Créer un compte",
	alreadyHaveAnAccount: "Vous avez déjà un compte ?",
	loginEx: "Connexion !",
	resendConfirmationEmail: "Renvoyer l'e-mail de confirmation",
	reportAbuseModal_spam: "Spam",
	reportAbuseModal_dmca: "Infraction aux Copyright",
	reportAbuseModal_cp: "Pédophilie",
	reportAbuseModal_stolen: "Données volées",
	reportAbuseModal_malware: "Malware",
	reportAbuseModal_other: "Autre",
	abuseReport: "Signaler un abus",
	abuseReportModalInfo: "Utilisez ce formulaire pour nous signaler des fichiers partagés inappropriés, illégaux ou néfastes.",
	yourEmailAddress: "Votre adresse e-mail",
	abuseReportModalInfoPlaceholder:
		"Veuillez nous fournir le plus de détails possible afin d'agir rapidement (optionnel)",
	send: "Envoyer",
	abuseReportModalReason: "Veuillez choisir une raison",
	avatarUploadMaxFileSize: "La taille du fichier ne doit pas dépasser 3 Mo",
	storageUsed: "Stockage utilisé",
	storageUsedInfo: "__USED__ utilisés sur __MAX__",
	storageUsedFilesAndFolders: "__USED__ de fichiers",
	storageUsedVersionedFiles: "__USED__ de fichiers versionnés",
	storageUsedFree: "__FREE__ d'espace libre",
	avatar: "Avatar",
	edit: "Éditer",
	personalInformation: "Informations personnelles",
	darkMode: "Mode sombre",
	language: "Langue",
	change: "Modifier",
	requestAccountData: "Demander les données du compte",
	request: "Les récupérer",
	logout: "Déconnexion",
	versionedFiles: "Fichiers versionnés",
	delete: "Supprimer",
	allFilesAndFolders: "Tous les fichiers et dossiers",
	deleteAccount: "Supprimer le compte",
	changePassword: "Modifier le mot de passe",
	tfa: "Double authentification",
	subMoreInfo: "Plus d'informations sur votre souscription",
	paymentMethod: "Moyen de paiement",
	stripe: "Stripe",
	paypal: "PayPal",
	crypto: "Cryptomonnaie",
	update: "Mise à jour",
	cancel: "Annuler",
	noSubs: "Vous n'avez actuellement aucune souscription active.",
	noInvoices: "Vous n'avez pas de factures.",
	planFeatures_1: "Bande passante illimitée",
	planFeatures_2: "Encryptage coté client",
	planFeatures_3: "Technologie Zero-knowledge",
	planTerms_monthly: "Mensuel",
	planTerms_annually: "Annuel",
	planTerms_lifetime: "À vie",
	planTerms_starter: "Basic",
	buyNow: "Acheter maintenant",
	general: "Général",
	settings: "Paramètres",
	security: "Sécurité",
	plans: "Plans",
	subs: "Souscriptions",
	events: "Évènements",
	invoices: "Factures",
	invalidEmail: "E-mail non valide",
	invalidPassword: "Mot de passe non valide",
	emailsDoNotMatch: "Les e-mails ne correspondent pas",
	changeEmailPleaseConfirm:
		"Veuillez confirmer votre nouvelle adresse e-mail en cliquant sur le lien envoyé sur celle-ci",
	newEmail: "Nouvel e-mail",
	confirmNewEmail: "Confirmer le nouvel e-mail",
	save: "Sauvegarder",
	firstName: "Prénom",
	lastName: "Nom",
	companyName: "Société",
	vatId: "N° de TVA",
	street: "Rue",
	streetNumber: "Numéro",
	city: "Ville",
	postalCode: "Code postal",
	country: "Pays",
	areYouSure: "Êtes-vous sûr ?",
	areYouSureDeleteAllVersioned:
		"Êtes-vous sûr de vouloir supprimer tous les fichiers versionnés ? Cette action est irréversible !",
	areYouSureDeleteAll:
		"Êtes-vous sûr de vouloir supprimer tous les fichiers et dossiers ? Cette action est irréversible !",
	invalidNewPassword: "Nouveau mot de passe non valide",
	invalidCurrentPassword: "Mot de passe actuel non valide",
	newPasswordsDontMatch: "Les nouveaux mots de passe ne correspondent pas",
	invalidMasterKeys: "Clés maîtres non valides",
	passwordChanged: "Mot de passe modifié",
	newPassword: "Nouveau mot de passe",
	confirmNewPassword: "Confirmer le nouveau mot de passe",
	currentPassword: "Mot de passe actuel",
	deleteAccountConfirm:
		"Veuillez confirmer la suppression du compte en cliquant sur le lien envoyé à votre adresse e-mail",
	areYouSureDeleteAccount:
		"Êtes-vous sûr de vouloir supprimer votre compte ? Nous vous enverrons un lien de confirmation sur votre adresse e-mail.",
	invalid2FACode: "Code de double authentification non valide",
	enable2FA: "Activer la double authentification",
	copy: "Copier",
	enter2FA: "Saisir le code de double authentification",
	enable: "Activé",
	recoveryKeys: "Clé de récupération",
	recoveryKeysInfo:
		"Veuillez stocker votre clé de récupération dans un endroit sûr. C'est le seul moyen de récupérer votre compte en cas de perte d'accès.",
	close: "Fermer",
	disable2FA: "Désactiver la double authentification",
	disable: "Désactiver",
	sharedWithMe: "Partagé avec moi",
	sharedWithOthers: "Partagé avec d'autres",
	links: "Liens",
	favorites: "Favoris",
	recents: "Récents",
	trash: "Corbeille",
	cloudDrive: "Drive Cloud",
	emptyTrash: "Corbeille vide",
	monthlyRecurring: "par mois",
	annuallyRecurring: "par an",
	oneTimePayment: "Paiement en une seule fois",
	buyRecurringInfo:
		"En souscrivant à un abonnement vous autorisez Filen à vous prélever automatiquement jusqu'à annulation. Vous pouvez annuler à tout moment via la page de gestion de votre compte. Aucun remboursement partiel.",
	buyLifetimeInfo: "En souscrivrant à un abonnement vous autorisez Filen à vous prélever. Aucun remboursement partiel.",
	creditDebit: "Carte de crédit",
	cancelSub: "Annuler l'abonnement'",
	cancelSubSure: "Êtes-vous sûr de vouloir vous désabonner ?",
	cancellingSub: "Annulation de l'abonnement",
	createFolder: "Créer un dossier",
	createTextFile: "Créer un fichier texte",
	uploadFiles: "Charger des fichiers",
	uploadFolders: "Charger des dossiers",
	preview: "Prévisualiser",
	preparingDownload: "Préparation du téléchargement",
	normalDownload: "Téléchargement normal",
	zipDownload: "Téléchargement par Zip",
	publicLink: "Lien public",
	share: "Partager",
	versions: "Versions",
	color: "Couleur",
	color_default: "Par défaut",
	color_blue: "Bleu",
	color_green: "Vert",
	color_purple: "Violet",
	color_red: "Rouge",
	color_gray: "Gris",
	unfavorite: "Retirer des Favoris",
	favorite: "Favori",
	rename: "Renommer",
	move: "Déplacer",
	selectDestination: "Sélectionner la destination",
	restore: "Restaurer",
	deletePerm: "Supprimer définitivement",
	remove: "Retirer",
	stopSharing: "Arrêter le partage",
	cookieConsent: "Ce site utilise des cookies afin de mesurer et améliorer votre expérience.",
	accept: "Accepter",
	optOut: "Refuser",
	onlyNeeded: "Uniquement ceux nécessaires",
	pleaseChooseDiffName: "Veuillez choisir un nom différent",
	newFolderName: "Nom",
	itemsMovedToTrash: "__COUNT__ élément(s) déplacés dans la corbeille",
	couldNotMoveToTrash: "Impossible de déplacer __NAME__ vers la corbeille : __ERR__",
	deleteModalSure: "Êtes-vous sûr de vouloir supprimer __COUNT__ élément(s) ?",
	deletePermModalSure:
		"Êtes-vous sûr de vouloir supprimer définitivement __COUNT__ élément(s) ? Cette action est irréversible !",
	itemsDeletedPerm: "__COUNT__ élément(s) ont été supprimés définitivement",
	couldNotDeletePerm: "Impossible de supprimer __NAME__ définitivement : __ERR__",
	uploadHere: "Charger ici",
	event: "Évènement",
	date: "Date",
	ipAddress: "Adresse IP",
	na: "N/A",
	listEmpty_1: "Aucun fichier ou dossier partagé avec vous",
	listEmpty_2: "Lorsque quelqu'un partagera des fichiers ou dossiers avec vous, ils apparaîtront ici",
	listEmpty_3: "Aucun fichier ou dossier partagé avec d'autres",
	listEmpty_4: "Lorsque vous partagerez des fichiers ou dossiers avec quelqu'un, ils apparaîtront ici",
	listEmpty_5: "Aucun lien public",
	listEmpty_6: "Lorsque vous créerez des liens publics vers un fichier ou un dossier, ils apparaîtront ici",
	listEmpty_7: "Aucun favoris",
	listEmpty_8: "Lorsque vous marquerez comme favoris des fichiers ou des dossiers, ils apparaîtront ici",
	listEmpty_9: "Aucun chargement récent",
	listEmpty_10: "Les fichiers récemment chargés apparaîtront ici",
	listEmpty_11: "Rien dans la corbeille",
	listEmpty_12: "Lorsque vous marquerez des fichiers ou dossiers comme étant à supprimer, ils apparaîtront ici",
	listEmpty_13: "Aucun fichier ou dossier chargé",
	listEmpty_14: "Glissez-déposez ici des fichiers ou dossiers ou cliquez sur le bouton ci-dessous",
	listFooterSelected: "__COUNT__ sélectionné(s) sur __TOTAL__",
	name: "Nom",
	size: "Taille",
	lastModified: "Dernière modification",
	moveModalBtn: "Déplacer __COUNT__ élément(s) vers __DEST__",
	file: "Fichier",
	ctrlPlusS: "CTRL + S",
	exit: "Quitter",
	fileHasBeenChanged: "Le fichier a été modifié",
	textEditorExitSure: "Êtes-vous sûr de vouloir quitter sans sauvegarder ?",
	saveChanges: "Sauvegarder les modifications",
	expire_never: "Jamais",
	expire_1h: "1 heure",
	expire_6h: "6 heures",
	expire_1d: "1 jour",
	expire_3d: "3 jours",
	expire_7d: "7 jours",
	expire_14d: "14 jours",
	expire_30d: "30 jours",
	addingItemsToPublicLink: "Ajouter des éléments au lien public",
	addingItemsToPublicLinkProgress: "Ajouter __LEFT__ élément(s) au lien public",
	enabled: "Activé",
	disabled: "Désactivé",
	copied: "Copié",
	publicLinkDownloadBtn: "Bouton de téléchargement activé",
	publicLinkPassword: "Mot de passe (laisser vide pour désactiver)",
	removedSharedItems: "__COUNT__ élément(s) partagé(s) supprimé(s)",
	couldNotRemoveSharedItems: "Impossible de supprimer __NAME__ : __ERR__",
	removeSharedItemsModalInfo:
		"Êtes-vous sûr de vouloir supprimer __COUNT__ élément(s) ? Vous ne pourrez plus y accéder ensuite.",
	renameNewName: "Nouveau nom",
	selectFromComputer: "Sélectionner depuis l'ordinateur",
	upload: "Charger",
	files: "Fichiers",
	folders: "Dossiers",
	newFolder: "Nouveau dossier",
	sharedWith: "Partagé avec",
	sharingItems: "Partage",
	sharingItemsProgress: "Partage de __LEFT__ éléments(s)",
	itemsSharedWith: "__COUNT__ éléments(s) partagé(s) avec __EMAIL__",
	shareReceiver: "E-mail du compte Filen du destinataire",
	upgrade: "Mise à niveau",
	stoppedSharingItems: "Partage de __COUNT__ éléments(s) interrompu",
	couldNotStopSharingItem: "Impossible d’arrêter le partage __NAME__ : __ERR__",
	stopSharingModalSure:
		"Êtes-vous sûr de vouloir arrêter le partage de __COUNT__ élément(s) ? Les autres personnes ne pourront plus y avoir accès.",
	aboutRemaining: "Temps restant : __TIME__",
	transferringItems: "Transfert de __COUNT__ élément(s)",
	uploadingItems: "Chargement de __COUNT__ élément(s)",
	downloadingItems: "Téléchargement de __COUNT__ élément(s)",
	new: "Nouveau",
	resume: "Reprendre",
	pause: "Pause",
	stop: "Arrêter",
	done: "Terminé",
	noUploadsQueued: "Aucun chargement en attente",
	creatingFolders: "Création des dossiers",
	creatingFoldersProgress: "Création de __LEFT__ dossier(s)",
	current: "Actuel",
	create: "Créer",
	changingColor: "Modifier la couleur pour __COUNT__ élément(s)",
	couldNotChangeColor: "Impossible de modifier la couleur pour __NAME__ : __ERR__",
	eventFileUploaded: "Fichier chargé",
	eventFileVersioned: "Fichier versionné",
	eventVersionedFileRestored: "Le fichier versionné a été restauré",
	eventFileMoved: "Fichier déplacé",
	eventFileTrash: "Fichier déplacé vers la corbeille",
	eventFileRm: "Fichier supprimé",
	eventFileRestored: "Fichier restauré",
	eventFileShared: "Fichier partagé",
	eventFileLinkEdited: "Lien public vers le fichier édité",
	eventFolderTrash: "Fichier déplacé vers la corbeille",
	eventFolderShared: "Fichier partagé",
	eventFolderMoved: "Fichier déplacé",
	eventFolderRenamed: "Fichier renommé",
	eventFolderCreated: "Fichier créé",
	eventFolderRestored: "Fichier restauré",
	eventFolderColorChanged: "Couleur du fichier modifiée",
	eventLogin: "Connexion",
	eventDeleteVersioned: "Suppression des fichiers et des dossiers versionnés",
	eventDeleteAll: "Suppression de tous les fichiers et dossiers",
	eventDeleteUnfinished: "Chargements non terminés supprimés",
	eventTrashEmptied: "Corbeille vidée",
	eventRequestAccountDeletion: "Demande de suppression de compte",
	event2FAEnabled: "2FA activée",
	event2FADisabled: "2FA désactivée",
	eventCodeRedeem: "Code redemandé",
	eventEmailChanged: "E-mail modifié",
	eventPasswordChanged: "Mot de passe modifié",
	eventRemovedSharedInItems: "Partages entrants supprimés",
	eventRemovedSharedOutItems: "Partages sortants supprimés",
	eventFileUploadedInfo: "__NAME__ chargé",
	eventFileVersionedInfo: "__NAME__ versionné",
	eventVersionedFileRestoredInfo: "La version de __NAME__ restaurée",
	eventFileRenamedInfo: "__NAME__ renommé en __NEW__",
	eventFileMovedInfo: "__NAME__ déplacé",
	fileRenamedInfo: "__NAME__ renommé en __NEW__",
	eventFileTrashInfo: "__NAME__ déplacé dans la corbeille",
	eventFileRmInfo: "__NAME__ supprimé",
	eventFileRestoredInfo: "__NAME__ restauré depuis la corbeille",
	eventFileSharedInfo: "__NAME__ partagé avec __EMAIL__",
	eventFileLinkEditedInfo: "__NAME__ lien public édité",
	eventFolderTrashInfo: "__NAME__ déplacé dans la corbeille",
	eventFolderSharedInfo: "__NAME__ partagé avec __EMAIL__",
	eventFolderMovedInfo: "__NAME__ déplacé",
	eventFolderRenamedInfo: "__NAME__ renommé en __NEW__",
	eventFolderCreatedInfo: "__NAME__ créé",
	eventFolderRestoredInfo: "__NAME__ restauré depuis la corbeille",
	eventFolderColorChangedInfo: "Couleur de __NAME__ modifiée",
	eventLoginInfo: "Connexion",
	eventDeleteVersionedInfo: "Suppression des fichiers et des dossiers versionnés",
	eventDeleteAllInfo: "Suppression de tous les fichiers et dossiers",
	eventDeleteUnfinishedInfo: "Chargements non terminés supprimés",
	eventTrashEmptiedInfo: "Corbeille vidée",
	eventRequestAccountDeletionInfo: "Demande de suppression de compte",
	event2FAEnabledInfo: "2FA activée",
	event2FADisabledInfo: "2FA désactivée",
	eventCodeRedeemInfo: "Code __CODE__ réclamé",
	eventEmailChangedInfo: "E-mail modifié en __EMAIL__",
	eventPasswordChangedInfo: "Mot de passe modifié",
	eventRemovedSharedInItemsInfo: "__COUNT__ partage(s) entrant(s) depuis __EMAIL__ retiré(s)",
	eventRemovedSharedOutItemsInfo: "__COUNT__ partage(s) sortant(s) vers __EMAIL__ retiré(s)",
	favoritingItems: "Ajout de __COUNT__ élément(s) aux favoris",
	unfavoritingItems: "Suppression de __COUNT__ éléments(s) des favoris",
	couldNotChangeFavStatus: "Impossible de modifier le statut de favoris pour __NAME__ : __ERR__",
	pleaseChooseDiffDest: "Veuillez choisir une destination différente",
	movingItems: "Déplacement de __COUNT__ élément(s)",
	folderExistsAtDest: "Un dossier avec le nom __NAME__ existe déjà à cette destination",
	couldNotMoveItem: "Impossible de déplacer __NAME__ : __ERR__",
	restoringItems: "Restauration de __COUNT__ élément(s)",
	couldNotRestoreItem: "Impossible de restaurer __NAME__ : __ERR__",
	changeEmail: "Modifier l'e-mail",
	passwordsDoNotMatch: "Les mots de passe ne correspondent pas",
	registerWeakPassword: "Votre mot de passe doit comporter au moins 10 caractères",
	invalidEmailOrPassword: "E-mail ou mot de passe incorrect",
	unknownErrorSupp: "Une erreur inconnue s'est produite. Veuillez réessayer ou contacter le support.",
	registerEmailAlreadyRegistered: "Cette adresse e-mail est déjà utilisée.",
	gotIt: "Bien compris",
	registration: "Inscription",
	registrationEmailInstructions:
		"Afin de terminer votre inscription, veuillez confirmer votre adresse e-mail. Un lien avec des instructions vous a été envoyé sur celle-ci.",
	forgotPasswordEmailSent:
		"Un e-mail avec des instructions sur comment réinitialiser votre mot de passe vous a été envoyé.",
	maxStorageReached: "Stockage plein",
	maxStorageReachedInfo:
		"Vous avez atteint le maximum autorisé. Veuillez mettre à niveau votre compte pour ajouter de nouveaux fichiers.",
	upgradeNow: "Mettre à niveau maintenant",
	abuseReportSubmitted: "Rapport d'abus envoyé !",
	invalidAbuseReason: "Raison non valide",
	uploadErrored: "Échec",
	newTextFileName: "Nouveau nom de fichier texte",
	passwordResetSuccess: "Le mot de passe a été modifié. Veuillez vous reconnecter sur tous vos appareils.",
	resetPasswordBtn: "Réinitialisation du mot de passe",
	publicLinkDisabled: "Lien public désactivé",
	expireAfter: "Expire après",
	exportMasterKeys: "Exporter les clés maîtres",
	export: "Exporter",
	resetPasswordCheckbox:
		"Je comprends qu'en réinitialisant mon mot de passe sans exporter mes clés maîtres, je vais rendre inaccessible toutes les données stockées sur mon compte. Cela est dû au fonctionnement du cryptage zero-knowledge.",
	invalidAuthVersion: "Version d'authentification non valide",
	recoveryMasterKeysInput: "Clés maîtres exportées",
	exportMasterKeysInfo:
		"Exporter vos clés maîtres rend possible la récupération de votre compte sans perte de données, au cas ou vous perdriez votre mot de passe. Assurez-vous d'exporter vos clés maîtres à chaque changement de mot de passe.",
	import: "Importer",
	invite: "Parrainage",
	yourReferralLink: "Votre lien",
	requestPayout: "Paiement requis",
	invalidPayoutAddress: "Adresse de paiement non valide",
	affiliatePayoutSuccess: "Demande de paiement envoyée",
	requestPayoutInfo:
		"Nous allons vous envoyer votre gain de parrainage sur l'adresse Bitcoin fournie d'ici 1 à 7 jours ouvrés.",
	comissionEarned: "Commission obtenue",
	receivedBonusStorage: "Bonus total de stockage reçu",
	referInfo: "Recevez jusqu'à __STORAGE__ de stockage en plus en parrainant d'autres utilisateurs",
	referInfo2:
		"Pour chaque ami que vous parrainez sur Filen, vous recevrez __STORAGE__ - et votre ami recevra aussi __OTHER_STORAGE__. Votre ami peut s'enregistrer pour une souscription de son choix, vous recevrez ensuite une commission de parrainage. Celle-ci est de __THRESHOLD__€. Votre taux est de __RATE__%.",
	yourBtcAddress: "Votre adresse Bitcoin",
	openUploads: "Ouvrir les chargements",
	payNow: "Payez la facture maintenant",
	emptyTrashModalSure: "Êtes-vous sûr de vouloir vider la corbeille ? Cette action est irréversible !",
	emptyTrashSuccess: "Corbeille vidée",
	itemRenamed: "Élément renommé",
	subCancelled: "Annulée",
	support: "Support",
	finishing: "Bientôt terminé",
	searchNothingFound: '"__TERM__" n\'existe pas',
	queued: "En attente",
	searchInThisFolder: "Rechercher dans ce dossier ...",
	fileVersioning: "Versionnage de fichier",
	loginAlerts: "Alertes de connexion",
	chats: "Conversations",
	chatsMessageInput: "Envoyer un message à __NAME__",
	notes: "Notes",
	contacts: "Contacts",
	chatConversations: "Conversations",
	chatParticipants: "Participants",
	chatNew: "Nouvelle conversation",
	chatNewInviteEmail: "Adresse e-mail",
	chatAddUserToConversation: "Ajouter un utilisateur",
	add: "Ajouter",
	chatInput: "Envoyer un message",
	noteAddParticipant: "Ajouter un participant",
	preparingFilesDots: "Préparation des fichiers ...",
	loadingDots: "Chargement ...",
	participants: "Participants",
	owner: "Propriétaire",
	removeParticipantNote: "Retirer ce participant de la note",
	toggleParticipantWritePermissionsClick: "Participant avec la permission d'écriture, cliquer pour changer",
	toggleParticipantReadPermissionsClick: "Participant en lecture seule, cliquer pour changer",
	addNewParticipant: "Ajouter un nouveau participant",
	noteHistory: "Historique",
	noteTypeText: "Texte",
	noteTypeRich: "Texte riche",
	noteTypeMd: "Markdown",
	noteTypeChecklist: "Liste",
	noteTypeCode: "Code",
	noteUnpin: "Désépingler",
	notePin: "Épingler",
	noteUnfavorite: "Retirer des favoris",
	noteFavorite: "Ajouter aux favoris",
	noteDuplicate: "Dupliquer",
	noteExport: "Exporter",
	noteTrash: "Corbeille",
	noteArchive: "Archiver",
	noteRestore: "Restaurer",
	noteDelete: "Supprimer",
	noteSynced: "Note synchronisée",
	syncingNote: "Synchronisation de la note ...",
	contactRequestSent: "Demande de contact envoyée",
	addContact: "Ajouter un contact",
	addContactSmall: "Ajouter contact",
	addContactEmail: "Adresse e-mail Filen du contact",
	blockedUsers: "Utilisateurs bloqués",
	contactsOnline: "En ligne",
	contactsAll: "Tous",
	contactsOffline: "Hors ligne",
	contactsPending: "En attente",
	contactsRequests: "Demandes",
	contactsBlocked: "Bloqués",
	blockUser: "Bloquer",
	removeUser: "Supprimer",
	contactsIcomingRequests: "Demandes entrantes",
	contactsOutgoingRequests: "Demandes sortantes",
	noteHistoryModal: "Historique de la note",
	noteHistoryRestore: "Restaurer cette version",
	searchInput: "Chercher ...",
	notesTagsNameExists: "Un label avec ce nom existe déjà",
	notesTagsCreate: "Créer un label",
	newNote: "Nouvelle note",
	notesNoNotesFoundUnderTag: "Aucune note trouvée.",
	notesCreate: "Créer une note",
	notesCreateInfo: "Vous n'avez pas encore de note.",
	notesDeleteWarning: "Êtes-vous sûr de vouloir supprimer cette note ? Cette action est irréversible !",
	notesTagDeleteWarning: "Êtes-vous sûr de vouloir supprimer ce label ? Cette action est irréversible !",
	notesTagsDelete: "Supprimer le label",
	deleteNote: "Supprimer la note",
	toggleParticipantWritePermissions: "Participant avec la permission d'écriture, cliquer pour changer",
	toggleParticipantReadPermissions: "Participant en lecture seule, cliquer pour changer",
	notesTagsRename: "Renommer le label",
	notesTagsCreateRenamePlaceholder: "Nom du label",
	noContactsFound: "Aucun contact trouvé.",
	noParticipantsFound: "Aucun participant trouvé.",
	leave: "Quitter",
	leaveNote: "Supprimer la note",
	leaveNoteWarning: "Êtes-vous sûr de vouloir supprimer cette note ? Cette action est irréversible !",
	noteRemoveParticipant: "Retirer participant",
	noteRemoveParticipantWarning: "Êtes-vous sûr de vouloir retirer ce participant de cette note ? Cette action est irréversible !",
	block: "Bloquer",
	blockUserWarning: "Êtes-vous sûr de vouloir bloquer __NAME__?",
	removeUserWarning: "Êtes-vous sûr de vouloir retirer __NAME__ de votre liste de contacts ?",
	chatYou: "Vous",
	chatNoMessagesYet: "Aucun message pour le moment",
	chatMessageDelete: "Supprimer le message",
	chatMessageDeleteWarning: "Êtes-vous sûr de vouloir supprimer ce message ? Cette action est irréversible !",
	chatConversationDelete: "Supprimer la conversation",
	chatConversationDeleteWarning: "Êtes-vous sûr de vouloir supprimer cette conversation ? Cette action est irréversible !",
	chatConversationLeave: "Quitter la conversation",
	chatConversationLeaveWarning: "Êtes-vous sûr de vouloir quitter cette conversation ?",
	chatConversationRemoveParticipant: "Retirer le participant",
	chatConversationRemoveParticipantWarning: "Êtes-vous sûr de vouloir retirer ce participant de la conversation ?",
	chatInfoTitle: "Conversation chiffrée de bout en bout",
	chatInfoSubtitle1: "Par défaut Filen sécurise toutes les conversations avec un chiffrement zero-knowledge de bout en bout.",
	chatInfoSubtitle2: "Seuls les participants de la conversation peuvent décrypter et lire son contenu.",
	chatInfoSubtitle3:
		"Le système s'assure que les données reçues viennent bien de l'utilisateur affiché sans avoir été modifiées entre-temps.",
	chatEmojisMatching: "Emojis correspondants",
	selectFromCloud: "Sélectionner depuis le Cloud",
	creatingPublicLinks: "Création des liens publics",
	attachToChat: "Joindre à la conversation",
	select: "Sélectionner",
	selectNumItems: "Sélectionner __NUM__ objet(s)",
	chatSettings: "Paramètres de la conversation",
	chatSettingsDisplayName: "Nom visible",
	chatSettingsAppearOffline: "Apparaître hors ligne",
	chatMessageHiddenUserBlocked: "Les messages des utilisateurs bloqués sont cachés.",
	chatConversationCreateSidebar: "Aucune conversation sécurisée pour le moment.",
	chatConversationCreateSidebarCreate: "Démarrer une conversation",
	noConversationFound: "Aucune conversation trouvée.",
	copyText: "Copier le texte",
	chatEdited: "modifié",
	chatViewingOlderMessages: "Vous consultez d'anciens messages",
	chatJumpToPresent: "Revenir aux derniers messages",
	twoFactorConfirmAlert: "J'ai bien sauvegardé ma clé de récupération 2FA",
	chatIsTyping: "est en train d'écrire",
	chatConversationEditName: "Modifier le nom",
	copyId: "Copier l'ID",
	replyToChatMessage: "Répondre",
	chatReplyingTo: "Répondre à",
	chatUnreadMessagesSince: "message(s) non lu(s) depuis __DATE__",
	chatMarkAsRead: "Marquer comme lu",
	profileMemberSince: "Membre depuis __DATE__",
	profileAddContact: "Envoyer une demande de contact",
	profileBlockContact: "Bloquer",
	profileUnblockContact: "Débloquer",
	profile: "Profil",
	chatMessageLimitReached: "La taille limite d'un message est de __LIMIT__ caractères",
	chatAttachmentTooManyFiles: "Vous ne pouvez pas joindre plus de __LIMIT__ fichiers par message",
	noteTooBig: "La taille maximum d'une note est de __MAXSIZE__",
	notesTags: "Labels",
	notesType: "Type",
	notesAll: "Toutes",
	notesPinned: "Épinglées"
}

export default fr
